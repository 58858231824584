import { Button, ComponentWithAs, Icon, IconProps } from '@chakra-ui/react';

export const ActionButton: React.FC<{
  colorScheme?: 'primary' | 'secondary';
  icon?: ComponentWithAs<'svg', IconProps> | undefined;
  ReactIcon?: React.ReactNode;
  onClickHandler: () => void;
}> = ({ children, colorScheme = 'primary', icon, ReactIcon, onClickHandler }) => (
  <Button colorScheme={colorScheme} size="xs" fontSize="xs" onClick={onClickHandler}>
    {icon && <Icon as={icon} color="white" mr={children ? 1 : 0} />}
    {ReactIcon}
    {children}
  </Button>
);
