import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link as RouterLink, NavLink as RouterNavLink } from 'react-router-dom';

import { CONTAINER_MAX_WIDTH, NAV_BAR_HEIGHT } from '../config/styles';

import { ChevronDownIcon, LogoDark, LogoLight } from '../assets/icons/Index';

// isInverted: used for Landing page
const Navbar: React.FC<{ isInverted?: boolean }> = ({ isInverted }) => {
  const { user, logout } = useAuth0();

  const userActions = () => {
    if (!user) {
      return (
        <HStack spacing={6}>
          {isInverted && (
            <Button id="signin-button" variant="ghost" as={RouterLink} to="/dashboard">
              ログイン
            </Button>
          )}
          {!isInverted && (
            <Button id="signin-button" colorScheme="primary" as={RouterLink} to="/dashboard">
              ログイン
            </Button>
          )}
          <Button id="signup-button" colorScheme="primary" as={RouterLink} to="/dashboard">
            新規登録
          </Button>
        </HStack>
      );
    }

    return (
      <Menu>
        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
          <HStack>
            <Avatar size="sm" src={user?.picture} />
            <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
              <Text fontSize="sm" fontWeight="semibold">
                {user?.nickname}
              </Text>
            </VStack>
            <Box display={{ base: 'none', md: 'flex' }}>
              <ChevronDownIcon />
            </Box>
          </HStack>
        </MenuButton>
        <MenuList color="secondary.600">
          <MenuItem
            onClick={() => {
              logout({ returnTo: window.location.origin });
            }}
          >
            ログアウト
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const navLinks = () => (
    <HStack spacing={0} px={8}>
      {user && (
        <Link as={RouterNavLink} className="nav-link" to="/dashboard" variant="button">
          Home
        </Link>
      )}
      <Link
        className="nav-link"
        href="https://share.hsforms.com/1VpvD-sNTRpSewqOSW2455wddydr"
        isExternal
        variant="button"
      >
        お問い合わせ
      </Link>
      <Link
        className="nav-link"
        href="https://datalabs-jp.notion.site/LinkedViewer-f1714dc6b352485fadf1539d494e3c1b"
        isExternal
        variant="button"
      >
        ヘルプセンター
      </Link>
    </HStack>
  );

  return (
    <Box
      backgroundColor={isInverted ? 'white' : 'primary.500'}
      boxShadow={isInverted ? '' : 'md'}
      color={isInverted ? '' : 'white'}
      w="100%"
    >
      <Container maxW={CONTAINER_MAX_WIDTH}>
        <Flex align="center" w="100%" minH={`${NAV_BAR_HEIGHT}px`} py={isInverted ? 12 : 0}>
          <Link as={RouterLink} to="/">
            {isInverted ? <LogoDark /> : <LogoLight />}
          </Link>

          {navLinks()}

          <Spacer />

          {userActions()}
        </Flex>
      </Container>
    </Box>
  );
};

export default Navbar;
