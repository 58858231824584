// react modules
import { forwardRef } from 'react';
import { Box, Flex, HStack, IconButton, Spacer, Square, VStack, Text } from '@chakra-ui/react';

// third party modules

// components
import { BoringPanelItem } from './PanelItem';
import { ItemGroup } from './ItemGroup';

// services

// assets, config
import { View, Boring } from '../../../../config/interfaces/views';
import { INFO_PANEL_PADDING, LAYER_ITEM_STYLES } from '../../../../config/styles';
import { PlusCircleIcon } from '../../../../assets/icons/Index';

const DataPanelFunction: React.ForwardRefRenderFunction<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  {
    view: View;
    isEditable: boolean;
    openAddBoringModalHandler: () => void;
    switchCesiumClickEventHandler: (isEnabled: boolean) => void;
    updateBoringLayerVisibilityHandler: (index: number, visibility: boolean) => void;
    deleteLayer: (index: number) => void;
    borings: Boring[] | undefined;
    isSharedView: boolean;
  }
> = ({ updateBoringLayerVisibilityHandler, deleteLayer, openAddBoringModalHandler, borings, isSharedView }) => (
  <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1}>
    {!isSharedView && (
      <>
        <Box w="100%" pos="relative">
          <Flex w="100%" alignItems="center" {...LAYER_ITEM_STYLES}>
            <Text pl={2}>CSVを読み込み</Text>
            <Spacer />
            <HStack spacing={0}>
              {/* 24px */}
              <IconButton
                aria-label="add-file"
                fontSize="sm"
                icon={<PlusCircleIcon />}
                onClick={openAddBoringModalHandler}
                size="xs"
                backgroundColor="transparent"
                _hover={{ backgroundColor: 'transparent' }}
              />
              <Square size={6} />
            </HStack>
          </Flex>
        </Box>
      </>
    )}
    {borings?.length && (
      <>
        {groupBy(borings).map((group) => (
          <>
            <ItemGroup title={group[0].file_name}>
              <>
                {group.map((boring) => (
                  <BoringPanelItem
                    key={boring.name.toString()}
                    label={`${boring.name.toString().padStart(4, '0')}`}
                    visible={boring.show}
                    deleteLayer={() => deleteLayer(boring.index)}
                    updateVisibility={(newVisible) => {
                      updateBoringLayerVisibilityHandler(boring.index, newVisible);
                    }}
                    deletable={!isSharedView}
                  />
                ))}
              </>
            </ItemGroup>
          </>
        ))}
      </>
    )}
  </VStack>
);

export const BoringPanel = forwardRef(DataPanelFunction);

function groupBy(array: Boring[]) {
  return array.reduce((result: Boring[][], item: Boring) => {
    const keyValue = item.file_id;

    if (!result.some((group) => group[0].file_id === keyValue)) {
      result.push([item]);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const group = result.find((group) => group[0].file_id === keyValue);
      if (group) group.push(item);
    }

    return result;
  }, []);
}
