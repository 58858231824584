// react modules
import { Box } from '@chakra-ui/react';
// assets, config
import { AssetType } from '../../../../../config/interfaces/views';
import { LAYER_ITEM_ACTIONS_PANEL_STYLES } from '../../../../../config/styles';
// components
import { ActionListPanelItem } from './ListPanelItem';

export const ActionListPanel: React.FC<{
  assetType: AssetType;
  openDeleteAssetConfirmModalHandler: () => void;
  downloadAssetHandler: () => void;
  moveCameraHandler: (vectorType: string) => void;
  changeEditAssetStatusHandler: (actionPanelName: string) => void;
  changePinPosition: () => void;
}> = ({
  assetType,
  openDeleteAssetConfirmModalHandler,
  downloadAssetHandler,
  moveCameraHandler,
  changeEditAssetStatusHandler,
  changePinPosition,
}) => (
  <Box w="150px" pos="absolute" right={1} zIndex={999} textAlign="center" {...LAYER_ITEM_ACTIONS_PANEL_STYLES}>
    {assetType === '3DTILES' && (
      <ActionListPanelItem onClickHandler={() => changeEditAssetStatusHandler('positionOrientation')}>
        描画位置・向き設定
      </ActionListPanelItem>
    )}
    {assetType === 'IMAGE' && (
      <ActionListPanelItem onClickHandler={changePinPosition}>
        ピンの位置を変更
      </ActionListPanelItem>
    )}
    {assetType === '3DTILES' && (
      <ActionListPanelItem onClickHandler={() => changeEditAssetStatusHandler('style')}>
        描画スタイル設定
      </ActionListPanelItem>
    )}
    {assetType === 'IMAGERY' && (
      <ActionListPanelItem onClickHandler={() => changeEditAssetStatusHandler('imageryStyle')}>
        描画スタイル設定
      </ActionListPanelItem>
    )}
    <ActionListPanelItem onClickHandler={() => moveCameraHandler('z')}>上空から見る</ActionListPanelItem>
    {assetType === '3DTILES' && (
      <ActionListPanelItem onClickHandler={() => moveCameraHandler('x')}>東から見る</ActionListPanelItem>
    )}
    {assetType === '3DTILES' && (
      <ActionListPanelItem onClickHandler={() => moveCameraHandler('y')}>北から見る</ActionListPanelItem>
    )}
    <ActionListPanelItem onClickHandler={() => openDeleteAssetConfirmModalHandler()}>
      ファイルを削除
    </ActionListPanelItem>
    <ActionListPanelItem onClickHandler={() => downloadAssetHandler()}>ダウンロード</ActionListPanelItem>
  </Box>
);
