import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button, HStack, Input, Textarea, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { ViewCommentReply } from '../../../../config/interfaces/views';
import { AddAssetReply, AddSharedAssetReply } from '../../../../services/View/AssetReply';

export const Form: React.FC<{
  authToken: string | null;
  asset_id: number;
  view_id: string;
  forSharedView: boolean | undefined;
  setIsFormShown: (show: boolean) => void;
  updateComments: (values: Array<ViewCommentReply>) => void;
}> = ({ authToken, asset_id, view_id, forSharedView, setIsFormShown, updateComments }) => {
  const { user } = useAuth0();
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState('');
  const [body, setBody] = useState('');
  const [error, setError] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const addReply = async () => {
    setSaving(true);

    try {
      const response = await (forSharedView ? AddSharedAssetReply : AddAssetReply )(authToken!, view_id, asset_id, {
        author_name: name,
        reply_body: body,
      });
      updateComments(response);
      closeForm();
    } catch (err) {
      setError('保存に失敗しました。時間をおいてから再度お試しください。');
    } finally {
      setSaving(false);
    }
  };

  const closeForm = () => {
    setName('');
    setBody('');
    setError('');
    setSaving(false);
    setIsFormShown(false);
  };

  useEffect(() => {
    if (user && user.nickname) {
      setName(user.nickname);
    }
  }, [user]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [textAreaRef]);

  return (
    <VStack alignItems="left">
      <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="名前" disabled={saving} />
      <Textarea
        rows={2}
        onChange={(e) => setBody(e.target.value)}
        ref={textAreaRef}
        maxLength={300}
        disabled={saving}
      />
      {error && <Alert status="error">{error}</Alert>}
      <HStack justifyContent="flex-end">
        {!saving && (
          <Button size="sm" onClick={() => closeForm()}>
            キャンセル
          </Button>
        )}
        <Button
          colorScheme="primary"
          size="sm"
          onClick={() => addReply()}
          loadingText="保存中"
          isLoading={saving}
          disabled={saving || !name || !body}
        >
          返信を送る
        </Button>
      </HStack>
    </VStack>
  );
};
