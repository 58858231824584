/**
 * サイズが最大サイズ以下であることをチェックする関数
 * @param {number} size チェック対象サイズ
 * @param {number} maxSize 最大サイズ
 * @return {boolean} 適合: true, 不適合:false
 */
export const checkSize = (size: number, maxSize: number): boolean => {
  if (size <= maxSize) return true;
  return false;
};

/**
 * return float value, within min and max.
 * if invalid value, return null
 * @param {string | number} value float number or string
 * @param {number} min
 * @param {number} max
 * @return {number | null} parsed float value or null
 */
export const returnFloatWithinRange = (value: string | number, min: number, max: number): number | null => {
  const parsedValue = typeof value === 'number' ? value : parseFloat(value);

  if (Number.isFinite(parsedValue)) {
    if (parsedValue < min) {
      return min;
    }
    if (parsedValue > max) {
      return max;
    }
    return parsedValue;
  }
  return null;
};
