import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { CloseIcon } from '../../../../../assets/icons/Index';
import { Style } from '../../../../../config/interfaces/views';
import { ACTION_PANEL_WIDTH, LAYER_ACTION_PANEL_STYLES } from '../../../../../config/styles';
import { _ } from '../../../../../services/Util';

export const ImageryStylePanel: React.FC<{
  assetStyle: Style;
  isEditable: boolean;
  closeHandler: () => void;
  editedStyleHandler: (assetStyle: Style) => void;
  saveStyleHandler: () => void;
}> = ({ assetStyle, isEditable, closeHandler, editedStyleHandler, saveStyleHandler }) => (
  <Box w={ACTION_PANEL_WIDTH} pos="absolute" right={0} zIndex={999} textAlign="center" {...LAYER_ACTION_PANEL_STYLES}>
    {/* 描画表現設定 */}

    <Box textAlign="right">
      <Icon
        as={CloseIcon}
        cursor="pointer"
        onClick={() => {
          if (isEditable) closeHandler();
        }}
      />
    </Box>
    <VStack spacing={4} alignItems="left">
      <Box>
        <Heading as="h4" size="xs">
          描画表現設定
        </Heading>
      </Box>
      <VStack overflowY="auto" maxHeight="300px">
        <FormControl id="scale">
          <FormLabel fontSize="xs">
            <Text fontWeight="bold">透過度</Text>
          </FormLabel>
          <HStack>
            <Slider
              ml={2}
              mr={2}
              aria-label="scale-slider"
              defaultValue={assetStyle.point_alpha * 100}
              value={assetStyle.point_alpha * 100}
              focusThumbOnChange={false}
              min={0}
              max={100}
              step={1}
              onChange={(val) => {
                const newAssetStyle = _.cloneDeep(assetStyle);
                newAssetStyle.point_alpha = Number.parseFloat((val * 0.01).toFixed(2));
                editedStyleHandler(newAssetStyle);
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb borderColor="gray.500" />
            </Slider>

            <Input
              w="88px"
              type="number"
              textAlign="right"
              size="sm"
              value={assetStyle.point_alpha}
              step={0.1}
              backgroundColor="gray.200"
              color="gray.800"
              onChange={(e) => {
                const newAssetStyle = _.cloneDeep(assetStyle);

                if (e.target.value) {
                  const parsedValue = parseFloat(e.target.value);
                  if (parsedValue < 0) {
                    newAssetStyle.point_alpha = 0;
                  } else if (parsedValue > 1) {
                    newAssetStyle.point_alpha = 1;
                  } else {
                    newAssetStyle.point_alpha = parsedValue;
                  }
                } else {
                  newAssetStyle.point_alpha = 1;
                }

                editedStyleHandler(newAssetStyle);
              }}
            />
          </HStack>
        </FormControl>
      </VStack>
      <Box>
        <Button
          w="100%"
          color="white"
          rounded="md"
          _hover={{ bg: useColorModeValue('blue.500', 'gray.500') }}
          bg={useColorModeValue('blue.500', 'gray.500')}
          onClick={() => saveStyleHandler()}
          isLoading={!isEditable}
        >
          保存
        </Button>
      </Box>
    </VStack>
  </Box>
);
