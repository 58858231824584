import { extendTheme } from '@chakra-ui/react';

const Button = {
  sizes: {
    md: {
      fontSize: 'inherit',
    },
  },
  variants: {
    outlinePrimary: {
      borderWidth: '1px',
      borderColor: 'primary.500',
      color: 'primary.500',
    },
  },
};

const Divider = {
  variants: {
    layer: {
      border: 0,
      borderColor: 'whiteAlpha.200',
      borderBottomStyle: 'solid',
    },
  },
};

const FormLabel = {
  baseStyle: {
    color: 'secondary.500',
  },
};

const Link = {
  variants: {
    button: {
      fontWeight: 'semibold',
      paddingBottom: '4',
      paddingLeft: '8',
      paddingRight: '8',
      paddingTop: '4',
      _hover: {
        textDecoration: 'none',
        backgroundColor: 'blackAlpha.100',
      },
    },
    plain: {
      _hover: {
        textDecoration: 'none',
      },
    },
    underline: {
      textDecoration: 'underline',
      color: 'primary.500',
    },
  },
};

const Menu = {
  baseStyle: {
    item: {
      paddingBottom: '2.5',
      paddingTop: '2.5',
    },
    list: {
      minW: '40',
      boxShadow: 'md',
      border: '0',
    },
  },
};

const theme = extendTheme({
  colors: {
    primary: {
      50: '#E5F3FF',
      100: '#BEE1FF',
      200: '#90CCFF',
      300: '#77C0FF',
      400: '#52B0FF',
      500: '#2699FB',
      600: '#248CE4',
      700: '#186FB9',
      800: '#11538B',
      900: '#0E3B62',
    },
    // gray
    secondary: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923,',
    },
  },
  components: {
    Button,
    Divider,
    FormLabel,
    Link,
    Menu,
  },
  styles: {
    global: {
      body: {
        color: 'secondary.600',
        fontSize: 'sm',
      },
      a: {
        color: 'primary.600',
      },
    },
  },
});

export default theme;
