import {
  Button,
  Modal as ModalComponent,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';
import { useImperativeHandle } from 'react';

const ModalFunction: React.ForwardRefRenderFunction<
  {
    openModal: () => void;
  },
  {
    headerTitle: string;
    cancelText?: string;
    okText?: string;
    okColorScheme?: string;
    okClickHandler?: () => void;
    cancelClickHandler?: () => void;
  }
> = (
  {
    children,
    headerTitle,
    cancelText = 'キャンセル',
    okText = 'OK',
    okColorScheme = 'primary',
    okClickHandler,
    cancelClickHandler,
  },
  ref
) => {
  // モーダル設定
  const { isOpen, onOpen, onClose } = useDisclosure();

  // 親コンポーネントから呼び出されるファンクション群の内部処理
  useImperativeHandle(ref, () => ({
    openModal() {
      onOpen();
    },
  }));

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent color="gray.600" mx={2}>
        <ModalHeader>{headerTitle}</ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody pb={6}>{children}</ModalBody>
        <ModalFooter>
          <Button
            mr={3}
            onClick={() => {
              if (cancelClickHandler) {
                cancelClickHandler();
              }
              onClose();
            }}
          >
            {cancelText}
          </Button>
          <Button
            colorScheme={okColorScheme}
            onClick={() => {
              if (okClickHandler) {
                okClickHandler();
              }
              onClose();
            }}
          >
            {okText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalComponent>
  );
};

export const Modal = forwardRef(ModalFunction);
