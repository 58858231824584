import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  Button,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import Linkify from 'react-linkify';

import { HDotsIcon } from '../../../../assets/icons/Index';
import { ViewCommentReply } from '../../../../config/interfaces/views';
import { TimestampToHumanReadable } from '../../../../services/Util';
import { DeleteAssetReply, EditAssetReply } from '../../../../services/View/AssetReply';

export const Comment: React.FC<{
  authToken: string | null;
  asset_id: number;
  view_id: string;
  comment: ViewCommentReply;
  forSharedView: boolean | undefined;
  onDeleted?: (comment: ViewCommentReply) => void;
  onEdited?: (comment: ViewCommentReply) => void;
}> = ({ authToken, asset_id, view_id, comment, forSharedView, onDeleted, onEdited }) => {
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [body, setBody] = useState('');
  const [error, setError] = useState('');

  // references
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  // Handle comment editing
  const handleEdit = useCallback(async () => {
    if (!authToken) return;

    setLoading(true);
    setError('');
    try {
      await EditAssetReply(authToken, view_id, asset_id, comment.reply_id!, body);
      if (onEdited) onEdited({ ...comment, reply_body: body });
      closeForm();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
    setLoading(false);
  }, [view_id, asset_id, comment, body, onEdited, authToken]);

  // Handle comment deleting
  const handleDelete = useCallback(async (): Promise<void> => {
    if (!authToken) return;

    setLoading(true);
    try {
      await DeleteAssetReply(authToken, view_id, asset_id, comment.reply_id!);
      if (onDeleted) onDeleted(comment);
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message);
      }
    }
    setLoading(false);
  }, [view_id, asset_id, comment, onDeleted, authToken]);

  // Open editing form
  const openForm = () => {
    setBody(comment.reply_body);
    setEditing(true);
  };

  // Close editing form
  const closeForm = () => {
    setEditing(false);
    setBody('');
  };

  // Focus on textarea after going into editing mode
  useEffect(() => {
    if (!editing || !textAreaRef) return;

    if (textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.selectionStart = textAreaRef.current.value.length;
    }
  }, [editing, textAreaRef, body]);

  return (
    <VStack alignItems="left" marginBottom="10px">
      <HStack alignItems="center">
        {/* Name and timestamp */}
        {!editing && (
          <HStack color="secondary.400" fontWeight="bold" alignItems="baseline">
            <Text as="span" marginBottom={-0.5}>
              {comment.author_name}
            </Text>
            {comment.updated_at && <Text fontSize="80%">{TimestampToHumanReadable(comment.updated_at)}</Text>}
          </HStack>
        )}

        {/* Editing comment form */}
        {editing && (
          <VStack alignItems="left" width="100%">
            <Textarea
              rows={2}
              onChange={(e) => setBody(e.target.value)}
              ref={textAreaRef}
              maxLength={300}
              disabled={loading}
              value={body}
            />
            {error && <Alert status="error">{error}</Alert>}
            <HStack justifyContent="flex-end">
              {!loading && (
                <Button size="sm" onClick={() => closeForm()}>
                  キャンセル
                </Button>
              )}
              <Button
                colorScheme="primary"
                size="sm"
                onClick={handleEdit}
                loadingText="Saving"
                isLoading={loading}
                disabled={loading || !body}
              >
                返信を送る
              </Button>
            </HStack>
          </VStack>
        )}
        <Spacer />

        {!editing && !forSharedView && comment.user_id === user?.sub && (
          <Menu>
            <MenuButton
              variant="ghost"
              as={IconButton}
              aria-label="Actions"
              fontSize="md"
              size="xs"
              icon={<HDotsIcon />}
              disabled={loading}
            />
            <MenuList>
              <MenuItem onClick={openForm}>編集</MenuItem>
              <MenuItem onClick={handleDelete}>削除</MenuItem>
            </MenuList>
          </Menu>
        )}
      </HStack>

      {/* Comment body */}
      {!editing && (
        <Linkify
          componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
            <Link isExternal variant="underline" key={key} href={decoratedHref}>
              {decoratedHref}
            </Link>
          )}
        >
          <Text whiteSpace="pre-wrap" margin="0 !important">{comment.reply_body}</Text>
        </Linkify>
      )}
    </VStack>
  );
};
