// third party modules
import axios from 'axios';

type ProcessName =
  | 'dashboard'
  | 'createView'
  | 'editView'
  | 'deleteView'
  | 'loadView'
  | 'saveViewShareLink'
  | 'addAsset'
  | 'deleteAsset'
  | 'saveAsset'
  | 'downloadAsset'
  | 'processing';

/**
 * Control try-catch errors in processing and display error messages
 * @param {Error} err Error object
 * @param {string} processName
 */
export const ProcessErrorHandler = (err: unknown, processName: ProcessName): void => {
  // エラーの種類によって、エラーメッセージを設定
  let alertMessage = '不明なエラーが発生しました。\n時間をおいて、再度実行してください。';
  if (axios.isAxiosError(err) && err.response) {
    console.error(err.response.data);

    if (processName === 'dashboard')
      alertMessage = '一覧取得処理でエラーが発生しました。\n時間をおいてから再度お試しください。';
    if (processName === 'createView')
      alertMessage = '作成処理でエラーが発生しました。\n時間をおいてから再度お試しください。';
    if (processName === 'editView')
      alertMessage = '編集処理でエラーが発生しました。\n時間をおいてから再度お試しください。';
    if (processName === 'deleteView')
      alertMessage = '削除処理でエラーが発生しました。\n時間をおいてから再度お試しください。';
    if (processName === 'loadView')
      alertMessage = '読込処理でエラーが発生しました。\n時間をおいてから再度お試しください。';
    if (processName === 'saveViewShareLink')
      alertMessage = '共有リンク保存処理でエラーが発生しました。\n時間をおいてから再度お試しください。';
    if (processName === 'addAsset')
      alertMessage = 'ファイル追加処理でエラーが発生しました。\n時間をおいてから再度お試しください。';
    if (processName === 'deleteAsset')
      alertMessage = 'ファイル削除処理でエラーが発生しました。\n時間をおいてから再度お試しください。';
    if (processName === 'saveAsset')
      alertMessage = 'ファイル更新処理でエラーが発生しました。\n時間をおいてから再度お試しください。';
    if (processName === 'downloadAsset')
      alertMessage = 'ダウンロード処理でエラーが発生しました。\n時間をおいてから再度お試しください。';
    if (processName === 'processing')
      alertMessage = '変換処理でエラーが発生しました。\n時間をおいてから再度お試しください。';

    const statusCode = err.response.status;
    if (statusCode === 400) alertMessage = '不正なリクエストです。ページを更新して再度お試しください。';
    if (statusCode === 403) alertMessage = '認証情報に不備があります。ログインし直して再度お試しください。';
    if (statusCode === 404) alertMessage = 'データが存在しません。';
  } else if (err instanceof Error) console.error(err.message);
  else {
    console.error(err);
  }

  alert(alertMessage);
};
