// react modules
import { Box, Flex, HStack, IconButton, Spacer, Square, Text } from '@chakra-ui/react';

// third party modules
// components
// services
// assets, config
import { HideIcon, ShowIcon } from '../../../../assets/icons/Index';
import { LAYER_ITEM_STYLES } from '../../../../config/styles';

export const GISPanelItem: React.FC<{
  label: string;
  visible: boolean;
  updateVisibility: (newVisible: boolean) => void;
}> = ({ label, visible, updateVisibility }) => (
  <Box w="100%" pos="relative">
    <Flex w="100%" alignItems="center" {...LAYER_ITEM_STYLES}>
      <Text pl={2}>{label}</Text>
      <Spacer />
      <HStack spacing={0}>
        {/* 24px */}
        <IconButton
          aria-label={visible ? 'show' : 'hide'}
          fontSize="sm"
          icon={visible ? <ShowIcon /> : <HideIcon />}
          onClick={() => updateVisibility(!visible)}
          size="xs"
          variant="toolbarIcon"
        />
        <Square size={6} />
      </HStack>
    </Flex>
  </Box>
);
