import { ToastId, UseToastOptions } from '@chakra-ui/react';
import dayjs from 'dayjs';
import * as lodash from 'lodash';
import { FileInfo } from '../config/interfaces/util';

// lodash alias
export const _ = lodash;

/**
 * 指定桁数で数値を四捨五入
 * @param val 対象数値
 * @param decimalLength 小数点decimalLength位までの数値にする
 * @returns number
 */
export const calcRound = (val: number, decimalLength: number): number =>
  Math.round(val * 10 ** decimalLength) / 10 ** decimalLength;

/**
 * return file info obj
 * @param {string} fileName filename
 * @return {{originFilename: string, filename: string, extension: string, contentType: string} | null} file info obj
 */
export const getFileInfo = (fileName: string): FileInfo | null => {
  const filenameArr = fileName.split('.');
  if (filenameArr.length === 0) return null;

  let contentType = '';
  const extension = filenameArr[filenameArr.length - 1].toLocaleLowerCase();
  if (extension === 'gltf') contentType = 'model/gltf+json';
  else if (extension === 'tif') contentType = 'image/tiff';
  else if (extension === 'tiff') contentType = 'image/tiff';
  else if (extension === 'las') contentType = 'text/plain';
  else if (extension === 'e57') contentType = 'text/plain';
  else if (extension === 'ply') contentType = 'text/plain';
  else if (extension === 'pts') contentType = 'text/plain';
  else if (extension === 'xyz') contentType = 'text/plain';
  else if (extension === 'xyzrgb') contentType = 'text/plain';
  else if (extension === 'fbx') contentType = 'application/octet-stream';
  else if (extension === 'obj') contentType = 'model/obj';
  else if (extension === 'kml') contentType = 'text/kml';
  else if (extension === 'geojson') contentType = 'application/geojson';
  else if (extension === 'zip') contentType = 'application/zip';
  else if (extension.toLocaleLowerCase() === 'jpg' || extension.toLocaleLowerCase() === 'jpeg')
    contentType = 'image/jpeg';

  if (!contentType) return null;

  return {
    originFilename: fileName,
    filename: `${dayjs().format('YYYYMMDDHHmmss')}_${filenameArr[0]}.${extension}`,
    extension,
    contentType,
  };
};

/**
 * waiting process
 * @param {number} waitTime millisecond
 */
export const sleep = (waitTime: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, waitTime));

/**
 * copy a text into clipboard
 * @param text text to copy
 * @param toastTitle Title you want to display when copied
 * @param toast toast service to show copy result
 */
export const CopyText = async (
  text: string,
  toastTitle: string,
  toast: (options?: UseToastOptions | undefined) => ToastId | undefined
): Promise<void> => {
  // show toast
  showToast(toastTitle, text, 'success', toast);

  // copy url to clipboard
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    alert('コピーに失敗しました');
  }
};

/**
 * show toast
 * @param toastTitle
 * @param toastDescription
 * @param status decided toast theme by status
 * @param toast toast service
 */
export const showToast = (
  toastTitle: string,
  toastDescription: string,
  status: 'success' | 'error' | 'warning' | 'info',
  toast: (options?: UseToastOptions | undefined) => ToastId | undefined
): void => {
  const toastConfig = {
    title: toastTitle,
    status,
    duration: 2500,
    isClosable: true,
    position: 'top',
  } as UseToastOptions;

  if (toastDescription) toastConfig.description = toastDescription;

  toast(toastConfig);
};

/**
 * Converts UNIX timestamp to date format.
 *
 * @param timestamp UNIX timestamp
 * @returns Date formatted in local format
 */
export const TimestampToHumanReadable = (timestamp: number): string =>
  new Date(timestamp).toLocaleString('en-ZA', { hour12: false });
