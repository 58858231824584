// react modules
import { Box, Flex, HStack, IconButton, Spacer, Text } from '@chakra-ui/react';

// third party modules
// components
// services
// assets, config
import { DeleteIcon, HideIcon, ShowIcon } from '../../../../assets/icons/Index';
import { LAYER_ITEM_FOCUSED_BACKGROUND_COLOR, LAYER_ITEM_STYLES } from '../../../../config/styles';

export const DistancePanelItem: React.FC<{
  label: string;
  visible: boolean;
  updateVisibility: (newVisible: boolean) => void;
  deleteLayer: () => void;
  focused: boolean;
  setFocused: (focused: boolean) => void;
}> = ({ label, visible, updateVisibility, deleteLayer, focused, setFocused }) => (
  <Box w="100%" pos="relative" onMouseEnter={() => setFocused(true)}>
    <Flex
      w="100%"
      alignItems="center"
      {...LAYER_ITEM_STYLES}
      backgroundColor={focused ? LAYER_ITEM_FOCUSED_BACKGROUND_COLOR : 'transparent'}
    >
      <Text pl={2}>{label}</Text>
      <Spacer />
      <HStack spacing={0}>
        {/* 24px */}
        <IconButton
          aria-label={visible ? 'show' : 'hide'}
          fontSize="sm"
          icon={visible ? <ShowIcon /> : <HideIcon />}
          onClick={() => updateVisibility(!visible)}
          size="xs"
          variant="toolbarIcon"
        />
        <IconButton
          aria-label="delete"
          fontSize="sm"
          icon={<DeleteIcon />}
          onClick={deleteLayer}
          size="xs"
          variant="toolbarIcon"
        />
      </HStack>
    </Flex>
  </Box>
);
