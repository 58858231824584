// react modules
import { Box, Flex, HStack, IconButton, Spacer, Square, Text } from '@chakra-ui/react';

// third party modules
// components
// services
// assets, config
import { DeleteIcon, HideIcon, ShowIcon } from '../../../../assets/icons/Index';
import { LAYER_ITEM_STYLES } from '../../../../config/styles';

export const BoringPanelItem: React.FC<{
  label: string;
  visible: boolean;
  updateVisibility: (newVisible: boolean) => void;
  deleteLayer: () => void;
  deletable: boolean;
}> = ({ label, visible, updateVisibility, deleteLayer, deletable }) => (
  <Box w="100%" pos="relative" cursor="pointer">
    <Flex w="100%" alignItems="center" {...LAYER_ITEM_STYLES} backgroundColor="transparent">
      <Text pl={4}>{label}</Text>
      <Spacer />
      <HStack spacing={0}>
        {/* 24px */}
        <IconButton
          aria-label={visible ? 'show' : 'hide'}
          fontSize="sm"
          icon={visible ? <ShowIcon /> : <HideIcon />}
          onClick={(e) => {
            e.stopPropagation();
            updateVisibility(!visible);
          }}
          size="xs"
          variant="toolbarIcon"
        />
        {deletable && (
          <IconButton
            aria-label="delete"
            fontSize="sm"
            icon={<DeleteIcon />}
            onClick={(e) => {
              e.stopPropagation();
              deleteLayer();
            }}
            size="xs"
            variant="toolbarIcon"
          />
        )}
        {!deletable && <Square size={6} />}
      </HStack>
    </Flex>
  </Box>
);
