import { ToolbarMoveIcon } from '../../../assets/icons/Index';
import { VIEWER_TOOLBAR_SHORTCUT } from '../../../config/constants';
import ToolbarButton from './_components/ToolbarButton';

const MoveTool: React.FC<{ isActive: boolean; onClick: () => void }> = ({ isActive, onClick }) => (
  <ToolbarButton
    icon={<ToolbarMoveIcon style={{ transform: 'scaleX(-1) rotateZ(-10deg)', fontSize: '75%' }} />}
    label={`移動ツール(${VIEWER_TOOLBAR_SHORTCUT.MOVE_TOOL[0]})`}
    isActive={isActive}
    onClick={onClick}
  />
);

export default MoveTool;
