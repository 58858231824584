export const WGS84_EPSG = {
  epsg: 'EPSG:4326',
  alias: 'wgs84',
  projection: '+proj=longlat +datum=WGS84 +no_defs',
  description: '地理座標系（緯度経度）',
};

export const JP_EPSG_LIST = [
  {
    epsg: 'EPSG:6669',
    alias: 'jp_1',
    projection: '+proj=tmerc +lat_0=33 +lon_0=129.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6669（日本の平面直角座標系1系）',
  },
  {
    epsg: 'EPSG:6670',
    alias: 'jp_2',
    projection: '+proj=tmerc +lat_0=33 +lon_0=131 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6670（日本の平面直角座標系2系）',
  },
  {
    epsg: 'EPSG:6671',
    alias: 'jp_3',
    projection: '+proj=tmerc +lat_0=36 +lon_0=132.1666666666667 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6671（日本の平面直角座標系3系）',
  },
  {
    epsg: 'EPSG:6672',
    alias: 'jp_4',
    projection: '+proj=tmerc +lat_0=33 +lon_0=133.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6672（日本の平面直角座標系4系）',
  },
  {
    epsg: 'EPSG:6673',
    alias: 'jp_5',
    projection: '+proj=tmerc +lat_0=36 +lon_0=134.3333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6673（日本の平面直角座標系5系）',
  },
  {
    epsg: 'EPSG:6674',
    alias: 'jp_6',
    projection: '+proj=tmerc +lat_0=36 +lon_0=136 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6674（日本の平面直角座標系6系）',
  },
  {
    epsg: 'EPSG:6675',
    alias: 'jp_7',
    projection: '+proj=tmerc +lat_0=36 +lon_0=137.1666666666667 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6675（日本の平面直角座標系7系）',
  },
  {
    epsg: 'EPSG:6676',
    alias: 'jp_8',
    projection: '+proj=tmerc +lat_0=36 +lon_0=138.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6676（日本の平面直角座標系8系）',
  },
  {
    epsg: 'EPSG:6677',
    alias: 'jp_9',
    projection: '+proj=tmerc +lat_0=36 +lon_0=139.8333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6677（日本の平面直角座標系9系）',
  },
  {
    epsg: 'EPSG:6678',
    alias: 'jp_10',
    projection: '+proj=tmerc +lat_0=40 +lon_0=140.8333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6678（日本の平面直角座標系10系）',
  },
  {
    epsg: 'EPSG:6679',
    alias: 'jp_11',
    projection: '+proj=tmerc +lat_0=44 +lon_0=140.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6679（日本の平面直角座標系11系）',
  },
  {
    epsg: 'EPSG:6680',
    alias: 'jp_12',
    projection: '+proj=tmerc +lat_0=44 +lon_0=142.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6680（日本の平面直角座標系12系）',
  },
  {
    epsg: 'EPSG:6681',
    alias: 'jp_13',
    projection: '+proj=tmerc +lat_0=44 +lon_0=144.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6681（日本の平面直角座標系13系）',
  },
  {
    epsg: 'EPSG:6682',
    alias: 'jp_14',
    projection: '+proj=tmerc +lat_0=26 +lon_0=142 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6682（日本の平面直角座標系14系）',
  },
  {
    epsg: 'EPSG:6683',
    alias: 'jp_15',
    projection: '+proj=tmerc +lat_0=26 +lon_0=127.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6683（日本の平面直角座標系15系）',
  },
  {
    epsg: 'EPSG:6684',
    alias: 'jp_16',
    projection: '+proj=tmerc +lat_0=26 +lon_0=124 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6684（日本の平面直角座標系16系）',
  },
  {
    epsg: 'EPSG:6685',
    alias: 'jp_17',
    projection: '+proj=tmerc +lat_0=26 +lon_0=131 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6685（日本の平面直角座標系17系）',
  },
  {
    epsg: 'EPSG:6686',
    alias: 'jp_18',
    projection: '+proj=tmerc +lat_0=20 +lon_0=136 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6686（日本の平面直角座標系18系）',
  },
  {
    epsg: 'EPSG:6687',
    alias: 'jp_19',
    projection: '+proj=tmerc +lat_0=26 +lon_0=154 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs',
    description: 'EPSG:6687（日本の平面直角座標系19系）',
  },
];

export const SG_EPSG_LIST = [
  {
    epsg: 'EPSG:3414',
    alias: 'sg_1',
    projection:
      '+proj=tmerc +lat_0=1.36666666666667 +lon_0=103.833333333333 +k=1 +x_0=28001.642 +y_0=38744.572 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs',
    description: 'EPSG:3414（シンガポールの平面直角座標系）',
  },
];
