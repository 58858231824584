// react modules
import { useImperativeHandle, useState, useRef } from 'react';
import { forwardRef } from '@chakra-ui/react';

import { Modal } from '../../../view/InfoPanels/_components/Modal';

// 削除確認モーダルコンポーネント
const DeleteBoringConfirmModalFunction: React.ForwardRefRenderFunction<
  // 親コンポーネントでの呼び出し用ファンクション群定義
  {
    openModal: (index: number) => void;
  },
  // 親コンポーネントから伝搬される変数・ファンクション群定義
  {
    deleteBoringHandler: (index: number) => void;
  }
> = ({ deleteBoringHandler }, ref) => {
  // 削除対象のcomment index保持用変数
  const [index, setIndex] = useState<number>(-1);

  // 親コンポーネントから呼び出されるファンクション群の内部処理
  useImperativeHandle(ref, () => ({
    openModal(_deleteIndex: number) {
      setIndex(_deleteIndex);
      modalRef.current?.openModal();
    },
  }));

  const modalRef = useRef<{
    openModal: () => void;
  }>();

  return (
    <>
      <Modal
        ref={modalRef}
        headerTitle="ボーリングデータを削除しますか？"
        okText="削除"
        okColorScheme="red"
        okClickHandler={() => deleteBoringHandler(index)}
      >
        一度削除してしまうと、元に戻せません。
      </Modal>
    </>
  );
};

export const DeleteBoringConfirmModal = forwardRef(DeleteBoringConfirmModalFunction);
