import { Cartesian3 } from 'cesium';
import { useState } from 'react';
import { ViewCommentReply } from '../config/interfaces/views';
import {
  AddComment,
  AddCommentReply,
  AddSharedComment,
  AddSharedCommentReply,
  DeleteCommentReply,
  EditComment,
  EditCommentReply,
  GetCommentReplies,
  GetSharedCommentReplies,
} from '../services/View/View';

const useCommentPopup = (
  body: string,
  closeEditBody: () => void,
  closePopup: () => void,
  comment_id: string,
  editingReplyIndex: number,
  name: string,
  onCommentModified: () => void,
  onCommentReplyAmountChanged: (commentId: string, amount: number) => void,
  position: Cartesian3 | undefined,
  setLoading: (loading: boolean) => void,
  setShowInputs: (show: boolean) => void,
  showCommentEditInputs: boolean,
  view_id: string | undefined,
  authToken: string | null,
  forSharedView?: boolean
) => {
  const [replies, setReplies] = useState<ViewCommentReply[]>([]);

  const AuthAddComment = forSharedView ? AddSharedComment : AddComment;
  const AuthAddCommentReply = forSharedView ? AddSharedCommentReply : AddCommentReply;
  const AuthGetCommentReplies = forSharedView ? GetSharedCommentReplies : GetCommentReplies;

  const fetchReplies = async () => {
    if (authToken !== null && view_id && comment_id) {
      setLoading(true);

      setReplies(await AuthGetCommentReplies(authToken, view_id, comment_id));
      setLoading(false);
    }
  };

  const addComment = async () => {
    setLoading(true);

    if (authToken !== null && view_id && position) {
      const newComment = {
        comment_body: body.trim(),
        author_name: name.trim(),
        cartesian_position_x: position.x,
        cartesian_position_y: position.y,
        cartesian_position_z: position.z,
      };

      if (await AuthAddComment(authToken, view_id, newComment)) {
        onCommentModified();
        closePopup();
      }
    }
    setLoading(false);
  };

  const addReply = async () => {
    if (!comment_id) {
      return;
    }

    setLoading(true);
    const reply: ViewCommentReply = {
      reply_body: body.trim(),
      author_name: name.trim(),
    };

    if (authToken !== null && view_id && (await AuthAddCommentReply(authToken, view_id, comment_id, reply))) {
      setShowInputs(false);
      await fetchReplies();
      onCommentReplyAmountChanged(comment_id, 1);
    }
    setLoading(false);
  };

  const deleteReply = async (index: number) => {
    if (!comment_id) {
      return;
    }

    setLoading(true);
    if (
      authToken !== null &&
      view_id &&
      replies[index].reply_id &&
      (await DeleteCommentReply(authToken, view_id, comment_id, replies[index].reply_id || ''))
    ) {
      setShowInputs(false);
      await fetchReplies();
      onCommentReplyAmountChanged(comment_id, -1);
    }
    setLoading(false);
  };

  const editBody = async () => {
    if (!comment_id) {
      return;
    }

    setLoading(true);
    if (authToken !== null && view_id) {
      if (showCommentEditInputs && (await EditComment(authToken, view_id, comment_id, body.trim()))) {
        onCommentModified();
        closeEditBody();
      } else if (
        editingReplyIndex >= 0 &&
        replies[editingReplyIndex]?.reply_id &&
        (await EditCommentReply(authToken, view_id, comment_id, replies[editingReplyIndex].reply_id || '', body.trim()))
      ) {
        await fetchReplies();
        closeEditBody();
      }
    }
    setLoading(false);
  };

  return {
    addComment,
    addReply,
    deleteReply: forSharedView ? () => null : deleteReply,
    editBody: forSharedView ? () => null : editBody,
    fetchReplies,
    replies,
    setReplies,
  };
};

export default useCommentPopup;
