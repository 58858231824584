// react modules
import { useImperativeHandle, useState, useRef } from 'react';
import { forwardRef } from '@chakra-ui/react';

import { Modal } from '../_components/Modal';
import { Asset } from '../../../../config/interfaces/views';

// 変更破棄確認モーダルコンポーネント
const RevertConfirmModalFunction: React.ForwardRefRenderFunction<
  // 親コンポーネントでの呼び出し用ファンクション群定義
  {
    openModal: (revertAsset: Asset) => void;
  },
  // 親コンポーネントから伝搬される変数・ファンクション群定義
  {
    revertAssetHandler: (asset: Asset) => void;
  }
> = ({ revertAssetHandler }, ref) => {
  // 破棄対象のasset index保持用変数
  const [revertAsset, setRevertAsset] = useState<Asset>();

  // 親コンポーネントから呼び出されるファンクション群の内部処理
  useImperativeHandle(ref, () => ({
    openModal(_revertAsset: Asset) {
      setRevertAsset(_revertAsset);
      modalRef.current?.openModal();
    },
  }));

  const modalRef = useRef<{
    openModal: () => void;
  }>();

  return (
    <>
      <Modal
        ref={modalRef}
        headerTitle="変更を破棄しますか？"
        okText="破棄"
        okColorScheme="red"
        okClickHandler={() => {
          if (revertAsset === undefined) return;
          revertAssetHandler(revertAsset);
        }}
      >
        設定が変更前に戻ります。
      </Modal>
    </>
  );
};

export const RevertConfirmModal = forwardRef(RevertConfirmModalFunction);
