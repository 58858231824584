// react modules
import { useImperativeHandle, useState, useRef } from 'react';
import { forwardRef } from '@chakra-ui/react';

import { Modal } from '../_components/Modal';

// ファイル追加完了モーダルコンポーネント
const CompletedAddAssetModalFunction: React.ForwardRefRenderFunction<
  // 親コンポーネントでの呼び出し用ファンクション群定義
  {
    openModal: (assetIndex: number) => void;
  },
  // 親コンポーネントから伝搬される変数・ファンクション群定義
  {
    zoomAssetHandler: (assetIndex: number) => void;
  }
> = ({ zoomAssetHandler }, ref) => {
  // 追加されたassetId保持用変数
  const [assetIndex, setAssetIndex] = useState<number>();

  // 親コンポーネントから呼び出されるファンクション群の内部処理
  useImperativeHandle(ref, () => ({
    openModal(_assetIndex: number) {
      setAssetIndex(_assetIndex);
      modalRef.current?.openModal();
    },
  }));

  const modalRef = useRef<{
    openModal: () => void;
  }>();

  return (
    <>
      <Modal
        ref={modalRef}
        headerTitle=""
        okText="はい"
        okColorScheme="primary"
        cancelText="いいえ"
        okClickHandler={() => {
          if (assetIndex === undefined) return;
          zoomAssetHandler(assetIndex);
        }}
      >
        ファイルの追加が完了しました。描画場所に移動しますか？
      </Modal>
    </>
  );
};

export const CompletedAddAssetModal = forwardRef(CompletedAddAssetModalFunction);
