// react modules
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Protected } from './services/Auth';

// pages
import HomePage from './pages/homePage/HomePage';
import { Dashboard } from './pages/dashboard/Index';
import { View } from './pages/view/Index';
import { Share } from './pages/share/Index';
import { Boring } from './pages/boring/Index';

const App: React.FunctionComponent = () => {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <p />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/share/:view_id" element={<Share />} />
        <Route element={<Protected />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/view/:view_id" element={<View />} />
          <Route path="/view/:view_id/boring" element={<Boring />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
