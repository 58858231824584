import { Box } from '@chakra-ui/react';
import { AssetType } from '../../../../../config/interfaces/views';
import { LAYER_ITEM_ACTIONS_PANEL_STYLES } from '../../../../../config/styles';
import { ActionListPanelItem } from './ListPanelItem';

export const ActionListPanel: React.FC<{
  downloadAssetHandler: () => void;
  moveCameraHandler: (vectorType: string) => void;
  assetType: AssetType;
  disallows_share_download: boolean | undefined;
}> = ({ downloadAssetHandler, moveCameraHandler, assetType, disallows_share_download }) => (
  <Box w="150px" pos="absolute" right={1} zIndex={999} textAlign="center" {...LAYER_ITEM_ACTIONS_PANEL_STYLES}>
    <ActionListPanelItem onClickHandler={() => moveCameraHandler('z')}>上空から見る</ActionListPanelItem>
    {assetType === '3DTILES' && (
      <ActionListPanelItem onClickHandler={() => moveCameraHandler('x')}>東から見る</ActionListPanelItem>
    )}
    {assetType === '3DTILES' && (
      <ActionListPanelItem onClickHandler={() => moveCameraHandler('y')}>北から見る</ActionListPanelItem>
    )}
    {disallows_share_download !== true && ( // if undefined, it was uploaded before this feature and should be allowed
      <ActionListPanelItem onClickHandler={() => downloadAssetHandler()}>ダウンロード</ActionListPanelItem>
    )}
  </Box>
);
