import {
  Alert,
  Box,
  Button,
  Flex,
  forwardRef,
  HStack,
  IconButton,
  Image as CImage,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  Skeleton,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ScreenSpaceEventHandler, Viewer } from 'cesium';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { EditIcon, HDotsIcon } from '../../../assets/icons/Index';
import { Asset, Position } from '../../../config/interfaces/views';
import { GetPreSignUrlForDownloadAsset } from '../../../services/AWS/S3';
import { TimestampToHumanReadable } from '../../../services/Util';
import { SaveAssetSettings } from '../../../services/View/Asset';
import {
  SelectPositionDialog,
  SelectPositionDialogForwardRef,
} from '../InfoPanels/modal/_components/SelectPositionDialog';
import { Comments } from './_components/Comments';

const styleBox = {
  backgroundColor: 'white',
  borderRadius: '5px',
  padding: '16px',
}

const imageModalStyles = {
  variants: {
    'image-preview': {
      dialogContainer: {
        overflow: 'hidden',
        width: 'calc(100% - 300px)',
        height: '100%',
        padding: '20px',
        justifyContent: 'center',
      },
      dialog: {
        borderRadius: '5px',
        width: 'auto',
        bg: 'gray.100',
      },
      body: {
        p: '8px',
        height: '100%',
      },
      image: {
        borderRadius: '5px',
        maxHeight: 'calc(100vh - 60px)',
        alignSelf: 'center',
        width: '100%',
        height: '100%',
        'object-fit': 'contain'
      },
      description: {
        ...styleBox,
        width: '100%',
      },
      descriptionText: {
        maxHeight: '200px',
        overflow: 'auto'
      },
      comments: {
        ...styleBox,
        alignItems: 'left',
        padding: '16px',
        width: '350px',
        height: '100%',
      }
    }
  }
};

const { variants: { 'image-preview': styles } } = imageModalStyles;

const ImageModalFunction: React.ForwardRefRenderFunction<
  {
    openModal: (asset: Asset | null, view_id: string, entityId: string) => void;
  },
  {
    authToken: string | null;
    cesiumViewer: Viewer | undefined;
    cesiumScreenSpaceEventHandler: ScreenSpaceEventHandler | undefined;
    forSharedView: boolean | undefined;
    setIsToolDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    reloadView: () => Promise<void>;
  }
> = (
  { authToken, cesiumViewer, cesiumScreenSpaceEventHandler, setIsToolDisabled, reloadView, forSharedView },
  componentRef
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useImperativeHandle(componentRef, () => ({
    async openModal(asset: Asset | null, view_id: string, entityId: string) {
      if (!asset) return;

      setAsset(asset);
      setViewId(view_id);
      setEntityId(entityId);

      if (asset.asset_settings.description) {
        setCurrentDescription(asset.asset_settings.description);
      }

      onOpen();

      setImageUrl(
        await GetPreSignUrlForDownloadAsset(authToken!, view_id, asset.asset_id, 'image/jpeg', forSharedView)
      );
    },
  }));

  const selectPositionDialogRef = useRef<SelectPositionDialogForwardRef>(null);
  const [asset, setAsset] = useState<Asset | null>(null);
  const [viewId, setViewId] = useState<string | null>(null);
  const [entityId, setEntityId] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [currentDescription, setCurrentDescription] = useState<string>('');
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [descriptionError, setDescriptionError] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  // Save new description
  const onSaveDescription = useCallback(async (): Promise<void> => {
    if (!viewId || !asset || !authToken) return Promise.resolve();

    setIsSaving(true);

    const assetSettings = { ...asset.asset_settings, description: textAreaRef.current?.value };

    try {
      setDescriptionError('');

      await SaveAssetSettings(authToken, viewId, asset.asset_id, assetSettings);

      setIsEditingDescription(false);
      setAsset({ ...cloneDeep(asset), asset_settings: assetSettings });
    }
    catch(err) {
      setDescriptionError(err instanceof Error ? err.message : err as string);
    }
    finally {
      setIsSaving(false);
    }

    // Reload view so if user close the modal and re-opens,
    // the new description will be shown.
    return reloadView();
  }, [asset, viewId, authToken, reloadView]);

  // Reset everything when closing the modal
  const handleModalClose = useCallback((): void => {
    onClose();

    // Reset values
    setAsset(null);
    setViewId('');
    setEntityId('');
    setImageUrl('');
    setCurrentDescription('');
    setIsEditingDescription(false);
    setIsSaving(false);
    setIsToolDisabled(false);
  }, [onClose, setIsToolDisabled]);

  // Start the change location flow
  const startChangeLocation = useCallback(() => {
    if (!selectPositionDialogRef) return;

    onClose();
    setIsToolDisabled(() => true);
    selectPositionDialogRef.current?.openModal(entityId, asset?.asset_settings.position);
  }, [selectPositionDialogRef, asset, entityId, onClose, setIsToolDisabled]);

  // Save the new position after a new location has been selected
  const handleLocationSelected = useCallback(
    async (newPosition: Position | null) => {
      if (!viewId || !asset || !authToken) return Promise.resolve();

      if (newPosition) {
        const assetSettings = { ...asset.asset_settings, position: newPosition };

        await SaveAssetSettings(authToken, viewId, asset.asset_id, assetSettings);

        setAsset({ ...cloneDeep(asset), asset_settings: assetSettings });

        await reloadView();

        handleModalClose();

        return Promise.resolve();
      }

      onOpen();

      return Promise.resolve();
    },
    [asset, viewId, handleModalClose, authToken, onOpen, reloadView]
  );

  // A version to cache the image into the component.
  // Must have CORS properly set in S3 first.
  // useEffect(() => {
  //   void axios.get(imageUrl, {responseType: 'arraybuffer'})
  //     .then((res) => {
  //       const buf = Buffer.from(res.data, 'binary').toString('base64');
  //       const img = new Image();
  //       img.onload = () => {
  //         console.log(img.width, img.height);
  //       };
  //       img.src = `data:image/jpeg;base64,${buf}`;
  //     })
  // }, [imageUrl]);

  useEffect(() => {
    if (!isEditingDescription || !textAreaRef.current || !asset) return;

    textAreaRef.current.focus();
    textAreaRef.current.selectionStart = textAreaRef.current.value.length;
  }, [isEditingDescription, asset]);

  useEffect(() => {
    if (isOpen) setIsToolDisabled(true);
  }, [isOpen, setIsToolDisabled]);

  return (
    <>
      {!forSharedView && (
        <SelectPositionDialog
          ref={selectPositionDialogRef}
          onSavePosition={handleLocationSelected}
          cesiumViewer={cesiumViewer}
        />
      )}

      <Modal isOpen={isOpen} onClose={handleModalClose} trapFocus={false} variant="image-preview" styleConfig={imageModalStyles}>
        {asset && (
          <ModalContent>
            <ModalBody>
              <HStack width="100%" alignItems="flex-start" height="100%">
                <VStack flex={1} minWidth="350px" height="100%" flexDirection="column">
                  <Flex flex={1} minHeight="0">
                    {imageUrl && <CImage src={imageUrl} style={styles.image} />}
                    {/** Skeleton works but different size makes it not nice when switching between skeleton and actual image */}
                    {!imageUrl && <Skeleton width="450px" height="75%" borderRadius={5} alignSelf="center" mx={10} />}
                  </Flex>

                  <Box style={styles.description}>
                    {/* Title and timestamp */}
                    <Flex width="100%" fontWeight="bold" fontSize="md">
                      <Flex flexDirection="column">
                        <Text>{asset.file_name}</Text>
                        {asset.created_at && (
                          <Text color="secondary.400" fontSize="70%">
                            {TimestampToHumanReadable(asset.created_at)}
                          </Text>
                        )}
                      </Flex>
                      <Spacer />
                      {!forSharedView && (
                        <Menu>
                          <MenuButton
                            variant="ghost"
                            as={IconButton}
                            aria-label="Actions"
                            fontSize="md"
                            size="xs"
                            icon={<HDotsIcon />}
                          />
                          <MenuList fontSize="sm">
                            <MenuItem onClick={startChangeLocation}>ピンの位置を変更</MenuItem>
                          </MenuList>
                        </Menu>
                      )}
                    </Flex>

                    {/* Description normal state */}
                    {!isEditingDescription && (
                      <>
                        {currentDescription && (
                          <Text whiteSpace="pre-wrap" style={styles.descriptionText}>{currentDescription}</Text>
                        )}
                        {!forSharedView && (
                          <Flex>
                            <Button
                              aria-label="edit-description"
                              leftIcon={<EditIcon />}
                              size="xs"
                              fontSize="xs"
                              onClick={() => setIsEditingDescription(true)}
                              mt={2}
                            >
                              説明文を編集
                            </Button>
                          </Flex>
                        )}
                      </>
                    )}

                    {/* Description editing state */}
                    {isEditingDescription && (
                      <>
                        <Textarea
                          ref={textAreaRef}
                          placeholder="説明文を入力..."
                          value={currentDescription}
                          onChange={(event) => setCurrentDescription(event.target.value)}
                        />
                        { descriptionError ?  <Alert status="error" mt={2} borderRadius={5}>{descriptionError}</Alert> : '' }
                        <HStack justifyContent="flex-end" mt={2}>
                          {!isSaving && (
                            <Button
                              aria-label="edit-description"
                              size="sm"
                              fontSize="sm"
                              onClick={() => setIsEditingDescription(false)}
                            >
                              キャンセル
                            </Button>
                          )}
                          <Button
                            aria-label="edit-description"
                            colorScheme="primary"
                            leftIcon={<EditIcon />}
                            size="sm"
                            fontSize="sm"
                            onClick={onSaveDescription}
                            isLoading={isSaving}
                            disabled={isSaving}
                            loadingText="保存中"
                          >
                            保存
                          </Button>
                        </HStack>
                      </>
                    )}
                  </Box>
                </VStack>

                  {viewId && (
                    <VStack style={styles.comments}>
                      <Box flex={1} minHeight={0} width="100%">
                        <Comments
                          authToken={authToken}
                          asset_id={asset.asset_id}
                          view_id={viewId}
                          forSharedView={forSharedView}
                          handleModalClose={handleModalClose}
                        />
                      </Box>
                    </VStack>
                  )}
                {/* description */}
              </HStack>
            </ModalBody>
          </ModalContent>
        )}
      </Modal>
    </>
  );
};

export const ImageModal = forwardRef(ImageModalFunction);
