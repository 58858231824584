// react modules
import { useState } from 'react';
import { Center, Divider, VStack } from '@chakra-ui/react';

// third party modules

// components
import { DataPanelItem } from './PanelItem';

// services

// assets, config
import { View, Asset } from '../../../../config/interfaces/views';
import { INFO_PANEL_PADDING } from '../../../../config/styles';

const INITIAL_EDIT_ASSET_STATUS = {
  assetId: null,
  activeActionPanelName: '',
  // isEdited: false,
};

export const DataPanel: React.FC<{
  view: View;
  downloadAssetHandler: (asset: Asset) => void;
  editedAssetHandler: (asset: Asset) => void;
  moveCameraHandler: (asset: Asset, vectorType: string) => void;
}> = ({ view, downloadAssetHandler, editedAssetHandler, moveCameraHandler }) => {
  const { assets } = view;

  const [editAssetStatus, setEditAssetStatus] = useState<{
    assetId: number | null;
    activeActionPanelName: string;
    // isEdited: boolean;
  }>(INITIAL_EDIT_ASSET_STATUS);

  return (
    <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1}>
      {assets.length && (
        <>
          {assets
            .slice()
            .reverse()
            .map((asset) => {
              const { asset_id, process_status } = asset;
              if (process_status === 'COMPLETE') {
                return (
                  <DataPanelItem
                    key={asset_id}
                    asset={asset}
                    editAssetStatus={editAssetStatus}
                    downloadAssetHandler={() => {
                      setEditAssetStatus(INITIAL_EDIT_ASSET_STATUS);
                      downloadAssetHandler(asset);
                    }}
                    editedAssetHandler={editedAssetHandler}
                    moveCameraHandler={(vectorType) => {
                      setEditAssetStatus(INITIAL_EDIT_ASSET_STATUS);
                      moveCameraHandler(asset, vectorType);
                    }}
                    changeEditAssetStatusHandler={(nextAssetId, actionPanelName) => {
                      setEditAssetStatus({ assetId: nextAssetId, activeActionPanelName: actionPanelName });
                    }}
                  />
                );
              }
              return null;
            })}
        </>
      )}
    </VStack>
  );
};
