import { Button, Container, Flex, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import BackgroundImage from '../../assets/imgs/landing-page.png';

import { CONTAINER_MAX_WIDTH } from '../../config/styles';

import Navbar from '../../components/Navbar';

const HomePage: React.FC = () => (
  <VStack h="100%">
    <Navbar isInverted />

    <Container flex="1" maxW={CONTAINER_MAX_WIDTH}>
      <Flex pos="relative" h="100%">
        <Image src={BackgroundImage} pos="absolute" right="0" top="5%" w="80%" />
        <VStack flex="1" pos="relative" align="left" color="secondary.600">
          <VStack w={{ sm: '50%', xl: '55%' }} flex="1" pt="10%" align="left">
            <Heading as="h2" size="3xl">
              3Dデータの可視化
              <br />
              共有を簡単に
            </Heading>
            <Text w={{ sm: '100%', xl: '60%' }} py="8">
              LinkedViewer（リンクトビューワー）は、3Dデータを仮想の地球上で簡単に可視化・共有できるサービスです。
              <Text ml="6">
                <ul>
                  <li>PCスペックが低くても、GB級のデータをサクサク閲覧可能</li>
                  <li>データの描画位置やサイズを自分で修正可能</li>
                </ul>
              </Text>
            </Text>
            <HStack>
              <Button id="start-button" as={RouterLink} to="/dashboard" colorScheme="primary" mr="6">
                はじめる
              </Button>
              {/* TODO: Unlock this feature in the next release */}
              {/* <Button variant="outline" borderColor="gray.700" leftIcon={<PlayIcon />}>
                動画で概要を知る
              </Button> */}
            </HStack>
          </VStack>
          <Text fontSize="xs" py="12">
            Copyright 2022
          </Text>
        </VStack>
      </Flex>
    </Container>
  </VStack>
);

export default HomePage;
