// react modules
import { Box, Center, Divider, Flex, VStack } from '@chakra-ui/react';
import { Viewer } from 'cesium';
// third party modules
import Entity from 'cesium/Source/DataSources/Entity';
import { useEffect, useState } from 'react';
// assets, config
import { Asset, Boring, View, ViewLayerPoints, ViewSettings } from '../../../config/interfaces/views';
import { INFO_PANEL_WIDTH } from '../../../config/styles';
// services
import { AddBoring } from '../../../services/Cesium/Viewer';
import { BoringPanel } from '../../boring/InfoPanels/boringPanel/Panel';
import { AreaPanel } from '../../view/InfoPanels/areaPanel/Panel';
import { CommentPanel } from '../../view/InfoPanels/commentPanel/Panel';
import { DistancePanel } from '../../view/InfoPanels/distancePanel/Panel';
import { GlobeTranslucencyPanel } from '../../view/InfoPanels/terrainPanel/Panel';
// components
import { DataPanel } from './dataPanel/Panel';
import { GISPanel } from './GISPanel/Panel';
import { CollapsePanel } from './_components/CollapsePanel';

export const InfoPanels: React.FC<{
  view: View;
  cesiumViewerElement: Viewer;
  downloadAssetHandler: (asset: Asset) => void;
  editedAssetHandler: (asset: Asset) => void;
  moveCameraHandler: (asset: Asset, vectorType: string) => void;
  saveViewSettingsHandler: (newViewSettings: ViewSettings) => void;
  editGlobeTranslucencyHandler: (alpha: number) => void;
  // toolbar
  distanceLayersPoints: ViewLayerPoints[];
  areaLayersPoints: ViewLayerPoints[];
  commentLayersPoints: ViewLayerPoints[];
  focusedDistanceLayerIndex: number;
  focusedAreaLayerIndex: number;
  focusedCommentLayerIndex: number;
  selectedCommentLayerIndex: number;
  isCommentLayerLoading: boolean;
  borings: Boring[] | undefined;
  isSharedView: boolean;
  updateDistanceLayerVisibility: (index: number, visibility: boolean) => void;
  deleteDistanceLayer: (index: number) => void;
  updateAreaLayerVisibility: (index: number, visibility: boolean) => void;
  deleteAreaLayer: (index: number) => void;
  updateCommentLayerVisibility: (index: number, visibility: boolean) => void;
  setFocusedDistanceLayerIndex: (index: number) => void;
  setFocusedAreaLayerIndex: (index: number) => void;
  setFocusedCommentLayerIndex: (index: number) => void;
  setSelectedCommentLayerIndex: (index: number) => void;
  updateBoringLayerVisibilityHandler: (index: number, visibility: boolean) => void;
}> = ({
  view,
  cesiumViewerElement,
  downloadAssetHandler,
  editedAssetHandler,
  moveCameraHandler,
  saveViewSettingsHandler,
  editGlobeTranslucencyHandler,
  // toolbar
  distanceLayersPoints,
  areaLayersPoints,
  commentLayersPoints,
  focusedDistanceLayerIndex,
  focusedAreaLayerIndex,
  focusedCommentLayerIndex,
  selectedCommentLayerIndex,
  isCommentLayerLoading,
  borings,
  isSharedView,
  updateDistanceLayerVisibility,
  deleteDistanceLayer,
  updateAreaLayerVisibility,
  deleteAreaLayer,
  updateBoringLayerVisibilityHandler,
  updateCommentLayerVisibility,
  setFocusedDistanceLayerIndex,
  setFocusedAreaLayerIndex,
  setFocusedCommentLayerIndex,
  setSelectedCommentLayerIndex,
}) => {
  const [boringEntities, setBoringEntities] = useState<Entity[]>([]);

  useEffect(() => {
    if (boringEntities?.length) {
      boringEntities.forEach((boringPoint) => cesiumViewerElement.entities.remove(boringPoint));
    }
    if (!borings?.length) return;
    const newEntities = borings.map((boring) => AddBoring(cesiumViewerElement, boring));
    setBoringEntities(newEntities);
    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borings]);

  return (
    <VStack
      color="secondary.400"
      fontSize="xs"
      position="absolute"
      right={0}
      spacing={1.5}
      top={10}
      paddingTop={2}
      paddingRight={2}
      w={INFO_PANEL_WIDTH}
      maxH="calc(100% - 100px)"
      overflowY="auto"
    >
      <Flex justify="flex-end" ml="auto!important" w="100%">
        <Box backgroundColor="gray.800" borderRadius="md" w="100%">
          <CollapsePanel title="地表透過度">
            <GlobeTranslucencyPanel editGlobeTranslucencyHandler={editGlobeTranslucencyHandler} />
          </CollapsePanel>
          <CollapsePanel title="ファイル">
            <DataPanel
              view={view}
              downloadAssetHandler={downloadAssetHandler}
              editedAssetHandler={editedAssetHandler}
              moveCameraHandler={moveCameraHandler}
            />
          </CollapsePanel>
          {!!borings?.length && (
            <>
              <Center h={2} w="100%">
                <Divider variant="layer" />
              </Center>
              <CollapsePanel title="ボーリング">
                <BoringPanel
                  view={view}
                  isEditable={false}
                  openAddBoringModalHandler={() => null}
                  switchCesiumClickEventHandler={() => null}
                  updateBoringLayerVisibilityHandler={updateBoringLayerVisibilityHandler}
                  deleteLayer={() => null}
                  borings={borings}
                  isSharedView={isSharedView}
                />
              </CollapsePanel>
            </>
          )}
          <Center h={2} w="100%">
            <Divider variant="layer" />
          </Center>
          <CollapsePanel title="建物モデル">
            <GISPanel view={view} saveViewSettingsHandler={saveViewSettingsHandler} />
          </CollapsePanel>
          {!!distanceLayersPoints.length && (
            <>
              <Center h={2} w="100%">
                <Divider variant="layer" />
              </Center>
              <CollapsePanel title="距離">
                <DistancePanel
                  distanceLayersPoints={distanceLayersPoints}
                  updateVisibility={updateDistanceLayerVisibility}
                  deleteLayer={deleteDistanceLayer}
                  focusedLayerIndex={focusedDistanceLayerIndex}
                  setFocusedLayerIndex={setFocusedDistanceLayerIndex}
                />
              </CollapsePanel>
            </>
          )}
          {!!areaLayersPoints.length && (
            <>
              <Center h={2} w="100%">
                <Divider variant="layer" />
              </Center>
              <CollapsePanel title="面積">
                <AreaPanel
                  areaLayersPoints={areaLayersPoints}
                  updateVisibility={updateAreaLayerVisibility}
                  deleteLayer={deleteAreaLayer}
                  focusedLayerIndex={focusedAreaLayerIndex}
                  setFocusedLayerIndex={setFocusedAreaLayerIndex}
                />
              </CollapsePanel>
            </>
          )}
          {!!commentLayersPoints.length && (
            <>
              <Center h={2} w="100%">
                <Divider variant="layer" />
              </Center>
              <CollapsePanel title="コメント">
                <CommentPanel
                  commentLayersPoints={commentLayersPoints}
                  updateVisibility={updateCommentLayerVisibility}
                  deleteLayer={() => null}
                  focusedLayerIndex={focusedCommentLayerIndex}
                  setFocusedLayerIndex={setFocusedCommentLayerIndex}
                  selectedLayerIndex={selectedCommentLayerIndex}
                  setSelectedLayerIndex={setSelectedCommentLayerIndex}
                  loading={isCommentLayerLoading}
                />
              </CollapsePanel>
            </>
          )}
        </Box>
      </Flex>
    </VStack>
  );
};
