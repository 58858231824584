import {
  Button,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
} from '@chakra-ui/react';
import { Asset, ViewOutline } from '../../config/interfaces/views';

interface EditShareFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  view: ViewOutline;
  onToggleShareDownload: (asset: Asset) => void;
}

export function EditShareFileModal({
  isOpen,
  onClose,
  view,
  onToggleShareDownload: onToggleDownload,
}: EditShareFileModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>共有ページでのファイルダウンロード可否</ModalHeader>
        <ModalBody>
          {(!view.assets || view.assets.length === 0) && 'ファイルが追加されていません'}
          <List spacing={1}>
            {view.assets!.map((asset) => (
              <ListItem key={asset.asset_id}>
                <Switch
                  isChecked={!asset.asset_settings.disallows_share_download}
                  onChange={() => onToggleDownload(asset)}
                />{' '}
                {asset.asset_settings.asset_name}
              </ListItem>
            ))}
          </List>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>閉じる</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
