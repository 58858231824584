import { useEffect } from 'react';

// ref https://qiita.com/FumioNonaka/items/587c3ed8545d820f330c
// eslint-disable-next-line @typescript-eslint/ban-types
export const useInterval = (callback: Function, delay?: number | null): void => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (delay !== null) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const interval = setInterval(() => callback(), delay || 0);
      return () => clearInterval(interval);
    }
  }, [callback, delay]);
};
