import { HStack, Text } from '@chakra-ui/react';
import { InfoOutlineIcon } from '../assets/icons/Index';

export const AttentionBox: React.FC<{
  backgroundColor?: string;
  color?: string;
}> = ({ children, backgroundColor = 'green.500', color = 'white' }) => (
  <HStack
    w="330px"
    position="absolute"
    top={1}
    left="50%"
    transform="translateX(-50%)"
    pt={2}
    pr={4}
    pb={2}
    pl={4}
    backgroundColor={backgroundColor}
    color={color}
    fontSize="xs"
    borderRadius="6px"
    alignItems="center"
  >
    <InfoOutlineIcon />
    <Text>{children}</Text>
  </HStack>
);
