// react modules
import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Switch,
  useColorModeValue,
  VStack,
  Text,
} from '@chakra-ui/react';
import { ChromePicker, ColorResult } from 'react-color';

// third party modules
import rgbHex from 'rgb-hex';

// assets, config
import { CloseIcon } from '../../../../../assets/icons/Index';
import { Style } from '../../../../../config/interfaces/views';
import { ACTION_PANEL_WIDTH, LAYER_ACTION_PANEL_STYLES } from '../../../../../config/styles';

// services
import { _ } from '../../../../../services/Util';

interface CustomColorResult extends ColorResult {
  hex: string;
}

export const AssetStylePanel: React.FC<{
  assetStyle: Style;
  isEditable: boolean;
  closeHandler: () => void;
  editedStyleHandler: (assetStyle: Style) => void;
  saveStyleHandler: () => void;
}> = ({ assetStyle, isEditable, closeHandler, editedStyleHandler, saveStyleHandler }) => {
  // カラーピッカー表示管理変数
  const [isShowPicker, setIsShowPicker] = useState(false);

  return (
    <Box w={ACTION_PANEL_WIDTH} pos="absolute" right={0} zIndex={999} textAlign="center" {...LAYER_ACTION_PANEL_STYLES}>
      {/* 描画表現設定 */}

      <Box textAlign="right">
        <Icon
          as={CloseIcon}
          cursor="pointer"
          onClick={() => {
            if (isEditable) closeHandler();
          }}
        />
      </Box>
      <VStack spacing={4} alignItems="left">
        <Box>
          <Heading as="h4" size="xs">
            描画表現設定
          </Heading>
        </Box>
        <VStack overflowY="auto" maxHeight="300px">
          <VStack w="100%" justifyContent="flex-start" mb={4}>
            <FormControl id="point_color">
              <HStack>
                <FormLabel fontSize="xs" my="auto">
                  <Text fontWeight="bold">色を上書きする</Text>
                </FormLabel>
                <Switch
                  id="point_color-switch"
                  isChecked={assetStyle.is_point_color_override}
                  onChange={() => {
                    const newAssetStyle = _.cloneDeep(assetStyle);
                    newAssetStyle.is_point_color_override = !assetStyle.is_point_color_override;
                    editedStyleHandler(newAssetStyle);
                  }}
                />
              </HStack>
            </FormControl>
            <Box mr="auto!important">
              <Box
                style={{
                  border: '1px solid #707070',
                  backgroundColor: '#E2E8F0',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsShowPicker(!isShowPicker);
                }}
              >
                <Box my={1} mx={1} py={2} px={4} backgroundColor={assetStyle.point_color}>
                  {' '}
                </Box>
              </Box>
            </Box>
            {isShowPicker ? (
              <ChromePicker
                color={`${assetStyle.point_color}${rgbHex(0, 0, 0, assetStyle.point_alpha).substring(6, 8)}`}
                onChange={(val: CustomColorResult) => {
                  const newAssetStyle = _.cloneDeep(assetStyle);
                  newAssetStyle.point_color = val.hex;
                  newAssetStyle.point_alpha = val.rgb.a!;
                  editedStyleHandler(newAssetStyle);
                }}
              />
            ) : null}
          </VStack>

          <FormControl id="scale">
            <FormLabel fontSize="xs">
              <Text fontWeight="bold">モデルの大きさ</Text>
            </FormLabel>
            <HStack>
              <Slider
                mr={2}
                aria-label="scale-slider"
                defaultValue={assetStyle.scale}
                value={assetStyle.scale}
                focusThumbOnChange={false}
                min={0.1}
                max={10}
                step={0.1}
                onChange={(val) => {
                  const newAssetStyle = _.cloneDeep(assetStyle);
                  newAssetStyle.scale = Number.parseFloat(val.toFixed(1));
                  editedStyleHandler(newAssetStyle);
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb borderColor="gray.500" />
              </Slider>

              <Input
                w="88px"
                type="number"
                textAlign="right"
                size="sm"
                value={assetStyle.scale}
                step={0.1}
                backgroundColor="gray.200"
                color="gray.800"
                onChange={(e) => {
                  const newAssetStyle = _.cloneDeep(assetStyle);

                  if (e.target.value) {
                    const parsedValue = parseFloat(e.target.value);
                    if (parsedValue < 0.1) {
                      newAssetStyle.scale = 0.1;
                    } else if (parsedValue > 10) {
                      newAssetStyle.scale = 10;
                    } else {
                      newAssetStyle.scale = parsedValue;
                    }
                  } else {
                    newAssetStyle.scale = 0.1;
                  }

                  editedStyleHandler(newAssetStyle);
                }}
              />
            </HStack>
          </FormControl>

          <FormControl id="point_size">
            <FormLabel fontSize="xs">
              <Text fontWeight="bold">点のサイズ（点群のみ）</Text>
            </FormLabel>
            <HStack>
              <Slider
                mr={2}
                aria-label="point_size-slider"
                defaultValue={assetStyle.point_size}
                value={assetStyle.point_size}
                focusThumbOnChange={false}
                min={0.1}
                max={10}
                step={0.1}
                onChange={(val) => {
                  const newAssetStyle = _.cloneDeep(assetStyle);
                  newAssetStyle.point_size = Number.parseFloat(val.toFixed(1));
                  editedStyleHandler(newAssetStyle);
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb borderColor="gray.500" />
              </Slider>

              <Input
                w="88px"
                type="number"
                textAlign="right"
                size="sm"
                value={assetStyle.point_size}
                step={0.1}
                backgroundColor="gray.200"
                color="gray.800"
                onChange={(e) => {
                  const newAssetStyle = _.cloneDeep(assetStyle);

                  if (e.target.value) {
                    const parsedValue = parseFloat(e.target.value);
                    if (parsedValue < 0.1) {
                      newAssetStyle.point_size = 0.1;
                    } else if (parsedValue > 10) {
                      newAssetStyle.point_size = 10;
                    } else {
                      newAssetStyle.point_size = parsedValue;
                    }
                  } else {
                    newAssetStyle.point_size = 0.1;
                  }

                  editedStyleHandler(newAssetStyle);
                }}
              />
            </HStack>
          </FormControl>
        </VStack>
        <Box>
          <Button
            w="100%"
            color="white"
            rounded="md"
            _hover={{ bg: useColorModeValue('blue.500', 'gray.500') }}
            bg={useColorModeValue('blue.500', 'gray.500')}
            onClick={() => saveStyleHandler()}
            isLoading={!isEditable}
          >
            保存
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};
