import axios, { AxiosRequestConfig } from 'axios';

import Environments from '../../config/environments';
import { Asset, AssetSettings, ProcessStatus, View, ViewCommentReply } from '../../config/interfaces/views';

const API_GATEWAY_URL = Environments.REACT_APP_API_GATEWAY_URL;

/**
 * add asset
 * @param access_token
 * @param view_id
 * @param file_name file_name with the date prefix
 * @param asset_name origin file_name
 * @returns Asset object
 */
export const AddAsset = async (
  access_token: string,
  view_id: string,
  file_name: string,
  asset_name: string
): Promise<Asset> => {
  console.log('add asset');
  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const addAssetRes = await axios.post<Asset>(
    `${API_GATEWAY_URL}/views/${view_id}/assets`,
    { file_name, asset_name },
    options
  );

  return addAssetRes.data;
};

/**
 * delete asset
 * @param access_token
 * @param view_id
 * @param asset_id
 */
export const DeleteAsset = async (access_token: string, view_id: string, asset_id: number): Promise<void> => {
  console.log(`delete asset, asset id is ${asset_id}`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  await axios.delete<View>(`${API_GATEWAY_URL}/views/${view_id}/assets/${asset_id}`, options);
};

/**
 * upload file for asset
 * @param upload_url signed url from GetSignedUrlForUploadAsset
 * @param upload_file File object
 * @param content_type
 * @param setLoadingText react hooks SetStateAction
 */
export const UploadAsset = async (
  upload_url: string,
  upload_file: File,
  content_type: string,
  setLoadingText?: React.Dispatch<React.SetStateAction<string>> | null
): Promise<void> => {
  console.log(`upload asset file`);

  const options: AxiosRequestConfig = {
    responseType: 'json',
    headers: { 'Content-Type': content_type },
    // for display upload progress percentage
    onUploadProgress(progressEvent: ProgressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (setLoadingText) setLoadingText(`アップロード中...${percentCompleted}%`);
    },
  };

  await axios.put(upload_url, upload_file, options);
};

/**
 * save asset settings
 * @param access_token
 * @param view_id
 * @param asset_id
 * @param asset_settings AssetSettings
 */
export const SaveAssetSettings = async (
  access_token: string,
  view_id: string,
  asset_id: number,
  asset_settings: AssetSettings
): Promise<void> => {
  console.log(`save asset settings, asset id is ${asset_id}`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  if (!asset_settings.position) {
    /* eslint-disable no-param-reassign */
    asset_settings.position = { height: 0, latitude: 0, longitude: 0 };
  }

  await axios.put(`${API_GATEWAY_URL}/views/${view_id}/assets/${asset_id}/asset-settings`, asset_settings, options);
};

/**
 * save asset tiling process status
 * @param access_token
 * @param view_id
 * @param asset_id
 * @param process_status ProcessStatus
 */
export const SaveProcessStatus = async (
  access_token: string,
  view_id: string,
  asset_id: number,
  process_status: ProcessStatus
): Promise<void> => {
  console.log(`save process status`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  await axios.put(`${API_GATEWAY_URL}/views/${view_id}/assets/${asset_id}/process-status`, { process_status }, options);
};

/**
 * check in progress tiling process status at assets
 * if include "AWAITING_FILES" or "NOT_STARTED" or "IN_PROGRESS" in assets, return the asset id
 * @param assets Asset[]
 * @returns object {tilingAsset: Asset | null}
 */
export const CheckExistsProcessingStatusAsset = (assets: Asset[]): { tilingAsset: Asset | null } => {
  let tilingAsset = null;

  assets.forEach((asset) => {
    if (
      asset.process_status === 'AWAITING_FILES' ||
      asset.process_status === 'NOT_STARTED' ||
      asset.process_status === 'IN_PROGRESS'
    ) {
      tilingAsset = asset;
    }
  });

  return { tilingAsset };
};
