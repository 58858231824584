import { Box, Flex, HStack, IconButton, Spacer, Text, Tooltip } from '@chakra-ui/react';
import {
  DisableShareDownloadIcon,
  EnableShareDownloadIcon,
  HideIcon,
  MoreHorizontalIcon,
  PinGround,
  ShowIcon,
} from '../../../../assets/icons/Index';
import { Asset, Position } from '../../../../config/interfaces/views';
import { LAYER_ITEM_STYLES } from '../../../../config/styles';
import { _ } from '../../../../services/Util';
import { AssetPositionOrientationPanel } from './actionPanels/AssetPositionOrientationPanel';
import { AssetStylePanel } from './actionPanels/AssetStylePanel';
import { ImageryStylePanel } from './actionPanels/ImageryStylePanel';
import { ActionListPanel } from './actionPanels/ListPanel';

export const DataPanelItem: React.FC<{
  asset: Asset;
  editAssetStatus: { assetId: number | null; activeActionPanelName: string; isEdited: boolean };
  isEditable: boolean;
  openDeleteAssetConfirmModalHandler: () => void;
  downloadAssetHandler: () => void;
  editedAssetHandler: (asset: Asset) => void;
  moveCameraHandler: (vectorType: string) => void;
  saveAssetHandler: (asset: Asset) => void;
  saveAssetVisibleHandler: (asset: Asset) => void;
  changeEditAssetStatusHandler: (nextAssetId: number | null, actionPanelName: string) => void;
  changePinPosition: (asset: Asset) => void;
}> = ({
  asset,
  editAssetStatus,
  isEditable,
  openDeleteAssetConfirmModalHandler,
  downloadAssetHandler,
  editedAssetHandler,
  moveCameraHandler,
  saveAssetHandler,
  saveAssetVisibleHandler,
  changeEditAssetStatusHandler,
  changePinPosition,
}) => {
  const { asset_settings, asset_type } = asset;
  const { asset_name, style, disallows_share_download, position } = asset_settings;
  const { visible } = style;

  const SHOW_ASSET_NAME_LENGTH = 20;

  return (
    <Box w="100%" pos="relative">
      <Flex w="100%" alignItems="center" {...LAYER_ITEM_STYLES}>
        <Tooltip label={asset_name.length > SHOW_ASSET_NAME_LENGTH ? asset_name : ''}>
          <Text pl={2}>{`${
            asset_name.length > SHOW_ASSET_NAME_LENGTH
              ? `${asset_name.substring(0, SHOW_ASSET_NAME_LENGTH - 1)}...`
              : asset_name
          }`}</Text>
        </Tooltip>
        <Spacer />
        <HStack spacing={0}>
          {/* 24px */}

          {/* clamp pin marker to ground (image only) */}
          {asset.asset_type === 'IMAGE' && (
            <Tooltip label={position?.clamp_to_ground ? 'ピンを設定した高さに表示する' : 'ピンを地表上に固定する'}>
              <IconButton
                aria-label={position?.clamp_to_ground ? 'ピンを設定した高さに表示する' : 'ピンを地表上に固定する'}
                fontSize="sm"
                icon={position?.clamp_to_ground ? <PinGround /> : <PinGround opacity={0.25} />}
                onClick={() => {
                  if (isEditable) {
                    if (editAssetStatus.isEdited) {
                      changeEditAssetStatusHandler(null, '');
                    } else {
                      const newAsset = _.clone(asset);
                      newAsset.asset_settings.position!.clamp_to_ground = !position?.clamp_to_ground;
                      saveAssetVisibleHandler(newAsset);
                    }
                  }
                }}
                size="xs"
                variant="toolbarIcon"
              >
                a
              </IconButton>
            </Tooltip>
          )}

          {/* visible button */}
          <IconButton
            aria-label={visible ? 'show' : 'hide'}
            fontSize="sm"
            icon={visible ? <ShowIcon /> : <HideIcon />}
            onClick={() => {
              if (isEditable) {
                if (editAssetStatus.isEdited) {
                  changeEditAssetStatusHandler(null, '');
                } else {
                  const newAsset = _.clone(asset);
                  newAsset.asset_settings.style.visible = !visible;
                  saveAssetVisibleHandler(newAsset);
                }
              }
            }}
            size="xs"
            variant="toolbarIcon"
          />

          {/* enable/disable download on share page */}
          <Tooltip label={disallows_share_download ? '共有ダウンロード：不可' : '共有ダウンロード：許可'}>
            <IconButton
              aria-label={disallows_share_download ? 'DisableShareDownload' : 'EnableShareDonwload'}
              fontSize="sm"
              icon={disallows_share_download ? <DisableShareDownloadIcon /> : <EnableShareDownloadIcon />}
              onClick={() => {
                if (isEditable) {
                  if (editAssetStatus.isEdited) {
                    changeEditAssetStatusHandler(null, '');
                  } else {
                    const newAsset = _.clone(asset);
                    newAsset.asset_settings.disallows_share_download = !disallows_share_download;
                    saveAssetVisibleHandler(newAsset);
                  }
                }
              }}
              size="xs"
              variant="toolbarIcon"
            />
          </Tooltip>

          {/* more actions button */}
          <IconButton
            aria-label="actionList"
            fontSize="sm"
            icon={<MoreHorizontalIcon />}
            onClick={() => {
              if (isEditable) {
                if (editAssetStatus && editAssetStatus.assetId === asset.asset_id) {
                  changeEditAssetStatusHandler(null, '');
                } else {
                  changeEditAssetStatusHandler(asset.asset_id, 'actionList');
                }
              }
            }}
            size="xs"
            variant="toolbarIcon"
          />
        </HStack>
      </Flex>
      {/* more actions panel */}
      {editAssetStatus.assetId === asset.asset_id && editAssetStatus.activeActionPanelName === 'actionList' && (
        <ActionListPanel
          assetType={asset_type!}
          openDeleteAssetConfirmModalHandler={() => {
            openDeleteAssetConfirmModalHandler();
          }}
          downloadAssetHandler={() => {
            downloadAssetHandler();
          }}
          moveCameraHandler={moveCameraHandler}
          changeEditAssetStatusHandler={(actionPanelName) =>
            changeEditAssetStatusHandler(asset.asset_id, actionPanelName)
          }
          changePinPosition={() => changePinPosition(asset)}
        />
      )}
      {/* asset position orientation panel */}
      {editAssetStatus.assetId === asset.asset_id && editAssetStatus.activeActionPanelName === 'positionOrientation' && (
        <Box pr={1} pl={1}>
          <AssetPositionOrientationPanel
            assetPosition={asset.asset_settings.position || ({ longitude: 0, latitude: 0, height: 0 } as Position)}
            assetOrientation={asset.asset_settings.orientation}
            isEditable={isEditable}
            closeHandler={() => changeEditAssetStatusHandler(null, '')}
            editedPositionOrientationHandler={(newAssetPosition, newAssetOrientation) => {
              const newAsset = _.clone(asset);
              newAsset.asset_settings.position = newAssetPosition;
              newAsset.asset_settings.orientation = newAssetOrientation;
              editedAssetHandler(newAsset);
            }}
            savePositionOrientationHandler={() => saveAssetHandler(asset)}
          />
        </Box>
      )}
      {/* asset style panel */}
      {editAssetStatus.assetId === asset.asset_id && editAssetStatus.activeActionPanelName === 'style' && (
        <Box pr={1} pl={1}>
          <AssetStylePanel
            assetStyle={asset.asset_settings.style}
            isEditable={isEditable}
            closeHandler={() => changeEditAssetStatusHandler(null, '')}
            editedStyleHandler={(newAssetStyle) => {
              const newAsset = _.clone(asset);
              newAsset.asset_settings.style = newAssetStyle;
              editedAssetHandler(newAsset);
            }}
            saveStyleHandler={() => saveAssetHandler(asset)}
          />
        </Box>
      )}
      {/* imagery asset style panel */}
      {editAssetStatus.assetId === asset.asset_id && editAssetStatus.activeActionPanelName === 'imageryStyle' && (
        <Box pr={1} pl={1}>
          <ImageryStylePanel
            assetStyle={asset.asset_settings.style}
            isEditable={isEditable}
            closeHandler={() => changeEditAssetStatusHandler(null, '')}
            editedStyleHandler={(newAssetStyle) => {
              const newAsset = _.clone(asset);
              newAsset.asset_settings.style = newAssetStyle;
              editedAssetHandler(newAsset);
            }}
            saveStyleHandler={() => saveAssetHandler(asset)}
          />
        </Box>
      )}
    </Box>
  );
};
