import { Box, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react';
import { LAYER_ITEM_STYLES } from '../../../../config/styles';

export const GlobeTranslucencyPanel: React.FC<{
  editGlobeTranslucencyHandler: (alpha: number) => void;
}> = ({ editGlobeTranslucencyHandler }) => (
  <Box w="100%" pos="relative" {...LAYER_ITEM_STYLES}>
    <Flex w="100%" alignItems="center">
      <Slider
        mr={5}
        ml={5}
        aria-label="scale-slider"
        defaultValue={100}
        // value={assetStyle.point_alpha * 100}
        focusThumbOnChange={false}
        min={0}
        max={100}
        step={1}
        onChange={(val) => {
          editGlobeTranslucencyHandler(val * 0.01);
        }}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb borderColor="gray.500" />
      </Slider>
    </Flex>
  </Box>
);
