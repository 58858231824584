import { ToolbarDistanceIcon } from '../../../assets/icons/Index';
import { VIEWER_TOOLBAR_SHORTCUT } from '../../../config/constants';
import ToolbarButton from './_components/ToolbarButton';

const DistanceTool: React.FC<{ isActive: boolean; onClick: () => void }> = ({ isActive, onClick }) => (
  <ToolbarButton
    icon={<ToolbarDistanceIcon />}
    label={`距離計算(${VIEWER_TOOLBAR_SHORTCUT.DISTANCE_TOOL[0]})`}
    isActive={isActive}
    onClick={onClick}
  />
);

export default DistanceTool;
