import * as chakraUiIcons from '@chakra-ui/icons';

import { IconType as it } from 'react-icons';
import { AiOutlineFolderOpen } from 'react-icons/ai';
import {
  BiCaretRightCircle,
  BiCheck,
  BiComment,
  BiCopy,
  BiDotsHorizontalRounded,
  BiHide,
  BiLink,
  BiMoveHorizontal,
  BiPencil,
  BiPlus,
  BiPlusCircle,
  BiShapeSquare,
  BiShow,
  BiTrash,
  BiUnlink,
  BiX,
} from 'react-icons/bi';
import { BsCursorFill } from 'react-icons/bs';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FiHelpCircle, FiHome, FiMenu, FiMoreHorizontal } from 'react-icons/fi';
import { MdPinDrop, MdOutlineFileDownload, MdOutlineFileDownloadOff } from 'react-icons/md';

import { ReactComponent as LogoInverted } from './src/logo-inverted.svg';
import { ReactComponent as Logo } from './src/logo.svg';

import { BsCompass } from 'react-icons/bs';
import { ReactComponent as SharedLinkPassword } from './src/locked-link.svg';

export type IconType = it; // react-icon タイプ定義

export const {
  ArrowBackIcon, // ←
  QuestionOutlineIcon, // outlined ?
  InfoOutlineIcon, // outline info
  HamburgerIcon, // ハンバーガー
  CloseIcon, // 閉じる
  ChevronDownIcon, // v矢印
  ChevronRightIcon, // >矢印
  LinkIcon, // リンク
  WarningIcon,
  CheckIcon: SuccessIcon,
} = chakraUiIcons;

export const AddIcon = BiPlus;
export const EditIcon = BiPencil;
export const CopyIcon = BiCopy;
export const FolderIcon = AiOutlineFolderOpen;
export const HomeIcon = FiHome; // ホーム
export const MenuIcon = FiMenu; // メニュー
export const PlayIcon = BiCaretRightCircle;
export const HelpCircleIcon = FiHelpCircle; // 丸形ヘルプ
export const BigChevronUpIcon = FaChevronUp; // 上向き矢印
export const BigChevronDownIcon = FaChevronDown; // 下向き矢印
export const MoreHorizontalIcon = FiMoreHorizontal; // ３点リーダー
export const PlusCircleIcon = BiPlusCircle; // 円形＋
export const ShowIcon = BiShow; // 表示
export const HideIcon = BiHide; // 非表示
export const EnableShareDownloadIcon = MdOutlineFileDownload; // enable shared download
export const DisableShareDownloadIcon = MdOutlineFileDownloadOff; // disable shared download
export const DeleteIcon = BiTrash;
export const CancelIcon = BiX;
export const ConfirmIcon = BiCheck;
export const PinGround = MdPinDrop;

export const HDotsIcon = BiDotsHorizontalRounded;
export const SharedLinkIcon = BiLink;
export const SharedLinkPasswordIcon = SharedLinkPassword;
export const UnsharedLinkIcon = BiUnlink;
export const LogoDark = Logo;
export const LogoLight = LogoInverted;
export const ToolbarDistanceIcon = BiMoveHorizontal;
export const ToolbarAreaIcon = BiShapeSquare;
export const ToolbarMoveIcon = BsCursorFill;
export const ToolbarCommentIcon = BiComment;
export const ToolbarCompassIcon = BsCompass;
