// react modules
import { useImperativeHandle, useState, useRef } from 'react';
import { forwardRef } from '@chakra-ui/react';

import { Modal } from '../_components/Modal';

// 削除確認モーダルコンポーネント
const DeleteCommentConfirmModalFunction: React.ForwardRefRenderFunction<
  // 親コンポーネントでの呼び出し用ファンクション群定義
  {
    openModal: (commentId: number) => void;
  },
  // 親コンポーネントから伝搬される変数・ファンクション群定義
  {
    deleteCommentHandler: (commentId: number) => void;
  }
> = ({ deleteCommentHandler }, ref) => {
  // 削除対象のcomment index保持用変数
  const [commentId, setCommentId] = useState<number>(-1);

  // 親コンポーネントから呼び出されるファンクション群の内部処理
  useImperativeHandle(ref, () => ({
    openModal(_deleteCommentId: number) {
      setCommentId(_deleteCommentId);
      modalRef.current?.openModal();
    },
  }));

  const modalRef = useRef<{
    openModal: () => void;
  }>();

  return (
    <>
      <Modal
        ref={modalRef}
        headerTitle="コメントを削除しますか？"
        okText="削除"
        okColorScheme="red"
        okClickHandler={() => deleteCommentHandler(commentId)}
      >
        一度削除してしまうと、元に戻せません。
      </Modal>
    </>
  );
};

export const DeleteCommentConfirmModal = forwardRef(DeleteCommentConfirmModalFunction);
