// react modules
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Flex, HStack, IconButton, Spacer, Square, VStack, Text } from '@chakra-ui/react';

// third party modules

// components
import { DataPanelItem } from './PanelItem';

// services

// assets, config
import { View, Asset } from '../../../../config/interfaces/views';
import { INFO_PANEL_PADDING, LAYER_ITEM_STYLES } from '../../../../config/styles';
import { PlusCircleIcon } from '../../../../assets/icons/Index';

const INITIAL_EDIT_ASSET_STATUS = {
  assetId: null,
  activeActionPanelName: '',
  isEdited: false,
};

export interface EditAssetStatus {
  assetId: number | null;
  activeActionPanelName: string;
  isEdited: boolean;
}

export interface DataPanelForwardRef {
  closeActionPanel: () => void;
  getEditAssetStatus: () => EditAssetStatus | null;
  updateEditAssetStatus: (status: EditAssetStatus) => void;
}

const DataPanelFunction: React.ForwardRefRenderFunction<
  DataPanelForwardRef,
  {
    view: View;
    isEditable: boolean;
    openDeleteAssetConfirmModalHandler: (asset: Asset) => void;
    downloadAssetHandler: (asset: Asset) => void;
    editedAssetHandler: (asset: Asset) => void;
    moveCameraHandler: (asset: Asset, vectorType: string) => void;
    saveAssetHandler: (asset: Asset) => void;
    saveAssetVisibleHandler: (asset: Asset) => void;
    switchCesiumClickEventHandler: (isEnabled: boolean) => void;
    openAddAssetModalHandler: () => void;
    openRevertAssetConfirmModalHandler: () => void;
    changePinPosition: (asset: Asset) => void;
}
> = (
  {
    view,
    isEditable,
    openDeleteAssetConfirmModalHandler,
    downloadAssetHandler,
    editedAssetHandler,
    moveCameraHandler,
    saveAssetHandler,
    saveAssetVisibleHandler,
    switchCesiumClickEventHandler,
    openAddAssetModalHandler,
    openRevertAssetConfirmModalHandler,
    changePinPosition,
},
  ref
) => {
  const { assets } = view;

  const [editAssetStatus, setEditAssetStatus] = useState<EditAssetStatus>(INITIAL_EDIT_ASSET_STATUS);

  // Definition of parent component to use this component
  useImperativeHandle(ref, () => ({
    closeActionPanel() {
      setEditAssetStatus(INITIAL_EDIT_ASSET_STATUS);
      switchCesiumClickEventHandler(false);
    },

    getEditAssetStatus() {
      return editAssetStatus;
    },

    updateEditAssetStatus(status) {
      setEditAssetStatus(status);
    },
  }));

  return (
    <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1}>
      <Box w="100%" pos="relative">
        <Flex w="100%" alignItems="center" {...LAYER_ITEM_STYLES}>
          <Text pl={2}>ファイルを追加</Text>
          <Spacer />
          <HStack spacing={0}>
            {/* 24px */}
            <IconButton
              aria-label="add-file"
              fontSize="sm"
              icon={<PlusCircleIcon />}
              onClick={openAddAssetModalHandler}
              size="xs"
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
            />
            <Square size={6} />
          </HStack>
        </Flex>
      </Box>
      {assets.length && (
        <>
          {assets
            .slice()
            .reverse()
            .map((asset) => {
              const { asset_id, process_status } = asset;
              if (process_status === 'COMPLETE') {
                return (
                  <DataPanelItem
                    key={asset_id}
                    asset={asset}
                    editAssetStatus={editAssetStatus}
                    isEditable={isEditable}
                    changeEditAssetStatusHandler={(nextAssetId, actionPanelName) => {
                      if (editAssetStatus.isEdited) {
                        openRevertAssetConfirmModalHandler();
                      } else {
                        setEditAssetStatus({
                          assetId: nextAssetId,
                          activeActionPanelName: actionPanelName,
                          isEdited: false,
                        });

                        if (actionPanelName === 'positionOrientation') {
                          // call enable click event
                          switchCesiumClickEventHandler(true);
                        } else {
                          switchCesiumClickEventHandler(false);
                        }
                      }
                    }}
                    // asset handler
                    editedAssetHandler={(newAsset) => {
                      setEditAssetStatus({
                        ...editAssetStatus,
                        isEdited: true,
                      });
                      editedAssetHandler(newAsset);
                    }}
                    saveAssetHandler={saveAssetHandler}
                    saveAssetVisibleHandler={saveAssetVisibleHandler}
                    downloadAssetHandler={() => {
                      setEditAssetStatus(INITIAL_EDIT_ASSET_STATUS);
                      downloadAssetHandler(asset);
                    }}
                    openDeleteAssetConfirmModalHandler={() => {
                      setEditAssetStatus(INITIAL_EDIT_ASSET_STATUS);
                      openDeleteAssetConfirmModalHandler(asset);
                    }}
                    // cesium handler
                    moveCameraHandler={(vectorType) => {
                      setEditAssetStatus(INITIAL_EDIT_ASSET_STATUS);
                      moveCameraHandler(asset, vectorType);
                    }}
                    changePinPosition={(newAsset) => {
                      changePinPosition(newAsset);
                      editedAssetHandler(newAsset);
                      setEditAssetStatus({
                        ...editAssetStatus,
                        activeActionPanelName: 'changePinPosition',
                        isEdited: true,
                      });
                    }}
                  />
                );
              }
              return null;
            })}
        </>
      )}
    </VStack>
  );
};

export const DataPanel = forwardRef(DataPanelFunction);
