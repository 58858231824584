/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// react modules
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  forwardRef,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AddBoring } from '../../../../services/View/View';

// third party modules

// assets, config
import { FolderIcon } from '../../../../assets/icons/Index';

// services
import { CapacityStatus } from '../../../../config/interfaces/util';
import { Boring } from '../../../../config/interfaces/views';

// types
interface FormData {
  fileUpload: File[];
  crs: string;
}

// upload available extensions
const extensions = '.csv';

// ファイル追加モーダルコンポーネント
const AddBoringModalFunction: React.ForwardRefRenderFunction<
  // 親コンポーネントでの呼び出し用ファンクション群定義
  {
    openModal: () => void;
  },
  // 親コンポーネントから伝搬される変数・ファンクション群定義
  {
    view_id: string | undefined;
    capacityStatus: CapacityStatus | undefined;
    addBoringHandler: (boringArray: Boring[]) => void;
    authToken: string | null;
    fetchBorings: () => void;
  }
> = ({ fetchBorings, authToken, view_id }, componentRef) => {
  const { user } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [fileName, setFileName] = useState<string>('');

  // file upload control form
  const inputRef = useRef<HTMLInputElement>();
  const { ref: fileRef, ...field } = register('fileUpload', {
    required: 'データを選択してください',
  });

  // add boring process
  const onSubmit = (formData: FormData) => {
    console.log('add boring to cesium');
    if (!user || !user.sub || !authToken) {
      alert('認証情報に不備があります。\nログインし直して再度お試しください。');
    }
    const file = formData.fileUpload[0];
    const reader = new FileReader();
    reader.readAsText(file);
    const boringArray: Boring[] = [];
    reader.onload = async () => {
      const { result } = reader;
      if (result) {
        const lines = result.toString().replace(/ /g, '').split('\n');
        // convert csv to json
        for (let i = 0; i < lines.length; i += 1) {
          const line = lines[i].split(',');
          if (line.length) {
            const boring = {
              start: Number(line[0]),
              end: Number(line[1]),
              color: line[2],
              lon: Number(line[3]),
              lat: Number(line[4]),
              name: line[5],
              show: true,
              index: i,
              radius: Number(line[6]),
              url: line[7] ? line[7] : '',
              file_name: file.name,
              file_id: '',
            };
            // validate boring data
            if (
              boring.start == null ||
              !boring.end ||
              !boring.color ||
              !boring.lon ||
              !boring.lat ||
              !boring.name ||
              !boring.radius
            ) {
              alert('CSVに不備があります。');
              return;
            }
            boringArray.push(boring);
          }
        }
        if (!boringArray.length) {
          return;
        }
        if (authToken !== null && view_id) {
          await AddBoring(authToken, view_id, boringArray);
          fetchBorings();
        }
        setFileName('');
        onClose();
      }
    };
  };

  // the event when change file input
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const targetFile = e.target.files[0];

    setFileName(targetFile.name);
    await field.onChange(e);
  };

  // 親コンポーネントから呼び出されるファンクション群の内部処理
  useImperativeHandle(componentRef, () => ({
    openModal() {
      onOpen();
    },
  }));

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} trapFocus={false} size="lg">
        <ModalOverlay />
        <ModalContent minH="334px">
          <ModalHeader>CSVを読み込み</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormControl id="fileUpload" isInvalid={errors.fileUpload} mt={4}>
                <FormLabel htmlFor="file">読み込むファイル</FormLabel>
                <InputGroup size="md">
                  <Input
                    hidden
                    {...field}
                    ref={(instance: HTMLInputElement) => {
                      fileRef(instance);
                      inputRef.current = instance;
                    }}
                    type="file"
                    accept={extensions}
                    onChange={handleFileChange}
                  />
                  <Input isReadOnly value={fileName} />
                  <InputRightElement w={14}>
                    <IconButton
                      aria-label="Upload file"
                      borderColor="inherit"
                      borderWidth="1px"
                      className="upload-file-button"
                      fontSize="lg"
                      icon={<FolderIcon />}
                      onClick={() => inputRef.current?.click()}
                      w="100%"
                    />
                  </InputRightElement>
                </InputGroup>
                <FormHelperText>.csv</FormHelperText>
                <FormErrorMessage>{errors.fileUpload && errors.fileUpload.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter mt={8}>
              <Button me={3} py={2} minW="100px" onClick={onClose}>
                キャンセル
              </Button>

              <Button colorScheme="primary" minW="100px" type="submit" py={2}>
                追加
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export const AddBoringModal = forwardRef(AddBoringModalFunction);
