// third party modules
import axios, { AxiosRequestConfig } from 'axios';
// assets, config
import { environments } from '../../config/environments';

// services
import { sleep } from '../Util';

const API_GATEWAY_URL = environments.REACT_APP_API_GATEWAY_URL;
const FILE_BUCKET_NAME = environments.REACT_APP_FILE_BUCKET_NAME;

/**
 * start complete CRS for las data
 * @param access_token
 * @param user_id
 * @param file_name
 * @param crs
 * @return executionArn for confirm completion status
 */
export const StartAddCrs = async (
  access_token: string,
  user_id: string,
  file_name: string,
  crs: string
): Promise<{ executionArn: string }> => {
  console.log('start complete crs');

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  // prepare req parameters
  const crsData = {
    input: {
      input_s3_path: `s3://${FILE_BUCKET_NAME}/${user_id}/origin/${file_name}`,
      epsg_code: crs.split(':')[1],
    },
    name: file_name,
  };

  // Execute state machine
  const executeRes = await axios.post<{ executionArn: string }>(`${API_GATEWAY_URL}/crs`, crsData, options);

  return executeRes.data;
};

/**
 * start convert to geojson
 * @param access_token
 * @param user_id
 * @param file_name
 * @param crs
 * @return executionArn for confirm completion status
 */
export const StartConvertToGeoJson = async (
  access_token: string,
  user_id: string,
  file_name: string,
  crs: string
): Promise<{ executionArn: string }> => {
  console.log('start convert to geojson');

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  // prepare req parameters
  const crsData = {
    input: {
      input_s3_path: `s3://${FILE_BUCKET_NAME}/${user_id}/origin/${file_name}`,
      epsg_code: crs.split(':')[1],
    },
    name: file_name,
  };

  // Execute state machine
  const executeRes = await axios.post<{ executionArn: string }>(
    `${API_GATEWAY_URL}/convert-to-geojson`,
    crsData,
    options
  );

  return executeRes.data;
};

/**
 * subscribe completion status, repeat until SUCCEEDED status
 * @param access_token
 * @param executionArn for confirm completion status
 */
export const CheckAddCrsStatus = async (access_token: string, executionArn: string): Promise<void> => {
  // Subscribe state machine
  console.log('subscribe complete crs status');

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const call = async () => {
    const subRes = await axios.post<{ statusCode: number; message: string }>(
      `${API_GATEWAY_URL}/crs/status`,
      { executionArn },
      options
    );
    console.log(subRes.data);
    const { statusCode, message } = subRes.data;
    if (message) {
      if (message === 'running') {
        console.log('RUNNING');
        await sleep(3000);
        await call();
      } else if (message === 'failed') {
        console.log('FAILED');
        console.log('Skip this process');
      } else {
        console.log('UNKNOWN');
        console.log('Skip this process');
      }
    }

    if (statusCode) {
      if (statusCode === 200) {
        console.log('SUCCEEDED');
      } else {
        console.log('Skip this process');
      }
    }
  };
  await call();
};

/**
 * subscribe convert to geojson status, repeat until SUCCEEDED status
 * @param access_token
 * @param executionArn for confirm completion status
 */
export const CheckConvertToGeoJsonStatus = async (access_token: string, executionArn: string): Promise<void> => {
  // Subscribe state machine
  console.log('subscribe convert to geojson status');

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const call = async () => {
    const subRes = await axios.post<{ statusCode: number; message: string }>(
      `${API_GATEWAY_URL}/convert-to-geojson/status`,
      { executionArn },
      options
    );
    console.log(subRes.data);
    const { statusCode, message } = subRes.data;
    if (message) {
      if (message === 'running') {
        console.log('RUNNING');
        await sleep(3000);
        await call();
      } else if (message === 'failed') {
        console.log('FAILED');
        console.log('Skip this process');
      } else {
        console.log('UNKNOWN');
        console.log('Skip this process');
      }
    }

    if (statusCode) {
      if (statusCode === 200) {
        console.log('SUCCEEDED');
      } else {
        alert('ファイル変換に失敗しました。ファイルを確認してください。');
        // TODO: design a specific error and handle it appropriately
        throw Error;
      }
    }
  };
  await call();
};
