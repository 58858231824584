import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Environments from '../../config/environments';

const API_GATEWAY_URL = Environments.REACT_APP_API_GATEWAY_URL;

/**
 * get signed url for upload asset
 * @param access_token
 * @param file_name filename in Asset
 * @param content_type
 * @returns url for upload
 */
export const GetSignedUrlForUploadAsset = async (
  access_token: string,
  file_name: string,
  content_type: string
): Promise<string> => {
  console.log(`get signed url for upload asset`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const preSignUrlResponse: AxiosResponse<{ url: string }> = await axios.post(
    `${API_GATEWAY_URL}/signed-url-upload`,
    { file_name, content_type },
    options
  );

  return preSignUrlResponse.data.url;
};

/**
 * get signed url for download asset for editor view
 * @param access_token
 * @param view_id
 * @param asset_id
 * @param content_type
 * @returns url for download
 */
export const GetPreSignUrlForDownloadAssetForEditorView = async (
  access_token: string,
  view_id: string,
  asset_id: number,
  content_type: string
): Promise<string> => {
  console.log(`get signed url for download asset`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const preSignUrlResponse: AxiosResponse<{ url: string }> = await axios.post(
    `${API_GATEWAY_URL}/views/${view_id}/assets/${asset_id}/signed-url-download`,
    { content_type },
    options
  );

  return preSignUrlResponse.data.url;
};

/**
 * get signed url for download asset for shared view
 * @param password
 * @param view_id
 * @param asset_id
 * @param content_type
 * @returns url for download
 */
export const GetPreSignUrlForDownloadAssetForSharedView = async (
  password: string,
  view_id: string,
  asset_id: number,
  content_type: string
): Promise<string> => {
  console.log(`get signed url for download asset for shared view`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { 'x-view-pw': password } };

  const preSignUrlResponse: AxiosResponse<{ url: string }> = await axios.post(
    `${API_GATEWAY_URL}/shared-views/${view_id}/assets/${asset_id}/signed-url-download`,
    { content_type },
    options
  );

  return preSignUrlResponse.data.url;
};

/**
 * wrapper of get signed url for download asset
 * @param access_token
 * @param view_id
 * @param asset_id
 * @param content_type
 * @param forSharedView
 * @returns url for download
 */
export const GetPreSignUrlForDownloadAsset = async (
  access_token: string,
  view_id: string,
  asset_id: number,
  content_type: string,
  forSharedView: boolean | undefined = false
): Promise<string> => {
  const getPreSignedUrlForDownloadAsset = forSharedView
    ? GetPreSignUrlForDownloadAssetForSharedView
    : GetPreSignUrlForDownloadAssetForEditorView;

  return getPreSignedUrlForDownloadAsset(access_token, view_id, asset_id, content_type);
};
