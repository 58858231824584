// react modules
import { Box, Flex, HStack, IconButton, Spacer, Square, Text } from '@chakra-ui/react';

// third party modules
// components
// services
// assets, config
import { DeleteIcon, HideIcon, ShowIcon } from '../../../../assets/icons/Index';
import { LAYER_ITEM_FOCUSED_BACKGROUND_COLOR, LAYER_ITEM_STYLES } from '../../../../config/styles';

export const CommentPanelItem: React.FC<{
  label: string;
  visible: boolean;
  updateVisibility: (newVisible: boolean) => void;
  deleteLayer: () => void;
  focused: boolean;
  selected: boolean;
  loading: boolean;
  deletable: boolean;
  setFocused: (focused: boolean) => void;
  setSelected: (selected: boolean) => void;
}> = ({
  label,
  visible,
  updateVisibility,
  deleteLayer,
  focused,
  selected,
  setFocused,
  setSelected,
  loading,
  deletable,
}) => (
  <Box
    w="100%"
    pos="relative"
    onMouseEnter={() => {
      if (!loading) setFocused(true);
    }}
    cursor="pointer"
    onClick={() => {
      if (!loading) setSelected(true);
    }}
  >
    <Flex
      w="100%"
      alignItems="center"
      {...LAYER_ITEM_STYLES}
      backgroundColor={focused || selected || loading ? LAYER_ITEM_FOCUSED_BACKGROUND_COLOR : 'transparent'}
    >
      <Text pl={2}>{label}</Text>
      <Spacer />
      <HStack spacing={0}>
        {/* 24px */}
        <IconButton
          aria-label={visible ? 'show' : 'hide'}
          fontSize="sm"
          icon={visible ? <ShowIcon /> : <HideIcon />}
          onClick={(e) => {
            e.stopPropagation();
            updateVisibility(!visible);
          }}
          size="xs"
          variant="toolbarIcon"
          disabled={loading}
        />
        {deletable && (
          <IconButton
            aria-label="delete"
            fontSize="sm"
            icon={<DeleteIcon />}
            onClick={(e) => {
              e.stopPropagation();
              deleteLayer();
            }}
            size="xs"
            variant="toolbarIcon"
            disabled={loading}
          />
        )}
        {!deletable && <Square size={6} />}
      </HStack>
    </Flex>
  </Box>
);
