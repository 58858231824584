// react modules
import { VStack } from '@chakra-ui/react';

// third party modules

// components
import { AreaPanelItem } from './PanelItem';

// services
import { _ } from '../../../../services/Util';
import { GetArea } from '../../../../services/Cesium/Viewer';

// assets, config
import { ViewLayerPoints } from '../../../../config/interfaces/views';
import { INFO_PANEL_PADDING } from '../../../../config/styles';
import { AREA_UNIT } from '../../../../config/constants';

export const AreaPanel: React.FC<{
  areaLayersPoints: ViewLayerPoints[];
  updateVisibility: (index: number, visibility: boolean) => void;
  deleteLayer: (index: number) => void;
  focusedLayerIndex: number;
  setFocusedLayerIndex: (index: number) => void;
}> = ({ areaLayersPoints, updateVisibility, deleteLayer, focusedLayerIndex, setFocusedLayerIndex }) => (
  <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} onMouseLeave={() => setFocusedLayerIndex(-1)}>
    {areaLayersPoints.map((layerPoints, index) => (
      <AreaPanelItem
        key={layerPoints?.points.toString()}
        label={`${GetArea(layerPoints?.points)}${AREA_UNIT}`}
        visible={!layerPoints.invisible}
        deleteLayer={() => deleteLayer(index)}
        focused={index === focusedLayerIndex}
        setFocused={() => {
          setFocusedLayerIndex(index);
        }}
        updateVisibility={(newVisible) => {
          updateVisibility(index, newVisible);
        }}
      />
    ))}
  </VStack>
);
