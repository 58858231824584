// third party modules
import axios, { AxiosRequestConfig } from 'axios';
import bytes from 'bytes';

// assets, config
import { CapacityStatus } from '../../config/interfaces/util';
import { environments } from '../../config/environments';

const API_GATEWAY_URL = environments.REACT_APP_API_GATEWAY_URL;

/**
 * capacity & usageを取得
 * @param access_token アクセストークン
 * @returns capacity, usage
 */
export const GetCapacity = async (access_token: string): Promise<CapacityStatus> => {
  console.log(`get capacity`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const capacityRequestRes = await axios.get<{ capacity: string; usage: string }>(
    `${API_GATEWAY_URL}/capacity`,
    options
  );

  const capacity = parseFloat(capacityRequestRes.data.capacity);
  const usage = parseFloat(capacityRequestRes.data.usage);
  const capacityInGigabyte = bytes(capacity, { decimalPlaces: 1, unit: 'GB' });
  const usageInGigabyte = bytes(usage, { decimalPlaces: 1, unit: 'GB' });
  const progressValue = Math.round((usage / capacity) * 100);

  return { capacity, capacityInGigabyte, usage, usageInGigabyte, progressValue };
};
