import { Alert, Box, Button, CircularProgress, Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { DeleteIcon, SuccessIcon, WarningIcon } from '../../../../../assets/icons/Index';
import { FileInfo } from '../../../../../config/interfaces/util';
import { Position } from '../../../../../config/interfaces/views';
import { getFileInfo } from '../../../../../services/Util';

// List of file extensions that requires CRS
const REQUIRES_CRS = new Set(['e57', 'ply', 'pts', 'xyz', 'xyzrgb', 'zip', 'las', 'LAS']);

export enum State {
  PENDING,
  UPLOADING,
  SUCCESS,
  ERROR,
}

export class FileRowData {
  file: File;

  fileInfo: FileInfo | null;

  position: Position | null = null;

  error = '';

  missingLocation = false;

  requireCrs = false;

  state: State = State.PENDING;

  progress = 0;

  constructor(file: File) {
    this.file = file;
    this.fileInfo = getFileInfo(this.file.name);

    if (this.fileInfo && REQUIRES_CRS.has(this.fileInfo.extension.toLowerCase())) {
      this.requireCrs = true;
    }
  }
}

export const FileRow: React.FC<{
  data: FileRowData;
  onRemoveFile: (file: FileRowData) => void;
  setSelectingLocationFile: React.Dispatch<React.SetStateAction<FileRowData | null>>;
}> = ({ data, onRemoveFile, setSelectingLocationFile }) => (
  <Box borderWidth="1px" borderColor="gray.200" borderRadius="4px" mb="3px">
    <Flex width="100%" py="6px" px="12px">
      <Text flex="1">{data.fileInfo?.originFilename}</Text>

      {data.state === State.SUCCESS && (
        <IconButton
          aria-label="File uploaded"
          className="file-upload-success"
          fontSize="lg"
          size="m"
          icon={<SuccessIcon />}
          backgroundColor="transparent"
          w="32px"
          color="green.400"
        />
      )}

      {data.state === State.ERROR && (
        <IconButton
          aria-label="Error"
          className="file-upload-error"
          fontSize="lg"
          size="m"
          icon={<WarningIcon />}
          backgroundColor="transparent"
          w="32px"
          color="red.600"
        />
      )}

      {/** Allow user to delete only while file is pending action or has error */}
      {[State.ERROR, State.PENDING].indexOf(data.state) >= 0 && (
        <IconButton
          aria-label="Remove file"
          className="remove-file-button"
          fontSize="lg"
          size="m"
          icon={<DeleteIcon />}
          onClick={() => onRemoveFile(data)}
          backgroundColor="transparent"
          w="32px"
        />
      )}

      {data.state === State.UPLOADING && data.progress === 0 && (
        <CircularProgress isIndeterminate size="24px" color="blue.300" thickness="16px" />
      )}
      {data.state === State.UPLOADING && data.progress > 0 && (
        <CircularProgress value={data.progress} size="24px" color="blue.300" thickness="16px" />
      )}
    </Flex>
    {data.error && !data.missingLocation && (
      <Alert status="warning">
        <Text width="100%" color="red">
          {data.error}
        </Text>
      </Alert>
    )}

    {data.missingLocation && (
      <Alert status="warning">
        <VStack alignItems="left">
          <Text>位置情報が含まれていません。手動で位置を選択するか削除してください。</Text>
          <HStack justifyContent="flex-end">
            <Button size="xs" onClick={() => onRemoveFile(data)}>
              削除
            </Button>
            <Button colorScheme="primary" size="xs" onClick={() => setSelectingLocationFile(data)}>
              位置を選択
            </Button>
          </HStack>
        </VStack>
      </Alert>
    )}
  </Box>
);
