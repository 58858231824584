import { ToolbarAreaIcon } from '../../../assets/icons/Index';
import { VIEWER_TOOLBAR_SHORTCUT } from '../../../config/constants';
import ToolbarButton from './_components/ToolbarButton';

const AreaTool: React.FC<{ isActive: boolean; onClick: () => void }> = ({ isActive, onClick }) => (
  <ToolbarButton
    icon={<ToolbarAreaIcon />}
    label={`面積計算(${VIEWER_TOOLBAR_SHORTCUT.AREA_TOOL[0]})`}
    isActive={isActive}
    onClick={onClick}
  />
);

export default AreaTool;
