// react modules
import { Box, Button, Collapse, useDisclosure, Text } from '@chakra-ui/react';

// third party modules
// components
// services
// assets, config
import {
  BigChevronDownIcon as ChevronDownIcon,
  BigChevronUpIcon as ChevronUpIcon,
} from '../../../../assets/icons/Index';

export const ItemGroup: React.FC<{ title: string }> = ({ children, title }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: false });
  return (
    <Box w="100%">
      <Button
        onClick={onToggle}
        w="100%"
        h={8}
        variant="layer"
        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        paddingInlineStart={5}
        paddingInlineEnd={4}
        display="flex"
        justifyContent="space-between"
      >
        <Text fontWeight={400}>{title}</Text>
      </Button>
      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </Box>
  );
};
