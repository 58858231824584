/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { useAuth0 } from '@auth0/auth0-react';
import { Container, Link, useToast } from '@chakra-ui/react';
import axios from 'axios';
import {
  BoundingSphere,
  Cartesian2,
  Cartesian3,
  Cesium3DTileset,
  defined,
  Entity,
  GeoJsonDataSource,
  HeadingPitchRange,
  ImageryLayer,
  KmlDataSource,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Viewer,
} from 'cesium';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CesiumComponentRef, Viewer as ResiumViewer } from 'resium';
import { LogoDark } from '../../assets/icons/Index';
import { AttentionBox } from '../../components/AttentionBox';
import { INITIAL_GLOBE_FRONTFACE_ALPHA } from '../../config/constants';
import { environments } from '../../config/environments';
import { CapacityStatus, FileInfo } from '../../config/interfaces/util';
import {
  Asset,
  AssetSettings,
  Boring as InterfaceBoring,
  Position,
  View as InterfaceView,
  ViewSettings,
  ViewShareLink,
} from '../../config/interfaces/views';
import useBoring from '../../hooks/Boring';
import useToolbar from '../../hooks/Toolbar';
import { GetPreSignUrlForDownloadAsset } from '../../services/AWS/S3';
import { getAssetInfo } from '../../services/Cesium/Util';
import {
  AddBoring,
  AddTileSetsToScene,
  FlyToCameraXYZ,
  GetCenterPosition,
  GetRayIntersectedPosition,
  InitCesiumViewer,
  SetWorldTerrain,
  TransformTileSet,
  ZoomOut,
} from '../../services/Cesium/Viewer';
import { useInterval } from '../../services/CustomHooks';
import { ProcessErrorHandler } from '../../services/ErrorHandler';
import { CopyText, getFileInfo, showToast, _ } from '../../services/Util';
import {
  CheckExistsProcessingStatusAsset,
  DeleteAsset,
  SaveAssetSettings,
  SaveProcessStatus,
} from '../../services/View/Asset';
import { GetCapacity } from '../../services/View/Capacity';
import {
  GetAssetIndex,
  GetOffsetHeight,
  GetView,
  GetViewWithDefaultSetting,
  SaveViewSettings,
} from '../../services/View/View';
import { EditShareLinkModal } from '../dashboard/EditShareLinkModal';
import { ImageModal } from '../view/Image/ImageModal';
import { DataPanelForwardRef } from '../view/InfoPanels/dataPanel/Panel';
import { AddAssetModal } from '../view/InfoPanels/modal/AddAssetModal';
import { CompletedAddAssetModal } from '../view/InfoPanels/modal/CompletedAddAssetModal';
import { DeleteCommentConfirmModal } from '../view/InfoPanels/modal/DeleteCommentConfirmModal';
import { DeleteConfirmModal } from '../view/InfoPanels/modal/DeleteConfirmModal';
import { RevertConfirmModal } from '../view/InfoPanels/modal/RevertConfirmModal';
import { SelectPositionDialog, SelectPositionDialogForwardRef } from '../view/InfoPanels/modal/_components/SelectPositionDialog';
import { Menubar } from '../view/Menubar/Menubar';
import { CommentPopup } from '../view/Toolbar/CommentTool/CommentPopup';
import { IMAGE_ICON_ID, Toolbar } from '../view/Toolbar/Toolbar';
import { InfoPanels } from './InfoPanels/InfoPanels';
import { AddBoringModal } from './InfoPanels/modal/AddBoringModal';
import { DeleteBoringConfirmModal } from './InfoPanels/modal/DeleteBoringConfirmModal';

export const Boring: React.FC = () => {
  // common settings
  const toast = useToast();
  const { user, getAccessTokenSilently } = useAuth0();

  // upload capacity status
  const [capacityStatus, setCapacityStatus] = useState<CapacityStatus>();

  // manage initial loading
  const [initialCompleted, setInitialCompleted] = useState(false);

  // cesium viewer refs
  const cesiumViewRef = useRef<CesiumComponentRef<Viewer>>(null);
  const view3DTileSetRefs = useRef<(Cesium3DTileset | KmlDataSource | GeoJsonDataSource | ImageryLayer | null)[]>([]);
  const osmBuildingsTileSetRef = useRef<Cesium3DTileset>();
  const [cesiumScreenSpaceEventHandler, setCesiumScreenSpaceEventHandler] = useState<ScreenSpaceEventHandler>();
  const [isMovingPointCloudObject, setIsMovingPointCloudObject] = useState(false);

  const [authToken, setAuthToken] = useState<string | null>(null);

  useEffect(() => {
    void (async () => {
      if (getAccessTokenSilently) {
        setAuthToken(await getAccessTokenSilently());
      }
    })();
  }, [getAccessTokenSilently]);

  // toolbar
  const {
    // distance layers
    deletedDistanceLayerIndex,
    distanceLayersPoints,
    focusedDistanceLayerIndex,
    setDeletedDistanceLayerIndex,
    setDistanceLayersPoints,
    setFocusedDistanceLayerIndex,
    updateDistanceLayerVisibility,
    // area layers
    areaLayersPoints,
    deletedAreaLayerIndex,
    focusedAreaLayerIndex,
    setAreaLayersPoints,
    setDeletedAreaLayerIndex,
    setFocusedAreaLayerIndex,
    updateAreaLayerVisibility,
    // comment layers
    commentLayersPoints,
    commentPopupAnchor,
    commentPopupPosition,
    comments,
    deleteCommentConfirmModalRef,
    deleteCommentLayer,
    focusedCommentLayerIndex,
    isCommentLayerLoading,
    onCommentModified,
    onCommentReplyAmountChanged,
    openDeleteCommentConfirmModal,
    selectedCommentLayerIndex,
    setCommentPopupAnchor,
    setCommentPopupPosition,
    setFocusedCommentLayerIndex,
    setSelectedCommentLayerIndex,
    toggleCommentPopup,
    updateCommentLayerVisibility,
    updateSelectedCommentLayer,
    // image layers
    fetchImages,
    imageLayersPoints,
    // toollbar hotkey
    isToolDisabled,
    setIsToolDisabled,
  } = useToolbar(cesiumViewRef, authToken, user?.sub);

  // view
  const { view_id } = useParams<{ view_id: string }>();
  const [view, setView] = useState<InterfaceView>();
  const [draftingView, setDraftingView] = useState<InterfaceView>();

  // boring
  const {
    fetchBorings,
    getBorings,
    borings,
    updateBoringVisibility,
    setBorings,
    openDeleteBoringConfirmModal,
    deleteBoring,
    deleteBoringConfirmModalRef,
  } = useBoring(view_id, authToken, false);

  // tiling asset
  const [isUploadingAsset, setIsUploadingAsset] = useState(false);
  const [tilingAsset, setTilingAsset] = useState<Asset | null>(null);

  // manage asset is editable state
  const [isEditable, setIsEditable] = useState(true);

  // attentionBox's text, Show box, when exists text
  const [attentionText, setAttentionText] = useState('');

  // image modal's asset ref
  const [imageModalAsset, setImageModalAsset] = useState<Asset | null>(null);

  // view info panel ref
  const InfoPanelsRef = useRef<DataPanelForwardRef>(null);

  // control modal refs
  const addAssetModalRef = useRef<{ openModal: () => void }>();
  const imageModalRef = useRef<{ openModal: (asset: Asset | null, view_id: string, entityId: string) => void }>();
  const selectPositionDialogRef = useRef<SelectPositionDialogForwardRef | null>(null);
  const addBoringModalRef = useRef<{ openModal: () => void }>();
  const completedAddAssetModalRef = useRef<{ openModal: (assetId: number) => void }>();
  const revertConfirmModalRef = useRef<{ openModal: (revertAsset: Asset) => void }>();
  const deleteConfirmModalRef = useRef<{ openModal: (deleteAssetId: number) => void }>();
  const editShareLinkModalRef = useRef<{ openModal: (curView: InterfaceView) => void }>();
  const openEditShareLinkModal = () => editShareLinkModalRef.current?.openModal(view!);

  // when loaded asset from cesium ion, adjust 3DTileSet by view asset data
  const initialize3DTileSets = useCallback(
    async (
      view3DTileSets: (Cesium3DTileset | KmlDataSource | GeoJsonDataSource | ImageryLayer | null)[],
      _view: InterfaceView,
      fileInfo?: FileInfo
    ): Promise<{
      transformed3DTileSets: (Cesium3DTileset | KmlDataSource | GeoJsonDataSource | ImageryLayer | null)[] | null;
    }> => {
      if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement) {
        console.log('cesium viewer is not loaded yet');
        return { transformed3DTileSets: null };
      }

      console.log('initialize tile set from assets');

      const transformed3DTileSets = await Promise.all(
        view3DTileSets.map(async (view3DTileSet, index) => {
          // return, when 3D tileSet is not completed for convert
          if (!view3DTileSet) return null;
          if (!(view3DTileSet instanceof Cesium3DTileset)) {
            const deepCopyView = _.cloneDeep(_view);
            const asset = deepCopyView.assets[index];
            const { orientation, position, style } = asset.asset_settings;
            return TransformTileSet(view3DTileSet, position!, orientation, style);
          }

          const ts = await view3DTileSet.readyPromise.then(async (_tileSet) => {
            // deep copy
            const deepCopyView = _.cloneDeep(_view);
            const asset = deepCopyView.assets[index];
            const { orientation, position, style } = asset.asset_settings;

            // if no position, save position
            let assetPosition = position;

            if (!assetPosition) {
              console.log('this asset no position');

              // get center position from 3DTileSet, set to view state
              const centerPosition = GetCenterPosition(view3DTileSet);

              if (fileInfo) {
                const { extension } = fileInfo;
                // Offset hegiht
                if (extension === 'las' || extension === 'LAS') {
                  const access_token = await getAccessTokenSilently();
                  try {
                    const offsetHeight = await GetOffsetHeight(access_token, centerPosition);
                    centerPosition.height += offsetHeight;
                  } catch (err) {
                    console.log(err);
                    console.log('Skip to adjust height');
                  }
                }
              }
              asset.asset_settings.position = centerPosition;
              assetPosition = centerPosition;

              // save
              try {
                setView(_.cloneDeep(deepCopyView));
                setDraftingView(_.cloneDeep(deepCopyView));

                const token = await getAccessTokenSilently();
                await SaveAssetSettings(token, deepCopyView.view_id, asset.asset_id, asset.asset_settings);
              } catch (err) {
                ProcessErrorHandler(err, 'loadView');
                return null;
              }
            }

            // set matrix4 to 3D tileSet
            const transformedView3DTileSet = TransformTileSet(_tileSet, assetPosition, orientation, style);
            return transformedView3DTileSet;
          });
          return ts;
        })
      );
      return { transformed3DTileSets };
    },
    [getAccessTokenSilently]
  );

  // first loading, get view data and initialize cesium viewer
  useEffect(() => {
    void (async () => {
      if (!initialCompleted) {
        if (!view && view_id) {
          try {
            // get view, upload file capacity and usage
            const tokenForInitializePage = await getAccessTokenSilently();
            const [responseView, responseCapacityStatus] = await Promise.all([
              await GetView(tokenForInitializePage, view_id),
              await GetCapacity(tokenForInitializePage),
            ]);

            // check tiling asset
            const checkResult = CheckExistsProcessingStatusAsset(responseView.assets);
            if (checkResult.tilingAsset) setTilingAsset(checkResult.tilingAsset);

            // set view state
            setView(_.cloneDeep(responseView));
            setDraftingView(_.cloneDeep(responseView));

            // set capacity state
            setCapacityStatus(responseCapacityStatus);
          } catch (err) {
            setInitialCompleted(true);

            if (axios.isAxiosError(err) && err.response?.data === 'view not found') {
              alert('ビューが存在しません');
              return;
            }

            ProcessErrorHandler(err, 'loadView');
          }
        } else {
          if (!view) return;
          if (osmBuildingsTileSetRef.current) return;
          if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement) {
            console.log('cesium viewer is not loaded yet');
            return;
          }

          try {
            // initialize cesium click event handler
            const cesiumViewerElement = cesiumViewRef.current.cesiumElement;
            setCesiumScreenSpaceEventHandler(new ScreenSpaceEventHandler(cesiumViewerElement.scene.canvas));

            // initialize cesium viewer
            const { view3DTileSets, osmBuildings3DTileSet } = await InitCesiumViewer(
              await getAccessTokenSilently(),
              cesiumViewRef.current,
              view
            );

            // set osm building tile set to useRef
            osmBuildingsTileSetRef.current = osmBuildings3DTileSet;

            // transform 3D tileSet with view asset data
            const { transformed3DTileSets } = await initialize3DTileSets(view3DTileSets, view);
            if (!transformed3DTileSets) return;

            // add 3D tileSets to viewer scene
            await AddTileSetsToScene(cesiumViewRef.current, transformed3DTileSets);

            // zoom to first asset
            const firstAsset = transformed3DTileSets[0];
            if (firstAsset) {
              const { show } = firstAsset;
              if (!show) firstAsset.show = true;
              await ZoomOut(cesiumViewerElement, firstAsset, 10);
              firstAsset.show = show;
            }

            // set 3D tile sets to useRef
            view3DTileSetRefs.current = transformed3DTileSets;

            setInitialCompleted(true);
            console.log('completed initialize');
          } catch (err) {
            setInitialCompleted(true);
            ProcessErrorHandler(err, 'loadView');
          }
        }
      }
    })();
  }, [getAccessTokenSilently, initialize3DTileSets, view_id, initialCompleted, view]);

  const reloadView = useCallback(async () => {
    if (!view) return;

    setAttentionText('ビューを読み込み中...');

    const accessToken = await getAccessTokenSilently();
    const updatedView = _.cloneDeep(await GetViewWithDefaultSetting(accessToken, view.view_id));
    setView(updatedView);
    setDraftingView(updatedView);
    setAttentionText('');

    // check tiling asset
    const checkResult = CheckExistsProcessingStatusAsset(updatedView.assets);
    if (checkResult.tilingAsset) setTilingAsset(checkResult.tilingAsset);
  }, [view, getAccessTokenSilently]);

  // fetch images (the dataset, not actual image) after view is done and show popup once clicked
  useEffect(() => {
    // The empty return is needed for typescript consistency of the cleanup function
    if (!view || !initialCompleted || !cesiumViewRef.current?.cesiumElement)
      return () => {
        /* do nothing */
      };

    const cesiumElement = cesiumViewRef.current?.cesiumElement;

    fetchImages(view);

    const eventListener = isToolDisabled ? 
      (selectedEntity: any): void => { /** do nothing */} :
      (selectedEntity: any): void => {
        if (defined(selectedEntity) && selectedEntity instanceof Entity) {
          if (defined(selectedEntity.id)) {
            const imageAsset = view.assets.find(row => `${IMAGE_ICON_ID}.${row.asset_id}` === selectedEntity.id);

            if (imageAsset) {
              setImageModalAsset(imageAsset);
              imageModalRef.current?.openModal(imageAsset, view.view_id, selectedEntity.id);

              // Deselect immediately. This is to make sure user can re-select
              // the same image pin after closing the modal.
              cesiumElement.selectedEntity = undefined;
            }
          }
        }
      };

    // Add event listener when the image pin is selected/clicked so we can show the modal.
    cesiumElement.selectedEntityChanged.addEventListener(eventListener);

    return function cleanup() {
      cesiumElement.selectedEntityChanged.removeEventListener(eventListener);
    };
  }, [view, initialCompleted, isToolDisabled, cesiumViewRef, fetchImages]);

  // Updating pin position (currently only used by IMAGE assets)
  const [editingPinAsset, setEditingPinAsset] = useState<Asset | null>();
  const changePinPosition = useCallback((asset: Asset) => {
    if (!selectPositionDialogRef) return;

    setEditingPinAsset(asset);
    setIsToolDisabled(true);
    selectPositionDialogRef.current?.openModal(`${IMAGE_ICON_ID}.${asset.asset_id}`, asset.asset_settings.position);
  }, [selectPositionDialogRef, setIsToolDisabled]);

  // Save the new position after a new location has been selected
  const handleSaveNewPinPosition = useCallback(
    async (newPosition: Position | null) => {
      // reset side panel
      const end = () => {
        InfoPanelsRef.current?.closeActionPanel();
        setEditingPinAsset(null);
        setIsToolDisabled(false);
        return Promise.resolve();
      };

      if (!view || !editingPinAsset || !newPosition) return end();

      const assetSettings = { ...editingPinAsset.asset_settings, position: newPosition };

      await SaveAssetSettings(await getAccessTokenSilently(), view.view_id, editingPinAsset.asset_id, assetSettings);
      await reloadView();

      return end();
    },
    [editingPinAsset, view, setIsToolDisabled, getAccessTokenSilently, reloadView]
  );

  /* asset control functions */
  // when tiling asset, use interval. get tiling status from cesium ion
  useInterval(
    async () => {
      if (!tilingAsset) return;
      if (!view || !draftingView) return;
      if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement) return;
      if (!view3DTileSetRefs.current) return;

      const tilingAssetId = tilingAsset.asset_id;
      try {
        // get asset status
        const { status, percentComplete } = await getAssetInfo(tilingAssetId);

        if (status === 'ERROR') {
          throw new Error('追加処理に失敗しました。時間を置いてから再度ご利用ください。');
        } else if (status === 'DATA_ERROR') {
          throw new Error('データに問題があり、追加処理に失敗しました。データをご確認ください。');
        } else if (status === 'AWAITING_FILES' || status === 'NOT_STARTED' || percentComplete === 0) {
          setAttentionText('ファイルの変換を準備しています');
        } else if (status === 'IN_PROGRESS') {
          setAttentionText(`ファイルを変換しています...${percentComplete}%`);
        } else if (status === 'COMPLETE') {
          // update process state, at view and draftingView
          // if exists asset in view.assets, replace asset. if not exists, push asset
          const newAsset: Asset = { ...tilingAsset, process_status: 'COMPLETE' };
          const newView = _.cloneDeep(view);
          const newDraftingView = _.cloneDeep(draftingView);
          let updateAssetIndex = newView.assets.length;

          const assetIndex = GetAssetIndex(newView, tilingAsset.asset_id);
          if (assetIndex === null) {
            newView.assets.push(newAsset);
            newDraftingView.assets.push(newAsset);
          } else {
            updateAssetIndex = assetIndex;
            newView.assets.splice(assetIndex, 1, newAsset);
            newDraftingView.assets.splice(assetIndex, 1, newAsset);
          }

          setView(newView);
          setDraftingView(newDraftingView);

          // set null to tiling asset id state, stop interval process
          setTilingAsset(null);

          // update process status
          const tokenForUpdateAssetProcessStatus = await getAccessTokenSilently();
          await SaveProcessStatus(tokenForUpdateAssetProcessStatus, view.view_id, tilingAssetId, 'COMPLETE');

          // initialize cesium viewer
          const { view3DTileSets, osmBuildings3DTileSet } = await InitCesiumViewer(
            await getAccessTokenSilently(),
            cesiumViewRef.current,
            newView
          );

          // set osm building tile set to useRef
          osmBuildingsTileSetRef.current = osmBuildings3DTileSet;

          // transform 3D tileSet with view asset data
          const fileInfo = getFileInfo(tilingAsset.file_name);
          if (fileInfo) {
            const { transformed3DTileSets } = await initialize3DTileSets(view3DTileSets, newView, fileInfo);
            if (!transformed3DTileSets) return;

            // add 3D tileSets to viewer scene
            await AddTileSetsToScene(cesiumViewRef.current, transformed3DTileSets);

            // set 3D tile sets to useRef
            view3DTileSetRefs.current = transformed3DTileSets;

            // completed add asset
            completedAddAssetModalRef.current?.openModal(updateAssetIndex);
            setAttentionText('');
          } else {
            alert('ファイルが存在しません');
          }
        }
      } catch (err) {
        setTilingAsset(null);
        setAttentionText('');

        // display alert message, and delete tiling error asset
        if (err instanceof Error) {
          if (err.message === 'Network Error') {
            alert('変換処理でエラーが発生しました。\n時間をおいてから再度お試しください。');
          } else {
            alert(err.message);
          }
        } else {
          ProcessErrorHandler(err, 'processing');
        }

        try {
          // delete asset when occur tiling error
          const tokenForDeleteAsset = await getAccessTokenSilently();
          await DeleteAsset(tokenForDeleteAsset, view.view_id, tilingAssetId);

          // if exists asset in view.assets, delete asset state
          const newView = _.cloneDeep(view);
          const newDraftingView = _.cloneDeep(draftingView);

          const assetIndex = GetAssetIndex(newView, tilingAssetId);
          if (assetIndex !== null) {
            newView.assets = newView.assets.filter((asset) => asset.asset_id !== tilingAssetId);
            newDraftingView.assets = newDraftingView.assets.filter((asset) => asset.asset_id !== tilingAssetId);

            setView(newView);
            setDraftingView(newDraftingView);
          }
        } catch (deleteErr) {
          ProcessErrorHandler(deleteErr, 'deleteAsset');
        }
      }
    },
    tilingAsset ? 3000 : null
  );

  // Add boring data
  const addBoring = (boringArray: InterfaceBoring[]) => {
    if (!user || !user.sub) return;

    console.log('boringarray', boringArray);
    setBorings(boringArray);
    const newBoringArray: InterfaceBoring[] = getBorings();
    console.log('Boringss in state after', newBoringArray);
    if (!newBoringArray.length) return;
    newBoringArray.forEach((boring) => {
      if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement || !view) {
        console.log('cesium viewer is not loaded yet');
        return;
      }
      AddBoring(cesiumViewRef.current.cesiumElement, boring);
    });
  };

  // update draftingView and 3DTileSet for the asset, when edited asset at info panel
  const editedAsset = (newAsset: Asset) => {
    if (!draftingView) return;

    const assetIndex = GetAssetIndex(draftingView, newAsset.asset_id);
    if (assetIndex === null) return;

    // update drafting view
    const newDraftingView = _.cloneDeep(draftingView);
    newDraftingView.assets[assetIndex] = newAsset;
    setDraftingView(newDraftingView);

    update3DTileSet(newAsset.asset_settings, assetIndex);
  };

  const saveAsset = async (newAsset: Asset) => {
    if (!draftingView) return;

    const assetIndex = GetAssetIndex(draftingView, newAsset.asset_id);
    if (assetIndex === null) return;

    const newView = _.cloneDeep(draftingView);
    newView.assets[assetIndex] = newAsset;

    setIsEditable(false);

    try {
      // save asset settings
      const tokenForUpdateAsset = await getAccessTokenSilently();
      await SaveAssetSettings(tokenForUpdateAsset, newView.view_id, newAsset.asset_id, newAsset.asset_settings);

      setView(_.cloneDeep(newView));
      setDraftingView(_.cloneDeep(newView));
    } catch (err) {
      ProcessErrorHandler(err, 'saveAsset');
    }

    // close action panel
    InfoPanelsRef.current?.closeActionPanel();
    setIsEditable(true);
  };

  const saveAssetVisible = async (newAsset: Asset) => {
    if (!draftingView) return;

    const assetIndex = GetAssetIndex(draftingView, newAsset.asset_id);
    if (assetIndex === null) return;

    update3DTileSet(newAsset.asset_settings, assetIndex);

    await saveAsset(newAsset);
  };

  const deleteAsset = async (deleteAssetId: number) => {
    if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement || !view) {
      console.log('cesium viewer is not loaded yet');
      return;
    }

    try {
      setAttentionText('ファイルを削除しています');
      setIsEditable(false);

      // delete asset
      const tokenForDeleteAsset = await getAccessTokenSilently();
      await DeleteAsset(tokenForDeleteAsset, view.view_id, deleteAssetId);

      const deletedAssetView = _.cloneDeep(view);
      deletedAssetView.assets = deletedAssetView.assets.filter((_asset) => _asset.asset_id !== deleteAssetId);

      setView(_.cloneDeep(deletedAssetView));
      setDraftingView(_.cloneDeep(deletedAssetView));

      // initialize cesium viewer
      const { view3DTileSets, osmBuildings3DTileSet } = await InitCesiumViewer(
        await getAccessTokenSilently(),
        cesiumViewRef.current,
        deletedAssetView
      );

      // set osm building tile set to useRef
      osmBuildingsTileSetRef.current = osmBuildings3DTileSet;

      // transform 3D tileSet with view asset data
      const { transformed3DTileSets } = await initialize3DTileSets(view3DTileSets, deletedAssetView);
      if (!transformed3DTileSets) return;

      // add 3D tileSets to viewer scene
      await AddTileSetsToScene(cesiumViewRef.current, transformed3DTileSets);

      // set 3D tile sets to useRef
      view3DTileSetRefs.current = transformed3DTileSets;

      showToast('ファイルを削除しました', '', 'success', toast);
    } catch (err) {
      ProcessErrorHandler(err, 'deleteAsset');
    }

    setAttentionText('');
    setIsEditable(true);
  };

  const revertAsset = (asset: Asset) => {
    if (!view) return;
    const originView = _.cloneDeep(view);

    const assetIndex = GetAssetIndex(originView, asset.asset_id);
    if (assetIndex === null) return;

    // revert drafting view
    setDraftingView(originView);

    // revert 3DTileSet
    update3DTileSet(originView?.assets[assetIndex].asset_settings, assetIndex);

    // close panel
    InfoPanelsRef.current?.closeActionPanel();

    // revert position selection. won't do anything if there's no selection in progress
    selectPositionDialogRef.current?.revert();
  };

  const zoomAsset = async (assetIndex: number) => {
    if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement) return;
    if (!view3DTileSetRefs.current || !view3DTileSetRefs.current.length) return;

    const cesiumViewerElement = cesiumViewRef.current.cesiumElement;
    const view3DTileSet = view3DTileSetRefs.current[assetIndex];

    if (!view3DTileSet) return;

    // zoom out at first asset
    await ZoomOut(cesiumViewerElement, view3DTileSet, 10);
  };

  // download selected asset origin file
  const downloadAsset = async (asset: Asset) => {
    if (!view) return;

    const fileInfo = getFileInfo(asset.file_name);
    if (fileInfo) {
      const { contentType } = fileInfo;

      try {
        // get presigned url for selected asset
        const downloadUrl = await GetPreSignUrlForDownloadAsset(
          await getAccessTokenSilently(),
          view.view_id,
          asset.asset_id,
          contentType
        );

        // download asset at new tab
        window.open(downloadUrl);
      } catch (err) {
        ProcessErrorHandler(err, 'downloadAsset');
      }
    } else {
      alert('ファイルが存在しません');
    }
  };

  const openRevertAssetConfirmModal = () => {
    if (!view) return;
    const editAssetStatus = InfoPanelsRef.current?.getEditAssetStatus();
    if (!editAssetStatus) return;
    const { assetId } = editAssetStatus;
    if (assetId === null || assetId === undefined) return;
    const assetIndex = GetAssetIndex(view, assetId);
    if (assetIndex === null) return;
    revertConfirmModalRef.current?.openModal(view.assets[assetIndex]);
  };

  const openDeleteAssetConfirmModal = (asset: Asset) => deleteConfirmModalRef.current?.openModal(asset.asset_id);

  const openAddAssetModal = () => {
    if (!view || !draftingView) return;

    if (!_.isEqual(view, draftingView)) {
      const editAssetStatus = InfoPanelsRef.current?.getEditAssetStatus();
      if (!editAssetStatus) return;
      const editingAssetId = editAssetStatus.assetId;
      if (editingAssetId === null || editingAssetId === undefined) return;

      const editingAssetIndex = GetAssetIndex(draftingView, editingAssetId);
      if (editingAssetIndex === null) return;

      // open revert confirm modal
      revertConfirmModalRef.current?.openModal(draftingView.assets[editingAssetIndex]);
    } else if (capacityStatus && capacityStatus.usage > capacityStatus.capacity) {
      alert(`アップロードファイルの合計が${capacityStatus.capacityInGigabyte}を超えています。`);
    } else if (isUploadingAsset || tilingAsset) {
      alert('現在、ファイルを追加中です。完了後に再度ご利用ください。');
    } else if (!isEditable) {
      alert('現在、ファイルを更新中です。完了後に再度ご利用ください。');
    } else {
      addAssetModalRef.current?.openModal();
    }
  };

  const openAddBoringModal = () => {
    if (!view || !draftingView) return;

    if (!_.isEqual(view, draftingView)) {
      const editAssetStatus = InfoPanelsRef.current?.getEditAssetStatus();
      if (!editAssetStatus) return;
      const editingAssetId = editAssetStatus.assetId;
      if (editingAssetId === null || editingAssetId === undefined) return;

      const editingAssetIndex = GetAssetIndex(draftingView, editingAssetId);
      if (editingAssetIndex === null) return;

      // open revert confirm modal
      revertConfirmModalRef.current?.openModal(draftingView.assets[editingAssetIndex]);
    } else if (capacityStatus && capacityStatus.usage > capacityStatus.capacity) {
      alert(`アップロードファイルの合計が${capacityStatus.capacityInGigabyte}を超えています。`);
    } else if (isUploadingAsset || tilingAsset) {
      alert('現在、ファイルを追加中です。完了後に再度ご利用ください。');
    } else if (!isEditable) {
      alert('現在、ファイルを更新中です。完了後に再度ご利用ください。');
    } else {
      addBoringModalRef.current?.openModal();
    }
  };

  /* cesium viewer control functions */

  // update asset style in cesium viewer
  const update3DTileSet = (assetSettings: AssetSettings, assetIndex: number) => {
    const { position, orientation, style } = assetSettings;
    const view3DTileSet = view3DTileSetRefs.current[assetIndex];
    if (!view3DTileSet) return;

    const transformedView3DTileSet = TransformTileSet(view3DTileSet, position!, orientation, style);
    view3DTileSetRefs.current[assetIndex] = transformedView3DTileSet;
  };

  // cesium viewer click event
  const clickEvent = useCallback(
    (cp: { position: Cartesian2 }) => {
      try {
        if (!draftingView) return;

        // get editing asset index
        const editAssetStatus = InfoPanelsRef.current?.getEditAssetStatus();
        if (!editAssetStatus) return;
        const { assetId } = editAssetStatus;
        if (assetId === null || assetId === undefined) return;
        const assetIndex = GetAssetIndex(draftingView, assetId);
        if (assetIndex === null) return;

        const cesiumViewerElement = cesiumViewRef.current!.cesiumElement!;

        // get intersect position
        const { intersectedPosition } = GetRayIntersectedPosition(cesiumViewerElement, cp.position);
        if (!intersectedPosition) return;

        const newDraftingView = _.cloneDeep(draftingView);
        const { orientation, style } = newDraftingView.assets[assetIndex].asset_settings;

        const view3DTileSet = view3DTileSetRefs.current[assetIndex];
        if (!view3DTileSet) return;
        if (!(view3DTileSet instanceof Cesium3DTileset)) return;
        // get matrix
        const transformedView3DTileSet = TransformTileSet(view3DTileSet, intersectedPosition, orientation, style);
        view3DTileSetRefs.current[assetIndex] = transformedView3DTileSet;

        // apply editableView
        newDraftingView.assets[assetIndex].asset_settings.position = intersectedPosition;
        InfoPanelsRef.current?.updateEditAssetStatus({
          ...editAssetStatus,
          isEdited: true,
        });
        setDraftingView(newDraftingView);
      } catch (err) {
        console.error(err);
        alert('地点選択処理でエラーが発生しました。\n画面を更新して再度お試しください。');
      }
    },
    [draftingView]
  );

  // switch cesium clickable setting
  useEffect(() => {
    if (!cesiumScreenSpaceEventHandler) return;
    if (isMovingPointCloudObject) {
      cesiumScreenSpaceEventHandler.setInputAction(clickEvent, ScreenSpaceEventType.LEFT_CLICK);
    } else {
      cesiumScreenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
    }
  }, [cesiumScreenSpaceEventHandler, clickEvent, isMovingPointCloudObject]);

  const moveCamera = async (asset: Asset, vectorType: string) => {
    if (!draftingView) return;

    // Since images are entities and we don't have access to the entity,
    // fly there manually
    if (asset.asset_type === 'IMAGE') {
      if (!asset.asset_settings.position) return;
      cesiumViewRef.current?.cesiumElement?.camera.flyTo({
        destination: Cartesian3.fromDegrees(
          asset.asset_settings.position.longitude,
          asset.asset_settings.position.latitude,
          asset.asset_settings.position.height + 1000.0
        ),
      });
      return;
    }

    const assetIndex = GetAssetIndex(draftingView, asset.asset_id);
    if (assetIndex === null) return;
    const targetAsset = view3DTileSetRefs.current[assetIndex];
    if (!targetAsset) return;
    if (targetAsset instanceof Cesium3DTileset) {
      FlyToCameraXYZ(cesiumViewRef.current!, targetAsset, vectorType);
    } else {
      // Note: Hacky but it's due to a problem with cesiumJs itself.
      // https://github.com/CesiumGS/cesium/issues/4327
      // https://github.com/DataLabs-Japan/viewer-app/issues/127
      const { show } = targetAsset;
      if (!show) targetAsset.show = true;
      await cesiumViewRef.current?.cesiumElement?.zoomTo(targetAsset);
      cesiumViewRef.current?.cesiumElement?.camera.zoomOut(1);
      await cesiumViewRef.current?.cesiumElement?.flyTo(targetAsset, { duration: 0.8 });
      await cesiumViewRef.current?.cesiumElement?.zoomTo(targetAsset);
      targetAsset.show = show;
    }
  };

  const setCameraHeading = (heading: number) => {
    const scene = cesiumViewRef.current?.cesiumElement?.scene;
    const camera = cesiumViewRef.current?.cesiumElement?.camera;
    if (!camera || !scene) return;

    // get intersection of camera and globe
    const center = new Cartesian2(scene.canvas.clientWidth / 2.0, scene.canvas.clientHeight / 2.0);
    const ray = camera.getPickRay(center);
    const target = scene.globe.pick(ray, scene);
    if (!target) return;

    const range = Cartesian3.distance(target, camera.position);
    camera.flyToBoundingSphere(new BoundingSphere(target), {
      offset: new HeadingPitchRange(heading, camera.pitch, range),
      duration: 0.5,
    });
  };

  /* view control functions */

  const saveViewSettings = async (newViewSettings: ViewSettings) => {
    if (!draftingView) return;

    const newDraftingView = _.cloneDeep(draftingView);
    const { show_osm_buildings, on_world_terrain } = newViewSettings;
    // switch enable world terrain
    SetWorldTerrain(cesiumViewRef.current!, on_world_terrain);
    // switch show osm buildings tile set
    osmBuildingsTileSetRef.current!.show = show_osm_buildings;

    newDraftingView.view_settings = newViewSettings;

    try {
      setIsEditable(false);

      const tokenForSaveViewSettings = await getAccessTokenSilently();
      await SaveViewSettings(tokenForSaveViewSettings, newDraftingView.view_id, newDraftingView.view_settings);

      setView(_.cloneDeep(newDraftingView));
      setDraftingView(_.cloneDeep(newDraftingView));
    } catch (err) {
      ProcessErrorHandler(err, 'editView');
    }
    setIsEditable(true);
  };

  /* share link control functions */

  const saveShareLink = async (viewShareLink: ViewShareLink) => {
    if (!view) return;

    const newView = { ...view };
    newView.view_settings.is_share = viewShareLink.is_share;
    newView.view_settings.password = viewShareLink.password ? '*' : '';

    setView(_.cloneDeep(newView));
    setDraftingView(_.cloneDeep(newView));

    const copyUrl = `${environments.REACT_APP_BASE_URL}/share/${newView.view_id}`;
    if (newView.view_settings.is_share) await CopyText(copyUrl, '共有リンクをコピーしました。', toast);
  };

  const editGlobeTranslucency = (alpha: number) => {
    if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement) {
      console.log('cesium viewer is not loaded yet');
      return;
    }
    const frontAlpha = alpha > INITIAL_GLOBE_FRONTFACE_ALPHA ? INITIAL_GLOBE_FRONTFACE_ALPHA : alpha;
    cesiumViewRef.current.cesiumElement.scene.globe.translucency.frontFaceAlpha = frontAlpha;
    cesiumViewRef.current.cesiumElement.scene.globe.translucency.backFaceAlpha = alpha;
  };

  return (
    // set overflow hidden not to add scroll bars when displays a comment popup
    <Container width="100%" maxW="100%" p={0} overflow="hidden">
      <ResiumViewer
        ref={cesiumViewRef}
        homeButton={false}
        navigationHelpButton={false}
        timeline={false}
        animation={false}
        style={{ position: 'relative', height: '100vh', cursor: focusedCommentLayerIndex >= 0 ? 'pointer' : 'default' }}
        // hide the selection status of the picked point (distance, area tool)
        selectionIndicator={false}
      >
        <Menubar />

        {attentionText && <AttentionBox>{attentionText}</AttentionBox>}
        {view && draftingView && (
          // {view && draftingView && borings && (
          <>
            <InfoPanels
              borings={borings}
              isSharedView={false}
              ref={InfoPanelsRef}
              view={draftingView}
              isEditable={isEditable}
              // distance layers
              distanceLayersPoints={distanceLayersPoints}
              focusedDistanceLayerIndex={focusedDistanceLayerIndex}
              setFocusedDistanceLayerIndex={setFocusedDistanceLayerIndex}
              deleteDistanceLayer={setDeletedDistanceLayerIndex}
              updateDistanceLayerVisibility={updateDistanceLayerVisibility}
              // area layers
              areaLayersPoints={areaLayersPoints}
              focusedAreaLayerIndex={focusedAreaLayerIndex}
              setFocusedAreaLayerIndex={setFocusedAreaLayerIndex}
              deleteAreaLayer={setDeletedAreaLayerIndex}
              updateAreaLayerVisibility={updateAreaLayerVisibility}
              // comment layers
              commentLayersPoints={commentLayersPoints}
              focusedCommentLayerIndex={focusedCommentLayerIndex}
              setFocusedCommentLayerIndex={setFocusedCommentLayerIndex}
              selectedCommentLayerIndex={selectedCommentLayerIndex}
              setSelectedCommentLayerIndex={updateSelectedCommentLayer}
              updateCommentLayerVisibility={updateCommentLayerVisibility}
              isCommentLayerLoading={isCommentLayerLoading}
              // cesium viewer handler
              moveCameraHandler={moveCamera}
              switchCesiumClickEventHandler={setIsMovingPointCloudObject}
              // asset handler
              editedAssetHandler={editedAsset}
              saveAssetHandler={saveAsset}
              saveAssetVisibleHandler={saveAssetVisible}
              downloadAssetHandler={downloadAsset}
              // boring handler
              updateBoringLayerVisibilityHandler={updateBoringVisibility}
              cesiumViewerElement={cesiumViewRef.current!.cesiumElement!}
              // view handler
              saveViewSettingsHandler={saveViewSettings}
              editGlobeTranslucencyHandler={editGlobeTranslucency}
              // open modal handler
              openAddAssetModalHandler={openAddAssetModal}
              openAddBoringModalHandler={openAddBoringModal}
              openDeleteAssetConfirmModalHandler={openDeleteAssetConfirmModal}
              openEditShareLinkModalHandler={openEditShareLinkModal}
              openRevertAssetConfirmModalHandler={openRevertAssetConfirmModal}
              openDeleteCommentConfirmModalHandler={openDeleteCommentConfirmModal}
              openDeleteBoringConfirmModalHandler={openDeleteBoringConfirmModal}
              // changing pin position 
              changePinPosition={changePinPosition}
            />
            <Toolbar
              areaLayersPoints={areaLayersPoints}
              cesiumScreenSpaceEventHandler={cesiumScreenSpaceEventHandler}
              cesiumViewerElement={cesiumViewRef.current!.cesiumElement!}
              deletedAreaLayerIndex={deletedAreaLayerIndex}
              deletedDistanceLayerIndex={deletedDistanceLayerIndex}
              distanceLayersPoints={distanceLayersPoints}
              focusedAreaLayerIndex={focusedAreaLayerIndex}
              focusedDistanceLayerIndex={focusedDistanceLayerIndex}
              focusedCommentLayerIndex={focusedCommentLayerIndex}
              setSelectedCommentLayerIndex={updateSelectedCommentLayer}
              setFocusedCommentLayerIndex={setFocusedCommentLayerIndex}
              resetDeletedAreaLayerIndex={() => setDeletedAreaLayerIndex(-1)}
              resetDeletedDistanceLayerIndex={() => setDeletedDistanceLayerIndex(-1)}
              setAreaLayersPoints={setAreaLayersPoints}
              setDistanceLayersPoints={setDistanceLayersPoints}
              isCommentPopupOpen={!!commentPopupAnchor}
              isToolDisabled={isToolDisabled}
              toggleCommentPopup={toggleCommentPopup}
              commentLayersPoints={commentLayersPoints}
              imageLayersPoints={imageLayersPoints}
              isMovingPointCloudObject={isMovingPointCloudObject}
              setCameraHeading={setCameraHeading}
            />
            <CommentPopup
              anchor={commentPopupAnchor}
              position={commentPopupPosition}
              closePopup={() => {
                setCommentPopupAnchor(undefined);
                setCommentPopupPosition(undefined);
                setSelectedCommentLayerIndex(-1);
              }}
              comment={comments?.[selectedCommentLayerIndex]}
              onCommentModified={onCommentModified}
              onCommentReplyAmountChanged={onCommentReplyAmountChanged}
              deleteComment={() => openDeleteCommentConfirmModal(selectedCommentLayerIndex)}
              authToken={authToken}
              signedInUser={user}
              forSharedView={false}
            />
          </>
        )}
        <Link
          href="/landing/index.html"
          target="_blank"
          _focus={{ boxShadow: 'none' }}
          position="absolute"
          bottom="40px"
          left={0}
        >
          <LogoDark height="28px" width="auto" />
        </Link>
      </ResiumViewer>
      <AddAssetModal
        ref={addAssetModalRef}
        capacityStatus={capacityStatus}
        view={view}
        setIsToolDisabled={setIsToolDisabled}
        cesiumViewer={cesiumViewRef.current?.cesiumElement}
        cesiumScreenSpaceEventHandler={cesiumScreenSpaceEventHandler}
        reloadView={reloadView}
        isEditable={isEditable}
        tilingAsset={tilingAsset}
      />
      <ImageModal
        ref={imageModalRef}
        authToken={authToken}
        asset={imageModalAsset}
        setIsToolDisabled={setIsToolDisabled}
        cesiumViewer={cesiumViewRef.current?.cesiumElement}
        cesiumScreenSpaceEventHandler={cesiumScreenSpaceEventHandler}
        reloadView={reloadView}
        forSharedView={false}
      />
      <AddBoringModal
        view_id={view_id}
        ref={addBoringModalRef}
        capacityStatus={capacityStatus}
        authToken={authToken}
        addBoringHandler={addBoring}
        fetchBorings={fetchBorings}
      />
      <SelectPositionDialog
        ref={selectPositionDialogRef}
        cesiumViewer={cesiumViewRef.current?.cesiumElement}
        onSavePosition={handleSaveNewPinPosition}
      />
      <CompletedAddAssetModal ref={completedAddAssetModalRef} zoomAssetHandler={zoomAsset} />
      <RevertConfirmModal ref={revertConfirmModalRef} revertAssetHandler={revertAsset} />
      <DeleteConfirmModal ref={deleteConfirmModalRef} deleteAssetHandler={deleteAsset} />
      <DeleteCommentConfirmModal ref={deleteCommentConfirmModalRef} deleteCommentHandler={deleteCommentLayer} />
      <DeleteBoringConfirmModal ref={deleteBoringConfirmModalRef} deleteBoringHandler={deleteBoring} />
      <EditShareLinkModal ref={editShareLinkModalRef} saveShareLinkHandler={saveShareLink} />
    </Container>
  );
};
