// react modules
import { VStack } from '@chakra-ui/react';

// components
import { GISPanelItem } from './PanelItem';

// services
import { _ } from '../../../../services/Util';

// assets, config
import { View, ViewSettings } from '../../../../config/interfaces/views';
import { INFO_PANEL_PADDING } from '../../../../config/styles';

export const GISPanel: React.FC<{
  view: View;
  saveViewSettingsHandler: (newViewSettings: ViewSettings) => void;
}> = ({ view, saveViewSettingsHandler }) => {
  const { view_settings } = view;
  return (
    <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1}>
      <GISPanelItem
        label="OSM建物モデル"
        visible={view_settings.show_osm_buildings}
        updateVisibility={(newVisible) => {
          const newViewSettings = _.cloneDeep(view_settings);
          newViewSettings.show_osm_buildings = newVisible;
          saveViewSettingsHandler(newViewSettings);
        }}
      />
      <GISPanelItem
        label="PLATEAU建物モデル(テクスチャ無し)"
        visible={view_settings.show_plateau_notexture_buildings}
        updateVisibility={(newVisible) => {
          const newViewSettings = _.cloneDeep(view_settings);
          newViewSettings.show_plateau_notexture_buildings = newVisible;
          saveViewSettingsHandler(newViewSettings);
        }}
      />
      <GISPanelItem
        label="PLATEAU建物モデル(テクスチャ付き)"
        visible={view_settings.show_plateau_texture_buildings}
        updateVisibility={(newVisible) => {
          const newViewSettings = _.cloneDeep(view_settings);
          newViewSettings.show_plateau_texture_buildings = newVisible;
          saveViewSettingsHandler(newViewSettings);
        }}
      />
    </VStack>
  );
};
