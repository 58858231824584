/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  forwardRef,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';

// assets, config
import { useAuth0 } from '@auth0/auth0-react';
import { ShowIcon, HideIcon } from '../../assets/icons/Index';
import { View, ViewOutline, ViewShareLink } from '../../config/interfaces/views';
import { SHARE_LINK_PASSWORD_MAX_LENGTH } from '../../config/constants';

// services
import { ProcessErrorHandler } from '../../services/ErrorHandler';
import { SaveViewShareLink } from '../../services/View/View';

// services

const EditShareLinkModalFunc: React.ForwardRefRenderFunction<
  {
    openModal: (view: View | ViewOutline) => void;
  },
  {
    saveShareLinkHandler: (viewShareLink: ViewShareLink) => void;
  }
> = ({ saveShareLinkHandler }, modalRef) => {
  // form statuses
  const [view, setView] = useState<View | ViewOutline>();
  const [isShareEnabled, setIsShareEnabled] = useState<boolean>(false);
  const [isPasswordEnabled, setIsPasswordEnabled] = useState<boolean>(false);
  const [isPasswordEdited, setIsPasswordEdited] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm();

  useImperativeHandle(modalRef, () => ({
    openModal(_view: View | ViewOutline) {
      onOpen();
      setView(_view);
      setIsShareEnabled(!!_view.view_settings.is_share);
      setIsPasswordEnabled(!!_view.view_settings.password);
      setPassword(_view.view_settings.password);
      setShowPassword(false);
      setIsPasswordEdited(false);
    },
  }));

  const handlePasswordVisibilityChange = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (newPassword: string) => {
    setPassword(newPassword);
    setIsPasswordEdited(true);
  };

  const getPasswordString = () => {
    if (!isPasswordEdited && password) {
      return 'パスワード設定済み';
    }
    return password;
  };

  const checkViewStatusChanged = (): boolean => {
    if (!view) {
      return false;
    }
    if (isPasswordEdited) {
      return true;
    }
    if (isShareEnabled) {
      if (isPasswordEnabled && !view.view_settings.password) {
        return true;
      }
      if (!isPasswordEnabled && view.view_settings.password) {
        return true;
      }
    }
    if (view.view_settings.is_share !== isShareEnabled) {
      return true;
    }

    return false;
  };

  // Check if submit button should be enabled or not
  const shouldDisableForm = () => {
    // User cannot submit form if one of any below conditions is not passed
    // Form is being submitted
    if (isSubmitting) {
      return '送信されています。';
    }
    // Password is enabled but empty
    if (isShareEnabled && isPasswordEnabled && !password.length) {
      return `パスワードは1〜${SHARE_LINK_PASSWORD_MAX_LENGTH || '1'}桁である必要があります。`;
    }
    // There is nothing to submit
    if (!checkViewStatusChanged()) {
      return '何も変わっていません。';
    }
    return '';
  };

  // edit share link process
  const onSubmit = async () => {
    if (view) {
      const viewShareLink: ViewShareLink = {
        view_id: view.view_id,
        is_share: isShareEnabled,
        password: isShareEnabled && isPasswordEnabled ? password : '',
      };

      try {
        const tokenForUpdate = await getAccessTokenSilently();
        await SaveViewShareLink(tokenForUpdate, viewShareLink);

        saveShareLinkHandler(viewShareLink);
        onClose();
      } catch (err) {
        ProcessErrorHandler(err, 'saveViewShareLink');
      }
    } else {
      alert('ビューが存在しません。');
    }
  };

  return (
    <Modal closeOnOverlayClick={!isSubmitting} isOpen={isOpen} onClose={onClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>共有リンクの設定</ModalHeader>
        <ModalCloseButton hidden={isSubmitting} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Controller
              control={control}
              name="enabled"
              defaultValue={isShareEnabled}
              render={({ field: { ref, ...restField } }) => (
                <FormControl id="enabled" display="flex" alignItems="center" mb="5">
                  <Switch
                    {...restField}
                    name="enabled"
                    ref={ref}
                    isChecked={isShareEnabled}
                    onChange={(e) => setIsShareEnabled(e.target.checked)}
                    textAlign="right"
                  />
                  <FormLabel htmlFor="enabled" mb="0" ml="2">
                    共有リンクを有効化
                  </FormLabel>
                </FormControl>
              )}
            />
            {isShareEnabled && (
              <Controller
                control={control}
                name="passwordEnabled"
                defaultValue={isPasswordEnabled}
                render={({ field: { ref, ...restField } }) => (
                  <FormControl id="passwordEnabled" mb="5">
                    <Box display="flex" alignItems="center">
                      <Switch
                        {...restField}
                        name="passwordEnabled"
                        ref={ref}
                        isChecked={isPasswordEnabled}
                        onChange={(e) => setIsPasswordEnabled(e.target.checked)}
                        textAlign="right"
                      />
                      <FormLabel htmlFor="passwordEnabled" mb="0" ml="2">
                        パスワードを設定する
                      </FormLabel>
                    </Box>
                    {!isPasswordEnabled && (
                      <FormHelperText color="orange">パスワードなしの場合、セキュリティが低下します。</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            )}
            {isShareEnabled && isPasswordEnabled && (
              <Controller
                control={control}
                name="password"
                defaultValue={password}
                render={({ field: { ref, ...restField } }) => (
                  <FormControl id="passwordEna">
                    <FormLabel htmlFor="password">パスワード</FormLabel>
                    <InputGroup size="md">
                      <Input
                        {...restField}
                        ref={ref}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="パスワードを入力"
                        onChange={(e) => handlePasswordChange(e.target.value.trim())}
                        value={getPasswordString()}
                        disabled={!isPasswordEnabled}
                        maxLength={30}
                      />
                      <InputRightElement w={14}>
                        <IconButton
                          aria-label="Toogle password visibility"
                          borderColor="inherit"
                          borderWidth="1px"
                          className="input-action-button"
                          disabled={!isPasswordEnabled || !password?.length}
                          fontSize="lg"
                          icon={showPassword ? <HideIcon /> : <ShowIcon />}
                          onClick={handlePasswordVisibilityChange}
                          w="100%"
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormHelperText>
                      パスワードは1〜{SHARE_LINK_PASSWORD_MAX_LENGTH}桁である必要があります。
                    </FormHelperText>
                  </FormControl>
                )}
              />
            )}
          </ModalBody>
          <ModalFooter mt={8}>
            <Button disabled={isSubmitting} me={3} py={2} minW="100px" onClick={onClose}>
              キャンセル
            </Button>

            <Button
              colorScheme="primary"
              disabled={!!shouldDisableForm()}
              isLoading={isSubmitting}
              minW="100px"
              type="submit"
              title={shouldDisableForm()}
              py={2}
            >
              確認
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export const EditShareLinkModal = forwardRef(EditShareLinkModalFunc);
