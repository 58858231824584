import { ArrowBackIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, HStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from '../InfoPanels/_components/ActionButton';

export const Menubar: React.FC = ({ children }) => {
  const navigate = useNavigate();
  return (
    <HStack position="absolute" top={1} left={1}>
      <Box>
        <ActionButton icon={ArrowBackIcon} colorScheme="secondary" onClickHandler={() => navigate(`/dashboard`)}>
          ダッシュボード
        </ActionButton>
      </Box>
      <Box>
        <ActionButton
          icon={QuestionOutlineIcon}
          colorScheme="secondary"
          onClickHandler={() =>
            window.open('https://datalabs-jp.notion.site/LinkedViewer-f1714dc6b352485fadf1539d494e3c1b')
          }
        />
      </Box>
      {children}
    </HStack>
  );
};
