const env = process.env.REACT_APP_ENV ? `_${process.env.REACT_APP_ENV}` : '';

export type EnvironmentVariables = {
  REACT_APP_BASE_URL: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_AUDIENCE: string;
  REACT_APP_CESIUM_ION_ACCESS_TOKEN: string;
  REACT_APP_API_GATEWAY_URL: string;
  REACT_APP_FILE_BUCKET_NAME: string;
};

export const environments: EnvironmentVariables = {
  REACT_APP_BASE_URL: process.env[`REACT_APP${env}_BASE_URL`]!,
  REACT_APP_AUTH0_DOMAIN: process.env[`REACT_APP${env}_AUTH0_DOMAIN`]!,
  REACT_APP_AUTH0_CLIENT_ID: process.env[`REACT_APP${env}_AUTH0_CLIENT_ID`]!,
  REACT_APP_AUDIENCE: process.env[`REACT_APP${env}_AUDIENCE`]!,
  REACT_APP_CESIUM_ION_ACCESS_TOKEN: process.env[`REACT_APP${env}_CESIUM_ION_ACCESS_TOKEN`]!,
  REACT_APP_API_GATEWAY_URL: process.env[`REACT_APP${env}_API_GATEWAY_URL`]!,
  REACT_APP_FILE_BUCKET_NAME: process.env[`REACT_APP${env}_FILE_BUCKET_NAME`]!,
};

export default environments;
