// third party modules
import axios from 'axios';
import {
  Cartesian3,
  Cesium3DTileStyle,
  HeadingPitchRoll,
  Math as CesiumMath,
  Matrix4,
  TranslationRotationScale,
  Transforms,
} from 'cesium';

// assets, config
import { Position, Orientation, Style, ProcessStatus } from '../../config/interfaces/views';
import Environments from '../../config/environments';

const CESIUM_ION_API_URL = 'https://api.cesium.com/v1';
const CESIUM_ION_ACCESS_TOKEN = Environments.REACT_APP_CESIUM_ION_ACCESS_TOKEN;

/**
 * get matrix4 with position, orientation, scale
 * @param position longitude, latitude, height
 * @param orientation roll, pitch, yaw
 * @param scale number
 * @returns Matrix4
 */
export const getMatrix4 = (
  position: Position,
  orientation: Orientation,
  scale: number
): { tileSetMatrix4: Matrix4 } => {
  const { longitude, latitude, height } = position;
  const { yaw, pitch, roll } = orientation;

  // create new position, headingPitchRoll, scale
  const newPosition = Cartesian3.fromDegrees(longitude, latitude, height);
  const newHpr = new HeadingPitchRoll(
    CesiumMath.toRadians(yaw),
    CesiumMath.toRadians(pitch),
    CesiumMath.toRadians(roll)
  );
  const newScale = new Cartesian3(scale, scale, scale);

  // create quaternion
  const quaternion = Transforms.headingPitchRollQuaternion(newPosition, newHpr);
  // apply translation
  const trs = new TranslationRotationScale(newPosition, quaternion, newScale);
  // Creates a Matrix4 instance from a TranslationRotationScale instance.
  const tileSetMatrix4 = Matrix4.fromTranslationRotationScale(trs, new Matrix4());

  return { tileSetMatrix4 };
};

/**
 *  get Cesium3DTileStyle with view tileSetStyle
 * @param tileSetStyle tileSetStyle
 * @returns Cesium3DTileStyle
 */
export const getTileSetStyle = (tileSetStyle: Style): { cesium3DTileStyle: Cesium3DTileStyle } => {
  const { is_point_color_override, point_alpha, point_color, point_size } = tileSetStyle;

  // 適用するスタイルのインスタンス化
  const cesium3DTileStyle = new Cesium3DTileStyle({ pointSize: point_size });

  // 色上書き設定が有効の場合、色を設定
  if (is_point_color_override)
    cesium3DTileStyle.color = new Cesium3DTileStyle({ color: `color("${point_color}",${point_alpha})` }).color;

  return { cesium3DTileStyle };
};

/**
 * cesium ion からアセット情報取得
 * @param asset_id
 * @returns status, percentComplete
 */
export const getAssetInfo = async (asset_id: number): Promise<{ status: ProcessStatus; percentComplete: number }> => {
  console.log(`getAsset id: ${asset_id}`);
  const assetInfo = await axios.get<{ status: ProcessStatus; percentComplete: number }>(
    `${CESIUM_ION_API_URL}/assets/${asset_id}`,
    {
      headers: {
        Authorization: `Bearer ${CESIUM_ION_ACCESS_TOKEN}`,
      },
      responseType: 'json',
    }
  );

  return {
    status: assetInfo.data.status,
    percentComplete: assetInfo.data.percentComplete,
  };
};
