import axios, { AxiosRequestConfig } from 'axios';

import Environments from '../../config/environments';
import { ViewCommentReply } from '../../config/interfaces/views';

const API_GATEWAY_URL = Environments.REACT_APP_API_GATEWAY_URL;

export const GetAssetReplies = async (
  access_token: string,
  view_id: string,
  asset_id: number
): Promise<ViewCommentReply[]> => {
  console.log(`fetching reply to asset [${asset_id}] of view [${view_id}]`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const response = await axios.get<ViewCommentReply[]>(
    `${API_GATEWAY_URL}/views/${view_id}/assets/${asset_id}/replies`,
    options
  );

  return response.data.reverse();
};

export const AddAssetReply = async (
  access_token: string,
  view_id: string,
  asset_id: number,
  body: ViewCommentReply
): Promise<ViewCommentReply[]> => {
  console.log(`adding reply to asset [${asset_id}] of view [${view_id}]`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const response = await axios.post<ViewCommentReply[]>(
    `${API_GATEWAY_URL}/views/${view_id}/assets/${asset_id}/replies`,
    body,
    options
  );

  return response.data.reverse();
};

export const DeleteAssetReply = async (
  access_token: string,
  view_id: string,
  asset_id: number,
  reply_id: string
): Promise<void> => {
  console.log(`deleting reply to asset [${asset_id}] of view [${view_id}]`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  return axios.delete(`${API_GATEWAY_URL}/views/${view_id}/assets/${asset_id}/replies/${reply_id}`, options);
};

export const EditAssetReply = async (
  access_token: string,
  view_id: string,
  asset_id: number,
  reply_id: string,
  reply_body: string
): Promise<void> => {
  console.log(`editing reply to asset [${asset_id}] of view [${view_id}]`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  return (
    await axios.patch<void>(
      `${API_GATEWAY_URL}/views/${view_id}/assets/${asset_id}/replies/${reply_id}`,
      { reply_body },
      options
    )
  ).data;
};

export const GetSharedAssetReplies = async (
  password: string,
  view_id: string,
  asset_id: number
): Promise<ViewCommentReply[]> => {
  console.log(`fetching reply to asset [${asset_id}] of shared view [${view_id}]`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { 'x-view-pw': password } };

  const response = await axios.get<ViewCommentReply[]>(
    `${API_GATEWAY_URL}/shared-views/${view_id}/assets/${asset_id}/replies`,
    options
  );

  return response.data.reverse();
};

export const AddSharedAssetReply = async (
  password: string,
  view_id: string,
  asset_id: number,
  body: ViewCommentReply
): Promise<ViewCommentReply[]> => {
  console.log(`adding reply to asset [${asset_id}] of shared view [${view_id}]`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { 'x-view-pw': password } };

  const response = await axios.post<ViewCommentReply[]>(
    `${API_GATEWAY_URL}/shared-views/${view_id}/assets/${asset_id}/replies`,
    body,
    options
  );

  return response.data.reverse();
};
