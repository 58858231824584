import {
  Alert,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  HStack,
  Input,
  Spacer,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  Cartesian2,
  Cartesian3,
  Cartographic,
  defined,
  Math as CesiumMath,
  sampleTerrain,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Viewer,
} from 'cesium';
import { clamp, cloneDeep, round } from 'lodash';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import {
  HEIGHT_INPUT_STEP,
  LATITUDE_INPUT_MAX,
  LATITUDE_INPUT_MIN,
  LATITUDE_INPUT_STEP,
  LONGITUDE_INPUT_MAX,
  LONGITUDE_INPUT_MIN,
  LONGITUDE_INPUT_STEP,
} from '../../../../../config/constants';
import { Position } from '../../../../../config/interfaces/views';
import { DrawImagePin } from '../../../../../services/Cesium/Viewer';

const PIN_ID = 'IMAGE_SELECT_POSITION';

export interface SelectPositionDialogForwardRef {
  openModal: (entityId?: string, position?: Position) => void;
  revert: () => void;
}

const HEIGHT_PRECISION = 2;
const COORDINATE_PRECISION = 7;

const SelectPositionDialogFunction: React.ForwardRefRenderFunction<
  SelectPositionDialogForwardRef,
  {
    cesiumViewer: Viewer | undefined;
    setManualPosition?: (newPosition: Position | null) => void;
    onSavePosition?: (newPosition: Position | null) => Promise<void>;
  }
> = ({ cesiumViewer, setManualPosition, onSavePosition }, componentRef) => {
  const [isShown, setIsShown] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [isGettingHeight, setIsGettingHeight] = useState(false);
  const [position, setPosition] = useState<Position | null>(null);
  const [cesiumScreenSpaceEventHandler, setCesiumScreenSpaceEventHandler] = useState<ScreenSpaceEventHandler>();

  const [editingEntityId, setEditingEntityId] = useState<string>();
  const [editingPosition, setEditingPosition] = useState<Position | null | undefined>();

  useImperativeHandle(componentRef, () => ({
    openModal(entityId?: string, inputPosition?: Position) {
      if (entityId) setEditingEntityId(entityId);
      if (inputPosition) {
        setEditingPosition(inputPosition);
        setPosition(inputPosition);
      }

      setIsShown(true);
      setupInputEvents();
    },

    revert() {
      if (isShown) cancel();
    },
  }));

  useEffect(() => {
    if (!cesiumViewer) return;

    setCesiumScreenSpaceEventHandler(new ScreenSpaceEventHandler(cesiumViewer.scene.canvas));
  }, [cesiumViewer]);

  const setupInputEvents = useCallback(() => {
    if (!cesiumScreenSpaceEventHandler || !cesiumViewer) return;

    cesiumScreenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
    cesiumScreenSpaceEventHandler.setInputAction((event: { position: Cartesian2 }) => {
      const ray = cesiumViewer.camera.getPickRay(event.position);
      const mousePosition = cesiumViewer.scene.globe.pick(ray, cesiumViewer.scene);

      if (mousePosition && defined(mousePosition)) {
        const cartographic = Cartographic.fromCartesian(mousePosition);
        const latitude = CesiumMath.toDegrees(cartographic.latitude);
        const longitude = CesiumMath.toDegrees(cartographic.longitude);

        setIsGettingHeight(true);
        void sampleTerrain(cesiumViewer.terrainProvider, 13, [cartographic]).then((res) => {
          setIsGettingHeight(false);
          setPosition({
            latitude: round(latitude, COORDINATE_PRECISION),
            longitude: round(longitude, COORDINATE_PRECISION),
            height: round(res[0]?.height || 0, HEIGHT_PRECISION),
            clamp_to_ground: false,
          });
        });
      }
    }, ScreenSpaceEventType.LEFT_CLICK);
  }, [cesiumViewer, cesiumScreenSpaceEventHandler]);

  useEffect(() => {
    if (!editingEntityId || !cesiumViewer) return;

    cesiumViewer.entities.removeById(editingEntityId);
  }, [cesiumViewer, editingEntityId, setupInputEvents]);

  useEffect(() => {
    if (!position || !cesiumViewer) return;

    if (editingEntityId) cesiumViewer.entities.removeById(editingEntityId);

    cesiumViewer.entities.removeById(PIN_ID);
    DrawImagePin(
      Cartesian3.fromDegrees(position.longitude, position.latitude, position.height),
      cesiumViewer,
      true,
      PIN_ID,
      PIN_ID,
      true,
      position.clamp_to_ground
    );
  }, [position, editingEntityId, cesiumViewer]);

  const reset = useCallback(() => {
    setIsShown(false);
    setEditingEntityId('');
    setPosition(null);
    setEditingPosition(null);

    if (cesiumViewer) cesiumViewer.entities.removeById(PIN_ID);
    if (cesiumScreenSpaceEventHandler) cesiumScreenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);
  }, [cesiumViewer, cesiumScreenSpaceEventHandler]);

  const cancel = useCallback(() => {
    if (editingPosition && editingEntityId && cesiumViewer) {
      cesiumViewer.entities.removeById(editingEntityId);
      DrawImagePin(
        Cartesian3.fromDegrees(editingPosition.longitude, editingPosition.latitude, editingPosition.height),
        cesiumViewer,
        false,
        editingEntityId,
        editingEntityId,
        true,
        editingPosition.clamp_to_ground
      );
    }

    if (setManualPosition) setManualPosition(null);
    if (onSavePosition) void onSavePosition(null);

    reset();
  }, [cesiumViewer, editingPosition, editingEntityId, setManualPosition, onSavePosition, reset]);

  const finish = useCallback(async () => {
    if (setManualPosition) {
      setManualPosition(position);
      reset();
    }

    if (onSavePosition) {
      setIsSaving(true);
      return onSavePosition(position)
        .then(() => reset())
        .catch((err) => {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError('保存に失敗しました。時間をおいてから再度お試しください。');
          }
        })
        .finally(() => setIsSaving(false));
    }

    return Promise.resolve();
  }, [position, setManualPosition, onSavePosition, reset]);

  return (
    <VStack display={isShown ? 'flex' : 'none'} position="fixed" top="2rem" width="100%">
      <Box maxW="md" bg="white" p="10px" borderRadius="5px" margin="auto">
        <VStack alignItems="left">
          <Text align="center">地点をクリックして位置を選択してください。</Text>
          <Divider />
          <VStack px="10px">
            <Flex w="100%">
              <Center w="80px">
                <FormLabel fontSize="xs">経度/緯度</FormLabel>
              </Center>
              <Box flex="1">
                <Flex>
                  <Input
                    type="number"
                    value={position?.longitude}
                    size="sm"
                    step={LONGITUDE_INPUT_STEP}
                    min={LONGITUDE_INPUT_MIN}
                    max={LONGITUDE_INPUT_MAX}
                    onChange={(e) => {
                      if (position) {
                        const parsedValue = e.target.value ? parseFloat(e.target.value) : 0;
                        const newPosition = cloneDeep(position);
                        newPosition.longitude = clamp(
                          round(parsedValue, COORDINATE_PRECISION),
                          LONGITUDE_INPUT_MIN,
                          LONGITUDE_INPUT_MAX
                        );
                        setPosition(newPosition);
                      }
                    }}
                  />
                  <Input
                    type="number"
                    value={position?.latitude}
                    size="sm"
                    step={LATITUDE_INPUT_STEP}
                    min={LATITUDE_INPUT_MIN}
                    max={LATITUDE_INPUT_MAX}
                    onChange={(e) => {
                      if (position) {
                        const parsedValue = e.target.value ? parseFloat(e.target.value) : 0;
                        const newPosition = cloneDeep(position);
                        newPosition.latitude = clamp(
                          round(parsedValue, COORDINATE_PRECISION),
                          LATITUDE_INPUT_MIN,
                          LATITUDE_INPUT_MAX
                        );
                        setPosition(newPosition);
                      }
                    }}
                  />
                </Flex>
              </Box>
            </Flex>
            <Flex w="100%">
              <Center w="80px">
                <FormLabel fontSize="xs">高さ</FormLabel>
              </Center>
              <Box flex="1">
                <VStack align="left">
                  <Input
                    type="number"
                    value={position?.height}
                    size="sm"
                    disabled={position?.clamp_to_ground}
                    step={HEIGHT_INPUT_STEP}
                    onChange={(e) => {
                      if (position) {
                        const parsedValue = e.target.value ? parseFloat(e.target.value) : 0;
                        const newPosition = cloneDeep(position);
                        newPosition.height = round(parsedValue, HEIGHT_PRECISION);
                        setPosition(newPosition);
                      }
                    }}
                  />
                </VStack>
              </Box>
            </Flex>
            <Flex w="100%">
              <Center w="80px" />
              <Box flex="1">
                <VStack align="left">
                  {position?.clamp_to_ground ? 'yes' : 'no'}
                  <Checkbox
                    size="sm"
                    isChecked={position?.clamp_to_ground}
                    onChange={(e) => {
                      if (position) {
                        const newPosition = cloneDeep(position);
                        newPosition.clamp_to_ground = e.target.checked;
                        setPosition(newPosition);
                      }
                    }}
                  >
                    地表上に固定
                  </Checkbox>
                </VStack>
              </Box>
            </Flex>
          </VStack>
          <Spacer />
          <HStack justifyContent="flex-end">
            <Button variant="outline" size="sm" onClick={cancel} disabled={isGettingHeight || isSaving}>
              キャンセル
            </Button>
            <Button
              colorScheme="primary"
              size="sm"
              onClick={finish}
              disabled={isGettingHeight || isSaving}
              isLoading={isSaving}
              loadingText="保存中"
            >
              保存
            </Button>
          </HStack>
        </VStack>
      </Box>
      <Alert display={isGettingHeight ? 'flex' : 'none'} status="info" maxW="md" margin="auto" borderRadius="5px">
        <Spinner mr="5px" size="sm" />
        <Text>位置情報を取得中...</Text>
      </Alert>
      {error && (
        <Alert display={isGettingHeight ? 'flex' : 'none'} status="error" maxW="md" margin="auto" borderRadius="5px">
          <Spinner mr="5px" size="sm" />
          <Text>{error}</Text>
        </Alert>
      )}
    </VStack>
  );
};

export const SelectPositionDialog = forwardRef(SelectPositionDialogFunction);
