// react modules
import { VStack } from '@chakra-ui/react';

// third party modules

// components
import { DistancePanelItem } from './PanelItem';

// services
import { _ } from '../../../../services/Util';
import { GetDistance } from '../../../../services/Cesium/Viewer';

// assets, config
import { ViewLayerPoints } from '../../../../config/interfaces/views';
import { INFO_PANEL_PADDING } from '../../../../config/styles';
import { DISTANCE_UNIT } from '../../../../config/constants';

export const DistancePanel: React.FC<{
  distanceLayersPoints: ViewLayerPoints[];
  updateVisibility: (index: number, visibility: boolean) => void;
  deleteLayer: (index: number) => void;
  focusedLayerIndex: number;
  setFocusedLayerIndex: (index: number) => void;
}> = ({ distanceLayersPoints, updateVisibility, deleteLayer, focusedLayerIndex, setFocusedLayerIndex }) => (
  <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} onMouseLeave={() => setFocusedLayerIndex(-1)}>
    {distanceLayersPoints.map((layerPoints, index) => (
      <DistancePanelItem
        key={layerPoints?.points.toString()}
        label={`${GetDistance(layerPoints?.points)}${DISTANCE_UNIT}`}
        visible={!layerPoints.invisible}
        deleteLayer={() => deleteLayer(index)}
        focused={index === focusedLayerIndex}
        setFocused={() => {
          setFocusedLayerIndex(index);
        }}
        updateVisibility={(newVisible) => {
          updateVisibility(index, newVisible);
        }}
      />
    ))}
  </VStack>
);
