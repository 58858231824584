import { Cartesian2, Cartesian3, Viewer } from 'cesium';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CesiumComponentRef } from 'resium';
import { View, ViewLayerPoints } from '../config/interfaces/views';
import useComment from './Comment';

const useToolbar = (
  cesiumViewRef: React.RefObject<CesiumComponentRef<Viewer>>,
  authToken: string | null,
  signedInUserId: string | undefined,
  forSharedView?: boolean
) => {
  const { view_id } = useParams<{ view_id: string }>();

  // distance, area, comment, image layers
  const [distanceLayersPoints, setDistanceLayersPoints] = useState<ViewLayerPoints[]>([]);
  const [areaLayersPoints, setAreaLayersPoints] = useState<ViewLayerPoints[]>([]);
  const [commentLayersPoints, setCommentLayersPoints] = useState<ViewLayerPoints[]>([]);
  const [imageLayersPoints, setImageLayersPoints] = useState<ViewLayerPoints[]>([]);
  // focused state
  const [focusedDistanceLayerIndex, setFocusedDistanceLayerIndex] = useState<number>(-1);
  const [focusedAreaLayerIndex, setFocusedAreaLayerIndex] = useState<number>(-1);
  const [focusedCommentLayerIndex, setFocusedCommentLayerIndex] = useState<number>(-1);
  const [selectedCommentLayerIndex, setSelectedCommentLayerIndex] = useState<number>(-1);
  // because deleting layer causes deleting entities, use these states to trigger delete on child component
  const [deletedDistanceLayerIndex, setDeletedDistanceLayerIndex] = useState<number>(-1);
  const [deletedAreaLayerIndex, setDeletedAreaLayerIndex] = useState<number>(-1);
  // comment states
  const [commentPopupAnchor, setCommentPopupAnchor] = useState<Cartesian2>();
  const [commentPopupPosition, setCommentPopupPosition] = useState<Cartesian3>();
  const [isCommentLayerLoading, setIsCommentLayerLoading] = useState(false);
  // camera events
  const [, setRemoveCameraMoveStartEvent] = useState<() => void>();
  const [, setRemoveCameraMoveEndEvent] = useState<() => void>();
  // toolbar hotkey
  const [isToolDisabled, setIsToolDisabled] = useState(false);

  // comment services base on authentication
  const { comments, fetchComments, deleteCommentLayer, onCommentReplyAmountChanged } = useComment(
    view_id,
    setCommentLayersPoints,
    setIsCommentLayerLoading,
    setCommentPopupAnchor,
    setSelectedCommentLayerIndex,
    authToken,
    signedInUserId,
    forSharedView
  );

  // images
  const fetchImages = useCallback(
    (view: View) => {
      console.log('fetch images');
      const imagesPoints: ViewLayerPoints[] = view.assets
        .filter(
          (asset) => asset.asset_settings.position && asset.asset_type === 'IMAGE' && asset.asset_settings.style.visible
        )
        .map(({ asset_id, file_name, asset_settings: { position } }) => ({
          id: `${asset_id}`, // convert number to string
          name: file_name,
          points: [
            Cartesian3.fromDegrees(
              position!.longitude,
              position!.latitude,
              position!.height
            ),
          ],
          clamp_to_ground: !!position?.clamp_to_ground
        }));

      setImageLayersPoints(imagesPoints);
    },
    [setImageLayersPoints]
  );

  // constrol modal ref
  const deleteCommentConfirmModalRef = useRef<{ openModal: (deleteCommentLayerIndex: number) => void }>();

  const updateDistanceLayerVisibility = (index: number, visibility: boolean) => {
    const newDistanceLayersPoints = [...distanceLayersPoints];
    const newLayerPoints = { ...newDistanceLayersPoints[index], invisible: !visibility };
    newDistanceLayersPoints[index] = newLayerPoints;
    setDistanceLayersPoints(newDistanceLayersPoints);
  };

  const updateAreaLayerVisibility = (index: number, visibility: boolean) => {
    const newAreaLayersPoints = [...areaLayersPoints];
    const newLayerPoints = { ...newAreaLayersPoints[index], invisible: !visibility };
    newAreaLayersPoints[index] = newLayerPoints;
    setAreaLayersPoints(newAreaLayersPoints);
  };

  const updateCommentLayerVisibility = (index: number, visibility: boolean) => {
    const newCommentLayersPoints = [...commentLayersPoints];
    const newLayerPoints = { ...newCommentLayersPoints[index], invisible: !visibility };
    newCommentLayersPoints[index] = newLayerPoints;
    setCommentLayersPoints(newCommentLayersPoints);
  };

  const updateSelectedCommentLayer = (index: number) => {
    setSelectedCommentLayerIndex(index);

    const cesiumViewerElement = cesiumViewRef?.current?.cesiumElement;

    if (cesiumViewerElement && index >= 0 && index < commentLayersPoints.length) {
      const point = commentLayersPoints[index].points[0];
      setCommentPopupAnchor(cesiumViewerElement.scene.cartesianToCanvasCoordinates(point));
      setCommentPopupPosition(point);
    } else {
      setCommentPopupPosition(undefined);
      setCommentPopupAnchor(undefined);
    }
  };

  const toggleCommentPopup = (position: Cartesian3 | undefined) => {
    setCommentPopupPosition(position);
    setSelectedCommentLayerIndex(-1);

    const cesiumViewerElement = cesiumViewRef?.current?.cesiumElement;
    if (position && cesiumViewerElement) {
      setCommentPopupAnchor(cesiumViewerElement.scene.cartesianToCanvasCoordinates(position));
      setCommentPopupPosition(position);
    } else {
      setCommentPopupAnchor(undefined);
      setCommentPopupPosition(undefined);
    }
  };

  const handleCameraMoveEnd = useCallback(() => {
    const cesiumViewerElement = cesiumViewRef?.current?.cesiumElement;
    if (commentPopupPosition && cesiumViewerElement) {
      setCommentPopupAnchor(cesiumViewerElement.scene.cartesianToCanvasCoordinates(commentPopupPosition));
    }
  }, [cesiumViewRef, commentPopupPosition]);

  // hide comment popup while camera is moving
  useEffect(() => {
    const cesiumViewerElement = cesiumViewRef?.current?.cesiumElement;

    if (cesiumViewerElement) {
      setRemoveCameraMoveStartEvent((removeListener) => {
        // remove the previous listener
        if (removeListener) {
          removeListener();
        }
        return cesiumViewerElement.camera.moveStart.addEventListener(() => {
          setCommentPopupAnchor(undefined);
        });
      });
      setRemoveCameraMoveEndEvent((removeListener) => {
        // remove the previous listener
        if (removeListener) {
          removeListener();
        }
        return cesiumViewerElement.camera.moveEnd.addEventListener(() => {
          handleCameraMoveEnd();
        });
      });
    }
  }, [cesiumViewRef, handleCameraMoveEnd]);

  useEffect(() => {
    void fetchComments();
  }, [view_id, fetchComments]);

  const onCommentModified = async () => {
    await fetchComments();
  };

  const openDeleteCommentConfirmModal = (index: number): void => deleteCommentConfirmModalRef.current?.openModal(index);

  return {
    // distance layers
    deletedDistanceLayerIndex,
    distanceLayersPoints,
    focusedDistanceLayerIndex,
    setDeletedDistanceLayerIndex,
    setDistanceLayersPoints,
    setFocusedDistanceLayerIndex,
    updateDistanceLayerVisibility,
    // area layers
    areaLayersPoints,
    deletedAreaLayerIndex,
    focusedAreaLayerIndex,
    setAreaLayersPoints,
    setDeletedAreaLayerIndex,
    setFocusedAreaLayerIndex,
    updateAreaLayerVisibility,
    // comment layers
    commentLayersPoints,
    commentPopupAnchor,
    commentPopupPosition,
    comments,
    deleteCommentConfirmModalRef,
    deleteCommentLayer,
    focusedCommentLayerIndex,
    isCommentLayerLoading,
    onCommentModified,
    openDeleteCommentConfirmModal,
    selectedCommentLayerIndex,
    setCommentPopupAnchor,
    setCommentPopupPosition,
    setFocusedCommentLayerIndex,
    setSelectedCommentLayerIndex,
    toggleCommentPopup,
    updateCommentLayerVisibility,
    updateSelectedCommentLayer,
    onCommentReplyAmountChanged,
    // image layers
    fetchImages,
    imageLayersPoints,
    // toollbar hotkey
    isToolDisabled,
    setIsToolDisabled,
  };
};

export default useToolbar;
