import { ToolbarCommentIcon } from '../../../../assets/icons/Index';
import { VIEWER_TOOLBAR_SHORTCUT } from '../../../../config/constants';
import ToolbarButton from '../_components/ToolbarButton';

const CommentTool: React.FC<{ isActive: boolean; numberOfComments: number; onClick: () => void }> = ({
  isActive,
  numberOfComments,
  onClick,
}) => (
  <ToolbarButton
    icon={<ToolbarCommentIcon />}
    label={`コメント(${VIEWER_TOOLBAR_SHORTCUT.COMMENT_TOOL[0]})`}
    isActive={isActive}
    onClick={onClick}
    showBadge={!!numberOfComments}
  />
);

export default CommentTool;
