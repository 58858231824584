import Cartesian2 from 'cesium/Source/Core/Cartesian2';
import Cartesian3 from 'cesium/Source/Core/Cartesian3';
import { useState, useCallback } from 'react';
import { ViewComment, ViewLayerPoints } from '../config/interfaces/views';
import { GetComments, DeleteComment, GetSharedComments } from '../services/View/View';

const useComment = (
  view_id: string | undefined,
  setCommentLayersPoints: (layersPoints: ViewLayerPoints[]) => void,
  setIsCommentLayerLoading: (loading: boolean) => void,
  setCommentPopupAnchor: (anchor: Cartesian2 | undefined) => void,
  setSelectedCommentLayerIndex: (index: number) => void,
  authToken: string | null,
  signedInUserId: string | undefined,
  forSharedView?: boolean
) => {
  const [comments, setComments] = useState<ViewComment[]>([]);
  const AuthGetComments = forSharedView ? GetSharedComments : GetComments;

  const fetchComments = useCallback(async () => {
    if (authToken !== null && view_id) {
      const allComments = await AuthGetComments(authToken, view_id);
      setComments(allComments);

      const commentPoints = allComments.map((comment) => ({
        points: [
          new Cartesian3(comment.cartesian_position_x, comment.cartesian_position_y, comment.cartesian_position_z),
        ],
        deletable: !!signedInUserId && signedInUserId === comment.user_id,
      }));
      setCommentLayersPoints(commentPoints);
    }
  }, [AuthGetComments, authToken, setCommentLayersPoints, signedInUserId, view_id]);

  const deleteCommentLayer = async (index: number) => {
    if (
      authToken === null ||
      !view_id ||
      !comments?.length ||
      index < 0 ||
      index >= comments.length ||
      !comments[index].comment_id
    ) {
      return;
    }
    setIsCommentLayerLoading(true);

    if (await DeleteComment(authToken, view_id, comments[index].comment_id || '')) {
      setCommentPopupAnchor(undefined);
      await fetchComments();
    }
    setIsCommentLayerLoading(false);
    setSelectedCommentLayerIndex(-1);
  };

  const onCommentReplyAmountChanged = (commentId: string, amount: number) => {
    const updatedComments = [...comments];
    const commentIndex = updatedComments.findIndex((comment) => comment.comment_id === commentId);

    if (commentIndex < 0) {
      return;
    }

    updatedComments[commentIndex] = {
      ...comments[commentIndex],
      reply_amount: Math.max(
        comments[commentIndex].reply_amount ? (comments[commentIndex].reply_amount as number) + amount : amount,
        0
      ),
    };

    setComments(updatedComments);
  };

  return {
    comments,
    fetchComments,
    deleteCommentLayer: forSharedView ? () => null : deleteCommentLayer,
    onCommentReplyAmountChanged,
  };
};

export default useComment;
