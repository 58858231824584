import { Link } from '@chakra-ui/react';
import axios from 'axios';
import {
  BoundingSphere,
  Cartesian2,
  Cartesian3,
  Cesium3DTileset,
  defined,
  Entity,
  GeoJsonDataSource,
  HeadingPitchRange,
  ImageryLayer,
  Ion,
  KmlDataSource,
  ScreenSpaceEventHandler,
  Viewer,
} from 'cesium';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CesiumComponentRef, Viewer as ResiumViewer } from 'resium';
import { LogoDark } from '../../assets/icons/Index';
import { INITIAL_GLOBE_FRONTFACE_ALPHA } from '../../config/constants';
import Environments from '../../config/environments';
import { Asset, AssetSettings, View as InterfaceView, ViewSettings } from '../../config/interfaces/views';
import useBoring from '../../hooks/Boring';
import useToolbar from '../../hooks/Toolbar';
import { GetPreSignUrlForDownloadAsset } from '../../services/AWS/S3';
import {
  AddTileSetsToScene,
  FlyToCameraXYZ,
  GetCenterPosition,
  InitCesiumViewer,
  InitPlateauBuildings,
  TransformTileSet,
  ZoomOut,
} from '../../services/Cesium/Viewer';
import { ProcessErrorHandler } from '../../services/ErrorHandler';
import { getFileInfo, _ } from '../../services/Util';
import { GetAssetIndex, GetSharedViewWithDefaultSetting } from '../../services/View/View';
import { ImageModal } from '../view/Image/ImageModal';
import { CommentPopup } from '../view/Toolbar/CommentTool/CommentPopup';
import { IMAGE_ICON_ID, Toolbar } from '../view/Toolbar/Toolbar';
import { InfoPanels } from './InfoPanels/InfoPanels';

// cesium 初期設定
Ion.defaultAccessToken = Environments.REACT_APP_CESIUM_ION_ACCESS_TOKEN;

export const Share: React.FC = () => {
  // common setting

  // cesium view settings
  const cesiumViewRef = useRef<CesiumComponentRef<Viewer>>(null);
  const view3DTileSetRefs = useRef<(Cesium3DTileset | KmlDataSource | GeoJsonDataSource | ImageryLayer | null)[]>([]);
  const osmBuildingsTileSetRef = useRef<Cesium3DTileset>();
  const plateauNoTextureBuildingsTileSetRefs = useRef<Cesium3DTileset[]>();
  const plateauTextureBuildingsTileSetRefs = useRef<Cesium3DTileset[]>();
  const [cesiumScreenSpaceEventHandler, setCesiumScreenSpaceEventHandler] = useState<ScreenSpaceEventHandler>();

  // view, asset settings
  const { view_id } = useParams<{ view_id: string }>();
  const [view, setView] = useState<InterfaceView>();

  // manage initial loading
  const [initialCompleted, setInitialCompleted] = useState(false);
  const [password, setPassword] = useState('');
  const [prepareForInitViewCompleted, setPrepareForInitViewCompleted] = useState(false);

  // boring
  const { borings, updateBoringVisibility } = useBoring(view_id, password, true);

  // image modal's asset ref
  const [imageModalAsset, setImageModalAsset] = useState<Asset | null>(null);
  const imageModalRef = useRef<{ openModal: (asset: Asset | null, view_id: string, entityId: string) => void }>();

  // toolbar
  const {
    // distance layers
    deletedDistanceLayerIndex,
    distanceLayersPoints,
    focusedDistanceLayerIndex,
    setDeletedDistanceLayerIndex,
    setDistanceLayersPoints,
    setFocusedDistanceLayerIndex,
    updateDistanceLayerVisibility,
    // area layers
    areaLayersPoints,
    deletedAreaLayerIndex,
    focusedAreaLayerIndex,
    setAreaLayersPoints,
    setDeletedAreaLayerIndex,
    setFocusedAreaLayerIndex,
    updateAreaLayerVisibility,
    // comment layers
    commentLayersPoints,
    commentPopupAnchor,
    commentPopupPosition,
    comments,
    // deleteCommentConfirmModalRef,
    // deleteCommentLayer,
    focusedCommentLayerIndex,
    isCommentLayerLoading,
    onCommentModified,
    onCommentReplyAmountChanged,
    // openDeleteCommentConfirmModal,
    selectedCommentLayerIndex,
    setCommentPopupAnchor,
    setCommentPopupPosition,
    setFocusedCommentLayerIndex,
    setSelectedCommentLayerIndex,
    toggleCommentPopup,
    updateCommentLayerVisibility,
    updateSelectedCommentLayer,
    // image layers
    fetchImages,
    imageLayersPoints,
    // toollbar hotkey
    isToolDisabled,
    setIsToolDisabled,
  } = useToolbar(cesiumViewRef, password, '', true);

  // when loaded asset from cesium ion, adjust 3DTileSet by view asset data
  const initialize3DTileSets = async (
    view3DTileSets: (Cesium3DTileset | KmlDataSource | GeoJsonDataSource | ImageryLayer | null)[],
    _view: InterfaceView
  ): Promise<{
    transformed3DTileSets: (Cesium3DTileset | KmlDataSource | GeoJsonDataSource | ImageryLayer | null)[] | null;
  }> => {
    if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement) {
      console.log('cesium viewer is not loaded yet');
      return { transformed3DTileSets: null };
    }

    const transformed3DTileSets = await Promise.all(
      view3DTileSets.map(async (view3DTileSet, index) => {
        // return, when 3D tileSet is not completed for convert
        if (!view3DTileSet) return null;
        if (!(view3DTileSet instanceof Cesium3DTileset)) {
          const deepCopyView = _.cloneDeep(_view);
          const asset = deepCopyView.assets[index];
          const { orientation, position, style } = asset.asset_settings;
          return TransformTileSet(view3DTileSet, position!, orientation, style);
        }

        const ts = await view3DTileSet.readyPromise.then((_tileSet) => {
          // deep copy
          const deepCopyView = _.cloneDeep(_view);
          const asset = deepCopyView.assets[index];
          const { orientation, position, style } = asset.asset_settings;

          // if no position, save position
          let assetPosition = position;
          if (!assetPosition) {
            console.log('this asset no position');

            // get center position from 3DTileSet, set to view state
            const centerPosition = GetCenterPosition(view3DTileSet);
            asset.asset_settings.position = centerPosition;
            assetPosition = centerPosition;
          }

          // set matrix4 to 3D tileSet
          const transformedView3DTileSet = TransformTileSet(_tileSet, assetPosition, orientation, style);
          return transformedView3DTileSet;
        });
        return ts;
      })
    );
    return { transformed3DTileSets };
  };

  const solveRequestResult = (_view: InterfaceView) => {
    const sharedView = _view;

    // show asset only completed tiling
    sharedView.assets = sharedView.assets.filter((asset) => asset.process_status === 'COMPLETE');

    // set view state
    console.log('set view');
    setView(sharedView);
  };

  useEffect(() => {
    // run it always, when update state
    void (async () => {
      if (!initialCompleted) {
        if (!view && view_id) {
          try {
            // try to request views without password
            const sharedView = await GetSharedViewWithDefaultSetting(view_id, '');
            solveRequestResult(sharedView);
            setPrepareForInitViewCompleted(true);
          } catch (err) {
            // if link has been disabled
            if (axios.isAxiosError(err) && err.response?.data === 'forbidden') {
              alert('このリンクは無効になっています');
              return;
            }
            if (axios.isAxiosError(err) && err.response?.data === 'view not found') {
              alert('ビューが存在しません');
              return;
            }

            // or else, try again with password
            const viewPassword = prompt('パスワードを入力してください');

            try {
              const viewsWithPassword = await GetSharedViewWithDefaultSetting(view_id, viewPassword || '');
              solveRequestResult(viewsWithPassword);
              setPassword(viewPassword || '');
              setPrepareForInitViewCompleted(true);
            } catch (errWithPassword) {
              setInitialCompleted(true);

              // パスワード間違えたエラーの表示
              if (axios.isAxiosError(err) && err.response?.data === 'wrong password') {
                alert('パスワードが間違っています');
                return;
              }

              ProcessErrorHandler(errWithPassword, 'loadView');
            }
          }
        } else {
          if (!prepareForInitViewCompleted) return;
          if (!view) return;
          if (osmBuildingsTileSetRef.current) return;
          if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement) {
            console.log('cesium viewer is not loaded yet');
            return;
          }

          try {
            // initialize cesium click event handler
            const cesiumViewerElement = cesiumViewRef.current.cesiumElement;
            setCesiumScreenSpaceEventHandler(new ScreenSpaceEventHandler(cesiumViewerElement.scene.canvas));

            // initialize cesium viewer

            const { view3DTileSets, osmBuildings3DTileSet } = await InitCesiumViewer(
              password,
              cesiumViewRef.current,
              view,
              true
            );

            // set osm building tile set to useRef
            osmBuildingsTileSetRef.current = osmBuildings3DTileSet;

            // transform 3D tileSet with view asset data
            const { transformed3DTileSets } = await initialize3DTileSets(view3DTileSets, view);
            if (!transformed3DTileSets) return;

            // add 3D tileSets to viewer scene
            await AddTileSetsToScene(cesiumViewRef.current, transformed3DTileSets);

            // zoom to first asset
            const firstAsset = transformed3DTileSets[0];
            if (firstAsset) {
              const { show } = firstAsset;
              if (!show) firstAsset.show = true;
              await ZoomOut(cesiumViewerElement, firstAsset, 10);
              firstAsset.show = show;
            }

            // set 3D tile sets to useRef
            view3DTileSetRefs.current = transformed3DTileSets;

            setInitialCompleted(true);
            console.log('completed initialize');
          } catch (err) {
            setInitialCompleted(true);
            ProcessErrorHandler(err, 'loadView');
          }
        }
      }
    })();
  }, [view, initialCompleted, view_id, password, prepareForInitViewCompleted]);

  // fetch images (the dataset, not actual image) after view is done and show popup once clicked
  useEffect(() => {
    // The empty return is needed for typescript consistency of the cleanup function
    if (!view || !initialCompleted || !cesiumViewRef.current?.cesiumElement)
      return () => {
        /* do nothing */
      };

    const cesiumElement = cesiumViewRef.current?.cesiumElement;

    fetchImages(view);

    const eventListener = (selectedEntity: any): void => {
      if (defined(selectedEntity) && selectedEntity instanceof Entity) {
        if (defined(selectedEntity.id)) {
          const imageAsset = view.assets.find(row => `${IMAGE_ICON_ID}.${row.asset_id}` === selectedEntity.id);

          if (imageAsset) {
            setImageModalAsset(imageAsset);
            imageModalRef.current?.openModal(imageAsset, view.view_id, selectedEntity.id);

            // Deselect immediately. This is to make sure user can re-select
            // the same image pin after closing the modal.
            cesiumElement.selectedEntity = undefined;
          }
        }
      }
    };

    // Add event listener when the image pin is selected/clicked so we can show the modal.
    cesiumElement.selectedEntityChanged.addEventListener(eventListener);

    return function cleanup() {
      cesiumElement.selectedEntityChanged.removeEventListener(eventListener);
    };
  }, [view, initialCompleted, cesiumViewRef, fetchImages]);

  /* asset control functions */

  // update view and 3DTileSet for the asset, when edited asset at info panel
  const editedAsset = (newAsset: Asset) => {
    if (!view) return;

    const assetIndex = GetAssetIndex(view, newAsset.asset_id);
    if (assetIndex === null) return;

    // update view
    const newView = _.cloneDeep(view);
    newView.assets[assetIndex] = newAsset;
    setView(newView);

    update3DTileSet(newAsset.asset_settings, assetIndex);
  };

  // download selected asset origin file
  const downloadAsset = async (asset: Asset) => {
    if (!view) return;

    const fileInfo = getFileInfo(asset.file_name);
    if (fileInfo) {
      const { contentType } = fileInfo;

      try {
        // get presigned url for selected asset
        const downloadUrl = await GetPreSignUrlForDownloadAsset(
          password,
          view.view_id,
          asset.asset_id,
          contentType,
          true
        );

        // download asset at new tab
        window.open(downloadUrl);
      } catch (err) {
        ProcessErrorHandler(err, 'downloadAsset');
      }
    } else {
      alert('ファイルが存在しません');
    }
  };

  /* cesium viewer control functions */

  // update asset style in cesium viewer
  const update3DTileSet = (assetSettings: AssetSettings, assetIndex: number) => {
    const { position, orientation, style } = assetSettings;
    const view3DTileSet = view3DTileSetRefs.current[assetIndex];
    if (!view3DTileSet) return;

    const transformedView3DTileSet = TransformTileSet(view3DTileSet, position!, orientation, style);
    view3DTileSetRefs.current[assetIndex] = transformedView3DTileSet;
  };

  const moveCamera = async (asset: Asset, vectorType: string) => {
    if (!view) return;

    // Since images are entities and we don't have access to the entity,
    // fly there manually
    if (asset.asset_type === 'IMAGE') {
      if (!asset.asset_settings.position) return;
      cesiumViewRef.current?.cesiumElement?.camera.flyTo({
        destination: Cartesian3.fromDegrees(
          asset.asset_settings.position.longitude,
          asset.asset_settings.position.latitude,
          asset.asset_settings.position.height + 1000.0
        ),
      });
      return;
    }

    const assetIndex = GetAssetIndex(view, asset.asset_id);
    if (assetIndex === null) return;
    const targetAsset = view3DTileSetRefs.current[assetIndex];
    if (!targetAsset) return;
    if (targetAsset instanceof Cesium3DTileset) {
      FlyToCameraXYZ(cesiumViewRef.current!, targetAsset, vectorType);
    } else {
      // Note: Hacky but it's due to a problem with cesiumJs itself.
      // https://github.com/CesiumGS/cesium/issues/4327
      // https://github.com/DataLabs-Japan/viewer-app/issues/127
      const { show } = targetAsset;
      if (!show) targetAsset.show = true;
      await cesiumViewRef.current?.cesiumElement?.zoomTo(targetAsset);
      cesiumViewRef.current?.cesiumElement?.camera.zoomOut(1);
      await cesiumViewRef.current?.cesiumElement?.flyTo(targetAsset, { duration: 0.8 });
      await cesiumViewRef.current?.cesiumElement?.zoomTo(targetAsset);
      targetAsset.show = show;
    }
  };

  const setCameraHeading = (heading: number) => {
    const scene = cesiumViewRef.current?.cesiumElement?.scene;
    const camera = cesiumViewRef.current?.cesiumElement?.camera;
    if (!camera || !scene) return;

    // get intersection of camera and globe
    const center = new Cartesian2(scene.canvas.clientWidth / 2.0, scene.canvas.clientHeight / 2.0);
    const ray = camera.getPickRay(center);
    const target = scene.globe.pick(ray, scene);
    if (!target) return;

    const range = Cartesian3.distance(target, camera.position);
    camera.flyToBoundingSphere(new BoundingSphere(target), {
      offset: new HeadingPitchRange(heading, camera.pitch, range),
      duration: 0.5,
    });
  };

  /* view control functions */

  const saveViewSettings = (newViewSettings: ViewSettings) => {
    if (!view) return;

    const newView = _.cloneDeep(view);
    const { show_osm_buildings, on_world_terrain, show_plateau_notexture_buildings, show_plateau_texture_buildings } =
      newViewSettings;
    // switch enable world terrain
    // SetWorldTerrain(cesiumViewRef.current!, on_world_terrain);
    // switch show osm buildings tile set
    osmBuildingsTileSetRef.current!.show = show_osm_buildings;
    // switch show plateau buildings tile set
    if (show_plateau_texture_buildings) {
      if (!view) return;
      if (!plateauTextureBuildingsTileSetRefs.current) {
        if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement) {
          console.log('cesium viewer is not loaded yet');
          return;
        }
        const plateauBuildings = InitPlateauBuildings(cesiumViewRef.current, view, '/texture/');
        plateauTextureBuildingsTileSetRefs.current = plateauBuildings;
      }
    }
    if (show_plateau_notexture_buildings) {
      if (!view) return;
      if (!plateauNoTextureBuildingsTileSetRefs.current) {
        if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement) {
          console.log('cesium viewer is not loaded yet');
          return;
        }
        const plateauBuildings = InitPlateauBuildings(cesiumViewRef.current, view, '/notexture/');
        plateauNoTextureBuildingsTileSetRefs.current = plateauBuildings;
      }
    }
    plateauTextureBuildingsTileSetRefs.current?.forEach((building, i) => {
      const newBuilding = building;
      newBuilding.show = show_plateau_texture_buildings;
      plateauTextureBuildingsTileSetRefs.current![i] = newBuilding;
    });
    plateauNoTextureBuildingsTileSetRefs.current?.forEach((building, i) => {
      const newBuilding = building;
      newBuilding.show = show_plateau_notexture_buildings;
      plateauNoTextureBuildingsTileSetRefs.current![i] = newBuilding;
    });

    newView.view_settings = newViewSettings;

    setView(newView);
  };

  const editGlobeTranslucency = (alpha: number) => {
    if (!cesiumViewRef.current || !cesiumViewRef.current.cesiumElement) {
      console.log('cesium viewer is not loaded yet');
      return;
    }
    const frontAlpha = alpha > INITIAL_GLOBE_FRONTFACE_ALPHA ? INITIAL_GLOBE_FRONTFACE_ALPHA : alpha;
    cesiumViewRef.current.cesiumElement.scene.globe.translucency.frontFaceAlpha = frontAlpha;
    cesiumViewRef.current.cesiumElement.scene.globe.translucency.backFaceAlpha = alpha;
  };

  return (
    // set overflow hidden not to add scroll bars when displays a comment popup
    <>
      <ResiumViewer
        ref={cesiumViewRef}
        homeButton={false}
        navigationHelpButton={false}
        timeline={false}
        animation={false}
        style={{
          position: 'relative',
          height: '100vh',
          cursor: focusedCommentLayerIndex >= 0 ? 'pointer' : 'default',
          overflow: 'hidden',
        }}
        selectionIndicator={false}
      >
        {view && (
          <>
            <InfoPanels
              borings={borings}
              view={view}
              cesiumViewerElement={cesiumViewRef.current!.cesiumElement!}
              // cesium viewer handler
              moveCameraHandler={moveCamera}
              // asset handler
              editedAssetHandler={editedAsset}
              downloadAssetHandler={downloadAsset}
              // view handler
              saveViewSettingsHandler={saveViewSettings}
              editGlobeTranslucencyHandler={editGlobeTranslucency}
              // distance layers
              distanceLayersPoints={distanceLayersPoints}
              focusedDistanceLayerIndex={focusedDistanceLayerIndex}
              setFocusedDistanceLayerIndex={setFocusedDistanceLayerIndex}
              deleteDistanceLayer={setDeletedDistanceLayerIndex}
              updateDistanceLayerVisibility={updateDistanceLayerVisibility}
              // area layers
              areaLayersPoints={areaLayersPoints}
              focusedAreaLayerIndex={focusedAreaLayerIndex}
              setFocusedAreaLayerIndex={setFocusedAreaLayerIndex}
              deleteAreaLayer={setDeletedAreaLayerIndex}
              updateAreaLayerVisibility={updateAreaLayerVisibility}
              // comment layers
              commentLayersPoints={commentLayersPoints}
              focusedCommentLayerIndex={focusedCommentLayerIndex}
              setFocusedCommentLayerIndex={setFocusedCommentLayerIndex}
              selectedCommentLayerIndex={selectedCommentLayerIndex}
              setSelectedCommentLayerIndex={updateSelectedCommentLayer}
              updateCommentLayerVisibility={updateCommentLayerVisibility}
              isCommentLayerLoading={isCommentLayerLoading}
              // boring handler
              updateBoringLayerVisibilityHandler={updateBoringVisibility}
              isSharedView
            />
            <Toolbar
              areaLayersPoints={areaLayersPoints}
              cesiumScreenSpaceEventHandler={cesiumScreenSpaceEventHandler}
              cesiumViewerElement={cesiumViewRef.current!.cesiumElement!}
              deletedAreaLayerIndex={deletedAreaLayerIndex}
              deletedDistanceLayerIndex={deletedDistanceLayerIndex}
              distanceLayersPoints={distanceLayersPoints}
              focusedAreaLayerIndex={focusedAreaLayerIndex}
              focusedDistanceLayerIndex={focusedDistanceLayerIndex}
              focusedCommentLayerIndex={focusedCommentLayerIndex}
              setSelectedCommentLayerIndex={updateSelectedCommentLayer}
              setFocusedCommentLayerIndex={setFocusedCommentLayerIndex}
              resetDeletedAreaLayerIndex={() => setDeletedAreaLayerIndex(-1)}
              resetDeletedDistanceLayerIndex={() => setDeletedDistanceLayerIndex(-1)}
              setAreaLayersPoints={setAreaLayersPoints}
              setDistanceLayersPoints={setDistanceLayersPoints}
              isCommentPopupOpen={!!commentPopupAnchor}
              isToolDisabled={isToolDisabled}
              toggleCommentPopup={toggleCommentPopup}
              commentLayersPoints={commentLayersPoints}
              imageLayersPoints={imageLayersPoints}
              isMovingPointCloudObject={false}
              forSharedView
              setCameraHeading={setCameraHeading}
            />
            <CommentPopup
              anchor={commentPopupAnchor}
              position={commentPopupPosition}
              closePopup={() => {
                setCommentPopupAnchor(undefined);
                setCommentPopupPosition(undefined);
                setSelectedCommentLayerIndex(-1);
              }}
              comment={comments?.[selectedCommentLayerIndex]}
              onCommentModified={onCommentModified}
              onCommentReplyAmountChanged={onCommentReplyAmountChanged}
              deleteComment={() => null}
              authToken={password}
              signedInUser={undefined}
              forSharedView
            />
            <ImageModal
              ref={imageModalRef}
              authToken={password}
              asset={imageModalAsset}
              setIsToolDisabled={setIsToolDisabled}
              cesiumViewer={cesiumViewRef.current?.cesiumElement}
              cesiumScreenSpaceEventHandler={cesiumScreenSpaceEventHandler}
              reloadView={() => Promise.resolve()}
              forSharedView
            />
          </>
        )}
        <Link
          href="/landing/index.html"
          target="_blank"
          _focus={{ boxShadow: 'none' }}
          position="absolute"
          bottom="40px"
          left={0}
        >
          <LogoDark height="28px" width="auto" />
        </Link>
      </ResiumViewer>
    </>
  );
};
