import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { User } from '@auth0/auth0-react';

import { FileInfo } from '../config/interfaces/util';
import { AddAsset, SaveAssetSettings } from './View/Asset';
import { Position, View } from '../config/interfaces/views';
import { CheckAddCrsStatus, CheckConvertToGeoJsonStatus, StartAddCrs, StartConvertToGeoJson } from './View/Crs';

export class FileUpload {
  file: File;

  fileInfo: FileInfo | null;

  crs: string;

  constructor(uploadFile: File, fileInfo: FileInfo | null, crs: string) {
    this.file = uploadFile;
    this.fileInfo = fileInfo;
    this.crs = crs;
  }

  /**
   * upload file for asset
   * @param upload_url signed url from GetSignedUrlForUploadAsset
   * @param onUploadProgress ProgressEvent
   */
  async upload(upload_url: string, onUploadProgress?: React.Dispatch<number> | null): Promise<void> {
    const options: AxiosRequestConfig = {
      responseType: 'json',
      headers: { 'Content-Type': this.fileInfo?.contentType || '' },
      // for display upload progress percentage
      onUploadProgress(progressEvent: ProgressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (onUploadProgress) onUploadProgress(percentCompleted);
      },
    };

    return axios.put(upload_url, this.file, options);
  }

  async postProcess(accessToken: string, view: View, user: User, position?: Position): Promise<void> {
    if (!user || !user.sub) {
      throw new Error('Invalid user');
    }

    let convertedUploadFileName = this.fileInfo!.filename;

    if (this.fileInfo) {
      if (new Set(['las', 'e57', 'ply', 'pts', 'xyz', 'xyzrgb']).has(this.fileInfo.extension.toLowerCase())) {
        const { executionArn } = await StartAddCrs(accessToken, user.sub, convertedUploadFileName, this.crs);

        await CheckAddCrsStatus(accessToken, executionArn);
        convertedUploadFileName = `${convertedUploadFileName.slice(0, -this.fileInfo.extension.length)}las`;
      } else if (this.fileInfo.extension.toLowerCase() === 'zip') {
        const { executionArn } = await StartConvertToGeoJson(accessToken, user.sub, convertedUploadFileName, this.crs);

        await CheckConvertToGeoJsonStatus(accessToken, executionArn);
        convertedUploadFileName = `${convertedUploadFileName.slice(0, -this.fileInfo.extension.length)}geojson`;
      }

      const newAsset = await AddAsset(accessToken, view.view_id, convertedUploadFileName, this.file.name);

      if (position) {
        await SaveAssetSettings(accessToken, view.view_id, newAsset.asset_id, { ...newAsset.asset_settings, position });
      }
    }

    return Promise.resolve();
  }
}
