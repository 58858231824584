import { User } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { Cartesian2, Cartesian3 } from 'cesium';
import React, { useEffect, useState } from 'react';
import Linkify from 'react-linkify';
import { useParams } from 'react-router-dom';

import { CancelIcon, ConfirmIcon, HDotsIcon } from '../../../../assets/icons/Index';

import { ViewComment, ViewCommentReply } from '../../../../config/interfaces/views';
import useCommentPopup from '../../../../hooks/CommentPopup';

export const CommentPopup: React.FC<{
  comment: ViewComment | undefined;
  anchor: Cartesian2 | undefined;
  position: Cartesian3 | undefined;
  closePopup: () => void;
  onCommentModified: () => void;
  onCommentReplyAmountChanged: (commentId: string, amount: number) => void;
  deleteComment: () => void;
  authToken: string | null;
  signedInUser: User | undefined;
  forSharedView: boolean | undefined;
}> = ({
  comment,
  anchor,
  position,
  closePopup,
  onCommentModified,
  onCommentReplyAmountChanged,
  deleteComment,
  authToken,
  signedInUser,
  forSharedView,
}) => {
  const { view_id } = useParams<{ view_id: string }>();

  const [name, setName] = useState('');
  const [body, setBody] = useState('');
  const [showCommentEditInputs, setShowCommentEditInputs] = useState(false);
  const [showInputs, setShowInputs] = useState(true);
  const [editingReplyIndex, setEditingReplyIndex] = useState(-1);
  const [loading, setLoading] = useState(false);

  const closeEditBody = () => {
    setShowCommentEditInputs(false);
    setEditingReplyIndex(-1);
  };

  const { replies, setReplies, editBody, deleteReply, fetchReplies, addReply, addComment } = useCommentPopup(
    body,
    closeEditBody,
    closePopup,
    comment?.comment_id || '',
    editingReplyIndex,
    name,
    onCommentModified,
    onCommentReplyAmountChanged,
    position,
    setLoading,
    setShowInputs,
    showCommentEditInputs,
    view_id,
    authToken,
    forSharedView
  );

  useEffect(() => {
    setName(signedInUser?.nickname || '');
    setBody('');
    setLoading(false);
    setShowInputs(!comment);
    setShowCommentEditInputs(false);
    setEditingReplyIndex(-1);
  }, [comment, anchor, signedInUser?.nickname]);

  useEffect(() => {
    if (showCommentEditInputs && comment) {
      setName(comment.author_name);
      setBody(comment.comment_body);
    } else {
      setName(signedInUser?.nickname || '');
      setBody('');
    }
  }, [showCommentEditInputs, comment, signedInUser?.nickname]);

  useEffect(() => {
    if (editingReplyIndex >= 0) {
      setName(replies[editingReplyIndex].author_name);
      setBody(replies[editingReplyIndex].reply_body);
    } else {
      setName(signedInUser?.nickname || '');
      setBody('');
    }
  }, [editingReplyIndex, replies, signedInUser?.nickname]);

  useEffect(() => {
    setReplies([]);
  }, [comment, setReplies, view_id]);

  // To prevent fade out animation from anchor (0, 0)
  if (!anchor) {
    return null;
  }

  const adjustedAnchorPosition = {
    left: anchor.x - 12,
    top: anchor.y,
  };

  const renderBody = (
    id: string | undefined,
    nameString: string,
    bodyString: string,
    user_id: string | undefined,
    updated_at: number | undefined,
    handleEdit: () => void,
    handleDelete: () => void
  ) => {
    const date = updated_at ? new Date(updated_at) : null;
    const timestamp = date ? date.toLocaleString('en-ZA', { hour12: false }) : '';
    return (
      <Flex key={id} flexDirection="column" width="100%">
        <HStack alignItems="center">
          <HStack color="secondary.400" fontWeight="bold" alignItems="baseline">
            <Text as="span" marginBottom={-0.5}>
              {nameString}
            </Text>
            <Text fontSize="80%">{timestamp}</Text>
          </HStack>
          <Spacer />
          {!forSharedView && user_id && user_id === signedInUser?.sub && (
            <Menu>
              <MenuButton
                variant="ghost"
                as={IconButton}
                aria-label="Actions"
                fontSize="md"
                size="xs"
                icon={<HDotsIcon />}
                disabled={loading}
              />
              <MenuList>
                <MenuItem onClick={handleEdit}>編集</MenuItem>
                <MenuItem onClick={handleDelete}>削除</MenuItem>
              </MenuList>
            </Menu>
          )}
        </HStack>
        <Linkify
          componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
            <Link isExternal variant="underline" key={key} href={decoratedHref}>
              {decoratedHref}
            </Link>
          )}
        >
          <Text whiteSpace="pre-wrap">{bodyString}</Text>
        </Linkify>
      </Flex>
    );
  };

  const renderInputBody = (forEdit?: boolean, key?: string) => (
    <React.Fragment key={key}>
      {forEdit && (
        <Text color="secondary.400" fontWeight="bold" as="span" marginBottom={-0.5} alignSelf="flex-start">
          {name}
        </Text>
      )}
      {!forEdit && (
        <Input
          size="sm"
          placeholder="名前"
          value={name}
          onChange={(e) => setName(e.target.value)}
          maxLength={30}
          disabled={loading}
        />
      )}
      <Textarea
        size="sm"
        placeholder="コメント"
        resize="vertical"
        value={body}
        onChange={(e) => setBody(e.target.value)}
        maxLength={300}
        disabled={loading}
      />
      {forEdit && (
        <HStack alignSelf="flex-end">
          <IconButton
            size="xs"
            variant="outline"
            aria-label="Cancel Edit"
            fontSize="sm"
            icon={<CancelIcon />}
            disabled={loading}
            onClick={closeEditBody}
          />
          <IconButton
            size="xs"
            variant="outline"
            aria-label="Confirm Edit"
            fontSize="sm"
            icon={<ConfirmIcon />}
            disabled={loading || !body}
            onClick={editBody}
          />
        </HStack>
      )}
    </React.Fragment>
  );

  const renderCommentBody = (_comment: ViewComment) => {
    if (showCommentEditInputs) {
      return renderInputBody(true);
    }
    return renderBody(
      _comment.comment_id,
      _comment.author_name,
      _comment.comment_body,
      _comment.user_id,
      _comment.updated_at,
      () => {
        setShowCommentEditInputs(true);
      },
      () => {
        deleteComment();
      }
    );
  };

  const renderReplyBody = (_reply: ViewCommentReply, index: number) => {
    if (index === editingReplyIndex) {
      return renderInputBody(true, _reply.reply_id);
    }
    return renderBody(
      _reply.reply_id,
      _reply.author_name,
      _reply.reply_body,
      _reply.user_id,
      _reply.updated_at,
      () => {
        setEditingReplyIndex(index);
      },
      () => {
        void deleteReply(index);
      }
    );
  };

  return (
    <Popover placement="top-start" isOpen>
      {/* Just for positioning the popover, trigger event will be handled by toolbar */}
      <PopoverTrigger>
        <Box style={{ ...adjustedAnchorPosition, position: 'absolute', width: 0, height: 0 }} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <VStack style={{ marginLeft: -4, marginRight: -4 }}>
            {!!comment && renderCommentBody(comment)}
            {!replies.length && comment?.reply_amount && (
              <Button
                variant="outline"
                size="sm"
                onClick={() => fetchReplies()}
                alignSelf="flex-start"
                disabled={loading}
              >
                {comment.reply_amount}件の返信を表示
              </Button>
            )}
            {replies.length && replies.map((reply, index) => renderReplyBody(reply, index))}
            {showInputs && renderInputBody()}
            <HStack alignSelf="stretch">
              {!!comment && !showInputs && (
                <Button colorScheme="primary" size="sm" onClick={() => setShowInputs(true)} disabled={loading}>
                  返信
                </Button>
              )}
              {!!comment && showInputs && (
                <Button colorScheme="primary" size="sm" onClick={() => addReply()} disabled={loading || !name || !body}>
                  返信を送る
                </Button>
              )}
              <Spacer />
              <Button variant="outline" size="sm" onClick={closePopup} disabled={loading}>
                {comment ? '閉じる' : 'キャンセル'}
              </Button>
              {!comment && (
                <Button colorScheme="primary" size="sm" onClick={addComment} disabled={loading || !name || !body}>
                  追加
                </Button>
              )}
            </HStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
