// react modules

// third party modules
import axios, { AxiosRequestConfig } from 'axios';

// assets, config
import { environments } from '../../config/environments';
import {
  Boring,
  Position,
  View,
  ViewComment,
  ViewCommentReply,
  ViewOutline,
  ViewSettings,
  ViewShareLink,
} from '../../config/interfaces/views';

// services

const API_GATEWAY_URL = environments.REACT_APP_API_GATEWAY_URL;

/**
 * get views
 * @param access_token
 * @returns View[]
 */
export const GetViews = async (access_token: string): Promise<ViewOutline[]> => {
  console.log(`get views`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const getViewsRes = await axios.get<ViewOutline[]>(`${API_GATEWAY_URL}/views`, options);

  return getViewsRes.data;
};

/**
 * get view
 * @param access_token
 * @param view_id
 * @returns View
 */
export const GetView = async (access_token: string, view_id: string): Promise<View> => {
  console.log(`get view`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const getViewRes = await axios.get<View>(`${API_GATEWAY_URL}/views/${view_id}`, options);

  // Before asset_type was added, asset_type was not set and there were only 3DTILES assets.
  getViewRes.data.assets.forEach((asset) => {
    /* eslint-disable no-param-reassign */
    asset.asset_type = asset.asset_type ? asset.asset_type : '3DTILES';
  });

  return getViewRes.data;
};

/**
 * get view with default setting
 * @param access_token
 * @param view_id
 * @returns View
 */
export const GetViewWithDefaultSetting = async (access_token: string, view_id: string): Promise<View> => {
  console.log(`get view`);

  const getViewResData = await GetView(access_token, view_id);

  // init show buildings with false for now
  // In future, if the DB doesn't have the elements, init with false and store to db when the setting is changed.
  // init like:
  // if (!getViewResData.view_settings.show_plateau_notexture_buildings) {
  //   getViewResData.view_settings.show_plateau_notexture_buildings = false;
  // }
  getViewResData.view_settings.show_osm_buildings = false;
  getViewResData.view_settings.show_plateau_notexture_buildings = false;
  getViewResData.view_settings.show_plateau_texture_buildings = false;

  return getViewResData;
};

/**
 * create view
 * @param access_token
 * @param view_name
 * @returns View object
 */
export const CreateView = async (access_token: string, view_name: string): Promise<View> => {
  console.log('create view');

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  const createViewRes = await axios.post<View>(`${API_GATEWAY_URL}/views`, { view_name }, options);

  return createViewRes.data;
};

/**
 * delete view
 * @param access_token
 * @param view_id
 */
export const DeleteView = async (access_token: string, view_id: string): Promise<void> => {
  console.log('delete view');

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  await axios.delete(`${API_GATEWAY_URL}/views/${view_id}`, options);
};

/**
 * get shared view
 * @param view_id
 * @param password
 * @returns View object
 */
export const GetSharedView = async (view_id: string, password: string): Promise<View> => {
  console.log(`get shared view`);

  const options: AxiosRequestConfig = { responseType: 'json', headers: { 'x-view-pw': password } };

  const getSharedViewRes = await axios.get<View>(`${API_GATEWAY_URL}/shared-views/${view_id}`, options);

  // Before asset_type was added, asset_type was not set and there were only 3DTILES assets.
  getSharedViewRes.data.assets.forEach((asset) => {
    /* eslint-disable no-param-reassign */
    asset.asset_type = asset.asset_type ? asset.asset_type : '3DTILES';
  });

  return getSharedViewRes.data;
};
/**
 * get shared view with default setting
 * @param view_id
 * @param password
 * @returns View object
 */
export const GetSharedViewWithDefaultSetting = async (view_id: string, password: string): Promise<View> => {
  console.log(`get shared view`);

  const getSharedViewResData = await GetSharedView(view_id, password);

  // init show buildings with false for now
  // In future, if the DB doesn't have the elements, init with false and store to db when the setting is changed.
  // init like:
  // if (!getViewResData.view_settings.show_plateau_notexture_buildings) {
  //   getViewResData.view_settings.show_plateau_notexture_buildings = false;
  // }
  getSharedViewResData.view_settings.show_osm_buildings = false;
  getSharedViewResData.view_settings.show_plateau_notexture_buildings = false;
  getSharedViewResData.view_settings.show_plateau_texture_buildings = false;

  return getSharedViewResData;
};

/**
 * save view settings
 * @param access_token
 * @param view_id
 * @param view_settings
 */
export const SaveViewSettings = async (
  access_token: string,
  view_id: string,
  view_settings: ViewSettings
): Promise<void> => {
  console.log('save view settings');

  const options: AxiosRequestConfig = { responseType: 'json', headers: { Authorization: `Bearer ${access_token}` } };

  await axios.put(`${API_GATEWAY_URL}/views/${view_id}/view-settings`, view_settings, options);
};

/**
 * save share link settings for view
 * @param access_token
 * @param view_share_link ViewShareLink object
 */
export const SaveViewShareLink = async (access_token: string, view_share_link: ViewShareLink): Promise<void> => {
  const { view_id, password, is_share } = view_share_link;
  console.log('save view share link');
  await axios.put(
    `${API_GATEWAY_URL}/views/${view_id}/password`,
    { password, is_share },
    {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
};

/**
 * get asset index by asset_id from view.assets array
 * @param view
 * @param asset_id
 * @return asset index or not match is null
 */
export const GetAssetIndex = (view: View, asset_id: number): number | null => {
  const { assets } = view;
  const index = assets.findIndex((asset) => asset.asset_id === asset_id);
  if (index === -1) return null;
  return index;
};

/**
 * get offset height of asset
 * @param access_token
 * @param position
 * @return height
 */
export const GetOffsetHeight = async (access_token: string, position: Position): Promise<number> => {
  console.log('get offset height');
  const response = await axios.get<{ height: number }>(
    `${API_GATEWAY_URL}/geoid-height?lat=${position.latitude}&lon=${position.longitude}&ellipsoid=WGS84`,
    {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
  return response.data.height;
};

/**
 * get view's comments
 * @param access_token
 * @param view_id
 * @return all comments
 */
export const GetComments = async (access_token: string, view_id: string): Promise<ViewComment[]> => {
  console.log('get comments');
  const response = await axios.get<ViewComment[]>(`${API_GATEWAY_URL}/views/${view_id}/comments`, {
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return response.data.reverse();
};

/**
 * add view's comment
 * @param access_token
 * @param view_id
 * @param comment
 * @return success or not
 */
export const AddComment = async (access_token: string, view_id: string, comment: ViewComment): Promise<boolean> => {
  console.log('add comment');
  const result = await axios
    .post(
      `${API_GATEWAY_URL}/views/${view_id}/comments`,
      { ...comment },
      {
        responseType: 'json',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then(() => true)
    .catch(() => false);

  return result;
};

/**
 * edit view's comment
 * @param access_token
 * @param view_id
 * @param comment_id
 * @param comment_body
 * @return success or not
 */
export const EditComment = async (
  access_token: string,
  view_id: string,
  comment_id: string,
  comment_body: string
): Promise<boolean> => {
  console.log('edit comment');
  const result = await axios
    .patch(
      `${API_GATEWAY_URL}/views/${view_id}/comments/${comment_id}`,
      { comment_body },
      {
        responseType: 'json',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then(() => true)
    .catch(() => false);

  return result;
};

/**
 * delete view's comment
 * @param access_token
 * @param view_id
 * @param comment_id
 * @return success or not
 */
export const DeleteComment = async (access_token: string, view_id: string, comment_id: string): Promise<boolean> => {
  console.log('delete comment');
  const result = await axios
    .delete(`${API_GATEWAY_URL}/views/${view_id}/comments/${comment_id}`, {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(() => true)
    .catch(() => false);

  return result;
};

/**
 * get view's comments
 * @param access_token
 * @param view_id
 * @return all comments
 */
export const GetCommentReplies = async (
  access_token: string,
  view_id: string,
  comment_id: string
): Promise<ViewCommentReply[]> => {
  console.log('get comment replies');
  const response = await axios.get<ViewCommentReply[]>(
    `${API_GATEWAY_URL}/views/${view_id}/comments/${comment_id}/replies`,
    {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
  return response.data.reverse();
};

/**
 * add view's comment reply
 * @param access_token
 * @param view_id
 * @param comment_id
 * @param reply
 * @return success or not
 */
export const AddCommentReply = async (
  access_token: string,
  view_id: string,
  comment_id: string,
  reply: ViewCommentReply
): Promise<boolean> => {
  console.log('add comment reply');
  const result = await axios
    .post(
      `${API_GATEWAY_URL}/views/${view_id}/comments/${comment_id}/replies`,
      { ...reply },
      {
        responseType: 'json',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then(() => true)
    .catch(() => false);

  return result;
};

/**
 * edit view's comment
 * @param access_token
 * @param view_id
 * @param comment_id
 * @param reply_id
 * @param reply_body
 * @return success or not
 */
export const EditCommentReply = async (
  access_token: string,
  view_id: string,
  comment_id: string,
  reply_id: string,
  reply_body: string
): Promise<boolean> => {
  console.log('edit comment reply');
  const result = await axios
    .patch(
      `${API_GATEWAY_URL}/views/${view_id}/comments/${comment_id}/replies/${reply_id}`,
      { reply_body },
      {
        responseType: 'json',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then(() => true)
    .catch(() => false);

  return result;
};

/**
 * delete view's comment reply
 * @param access_token
 * @param view_id
 * @param comment_id
 * @param reply_id
 * @return success or not
 */
export const DeleteCommentReply = async (
  access_token: string,
  view_id: string,
  comment_id: string,
  reply_id: string
): Promise<boolean> => {
  console.log('delete comment reply');
  const result = await axios
    .delete(`${API_GATEWAY_URL}/views/${view_id}/comments/${comment_id}/replies/${reply_id}`, {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(() => true)
    .catch(() => false);

  return result;
};

/**
 * get shared view's comments
 * @param password
 * @param view_id
 * @return all comments
 */
export const GetSharedComments = async (password: string, view_id: string): Promise<ViewComment[]> => {
  console.log('get shared comments');
  const response = await axios.get<ViewComment[]>(`${API_GATEWAY_URL}/shared-views/${view_id}/comments`, {
    responseType: 'json',
    headers: {
      'x-view-pw': password,
    },
  });
  return response.data.reverse();
};

/**
 * add shared view's comment
 * @param password
 * @param view_id
 * @param comment
 * @return success or not
 */
export const AddSharedComment = async (password: string, view_id: string, comment: ViewComment): Promise<boolean> => {
  console.log('add shared comment');
  const result = await axios
    .post(
      `${API_GATEWAY_URL}/shared-views/${view_id}/comments`,
      { ...comment },
      {
        responseType: 'json',
        headers: {
          'x-view-pw': password,
        },
      }
    )
    .then(() => true)
    .catch(() => false);

  return result;
};

/**
 * get shared view's comments
 * @param password
 * @param view_id
 * @return all comments
 */
export const GetSharedCommentReplies = async (
  password: string,
  view_id: string,
  comment_id: string
): Promise<ViewCommentReply[]> => {
  console.log('get shared comment replies');
  const response = await axios.get<ViewCommentReply[]>(
    `${API_GATEWAY_URL}/shared-views/${view_id}/comments/${comment_id}/replies`,
    {
      responseType: 'json',
      headers: {
        'x-view-pw': password,
      },
    }
  );
  return response.data.reverse();
};

/**
 * add shared view's comment reply
 * @param password
 * @param view_id
 * @param comment_id
 * @param reply
 * @return success or not
 */
export const AddSharedCommentReply = async (
  password: string,
  view_id: string,
  comment_id: string,
  reply: ViewCommentReply
): Promise<boolean> => {
  console.log('add shared comment reply');
  const result = await axios
    .post(
      `${API_GATEWAY_URL}/shared-views/${view_id}/comments/${comment_id}/replies`,
      { ...reply },
      {
        responseType: 'json',
        headers: {
          'x-view-pw': password,
        },
      }
    )
    .then(() => true)
    .catch(() => false);

  return result;
};

/**
 * add boring
 * @param access_token
 * @param view_id
 * @param borings
 * @return borings
 */
export const AddBoring = async (access_token: string, view_id: string, borings: Boring[]): Promise<Boring[]> => {
  console.log('add Borings');
  const result = await axios.post<Boring[]>(
    `${API_GATEWAY_URL}/views/${view_id}/borings`,
    { borings },
    {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );

  return result.data;
};

/**
 * delete boring
 * @param access_token
 * @param view_id
 * @param index
 * @return borings
 */
export const DeleteBoring = async (access_token: string, view_id: string, index: number): Promise<Boring[]> => {
  console.log('delete boring');
  const result = await axios.delete<Boring[]>(`${API_GATEWAY_URL}/views/${view_id}/borings/index/${index}`, {
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  return result.data;
};

/**
 * get boring
 * @param access_token
 * @param view_id
 * @param borings
 * @return borings
 */
export const GetBorings = async (access_token: string, view_id: string): Promise<Boring[]> => {
  console.log('get boring');
  const response = await axios.get<Boring[]>(`${API_GATEWAY_URL}/views/${view_id}/borings`, {
    responseType: 'json',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  return response.data;
};

/**
 * get shared borings
 * @param password
 * @param view_id
 * @return borings
 */
export const GetSharedBorings = async (password: string, view_id: string): Promise<Boring[]> => {
  console.log('get boring for share page');
  const response = await axios.get<Boring[]>(`${API_GATEWAY_URL}/shared-views/${view_id}/borings`, {
    responseType: 'json',
    headers: {
      'x-view-pw': password,
    },
  });

  return response.data;
};
