// react modules
import { useImperativeHandle, useState, useRef } from 'react';
import { forwardRef } from '@chakra-ui/react';

import { Modal } from '../_components/Modal';

// 削除確認モーダルコンポーネント
const DeleteConfirmModalFunction: React.ForwardRefRenderFunction<
  // 親コンポーネントでの呼び出し用ファンクション群定義
  {
    openModal: (assetId: number) => void;
  },
  // 親コンポーネントから伝搬される変数・ファンクション群定義
  {
    deleteAssetHandler: (assetId: number) => void;
  }
> = ({ deleteAssetHandler }, ref) => {
  // 削除対象のasset index保持用変数
  const [assetId, setAssetId] = useState<number>(0);

  // 親コンポーネントから呼び出されるファンクション群の内部処理
  useImperativeHandle(ref, () => ({
    openModal(_deleteAssetId: number) {
      setAssetId(_deleteAssetId);
      modalRef.current?.openModal();
    },
  }));

  const modalRef = useRef<{
    openModal: () => void;
  }>();

  return (
    <>
      <Modal
        ref={modalRef}
        headerTitle="データを削除しますか？"
        okText="削除"
        okColorScheme="red"
        okClickHandler={() => deleteAssetHandler(assetId)}
      >
        一度削除してしまうと、元に戻せません。
      </Modal>
    </>
  );
};

export const DeleteConfirmModal = forwardRef(DeleteConfirmModalFunction);
