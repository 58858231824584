// upload file settings
export const UPLOAD_LIMIT_FILE_SIZE_BYTE = 1024 ** 3 * 5; // 5GB
export const UPLOAD_LIMIT_FILE_SIZE_GIGABYTE = 5; // 5GB

// longitude settings
export const LONGITUDE_INPUT_MIN = -180;
export const LONGITUDE_INPUT_MAX = 180;
export const LONGITUDE_INPUT_STEP = 0.0000001;

// latitude settings
export const LATITUDE_INPUT_MIN = -90;
export const LATITUDE_INPUT_MAX = 90;
export const LATITUDE_INPUT_STEP = 0.0000001;

// height settings
export const HEIGHT_INPUT_STEP = 0.01;

// cartesian settings
export const CARTESIAN_X_INPUT_STEP = 0.01;
export const CARTESIAN_Y_INPUT_STEP = 0.01;

// roll settings
export const ROLL_INPUT_MIN = -180;
export const ROLL_INPUT_MAX = 180;
export const ROLL_INPUT_STEP = 0.1;

// pitch settings
export const PITCH_INPUT_MIN = -180;
export const PITCH_INPUT_MAX = 180;
export const PITCH_INPUT_STEP = 0.1;

// yaw settings
export const YAW_INPUT_MIN = -180;
export const YAW_INPUT_MAX = 180;
export const YAW_INPUT_STEP = 0.1;

// share link password settings
export const SHARE_LINK_PASSWORD_MAX_LENGTH = 30;

// viewer toolbar
export const VIEWER_TOOLS = {
  MOVE: 'MOVE',
  DISTANCE: 'DISTANCE',
  AREA: 'AREA',
  COMMENT: 'COMMENT',
};

export const VIEWER_TOOLBAR_SHORTCUT = {
  CONFIRM: ['Enter'],
  CANCEL: ['Escape'],
  MOVE_TOOL: ['M', 'm'],
  DISTANCE_TOOL: ['D', 'd'],
  AREA_TOOL: ['A', 'a'],
  COMMENT_TOOL: ['C', 'c'],
};

// measure tool
export const MEASURE_NUMBER_DECIMAL = 2; // eg. 15.045 -> 15.05m, 15.05m2
export const DISTANCE_UNIT = 'm';
export const AREA_UNIT = '㎡';

// initial globe translucency
export const INITIAL_GLOBE_FRONTFACE_ALPHA = 0.6;
