// react modules
import { VStack } from '@chakra-ui/react';

// third party modules

// components
import { CommentPanelItem } from './PanelItem';

// services

// assets, config
import { ViewLayerPoints } from '../../../../config/interfaces/views';
import { INFO_PANEL_PADDING } from '../../../../config/styles';

export const CommentPanel: React.FC<{
  commentLayersPoints: ViewLayerPoints[];
  updateVisibility: (index: number, visibility: boolean) => void;
  deleteLayer: (index: number) => void;
  focusedLayerIndex: number;
  setFocusedLayerIndex: (index: number) => void;
  selectedLayerIndex: number;
  setSelectedLayerIndex: (index: number) => void;
  loading: boolean;
}> = ({
  commentLayersPoints,
  updateVisibility,
  deleteLayer,
  focusedLayerIndex,
  setFocusedLayerIndex,
  selectedLayerIndex,
  setSelectedLayerIndex,
  loading,
}) => (
  <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} onMouseLeave={() => setFocusedLayerIndex(-1)}>
    {commentLayersPoints.map((layerPoints, index) => (
      <CommentPanelItem
        key={layerPoints?.points.toString()}
        label={`コメント_${(index + 1).toString().padStart(4, '0')}`}
        visible={!layerPoints.invisible}
        deleteLayer={() => deleteLayer(index)}
        focused={index === focusedLayerIndex}
        selected={index === selectedLayerIndex}
        setFocused={() => {
          setFocusedLayerIndex(index);
        }}
        setSelected={() => setSelectedLayerIndex(index)}
        updateVisibility={(newVisible) => {
          updateVisibility(index, newVisible);
        }}
        loading={loading}
        deletable={!!layerPoints.deletable}
      />
    ))}
  </VStack>
);
