import { readAndCompressImage } from 'browser-image-resizer';
import ExifReader from 'exif-reader';
import { Position } from '../config/interfaces/views';

export enum ErrorCodes {
  UNRECOVERABLE,
  MISSING_GPS,
}

/**
 * Custom Error for files that can't be used.
 * eg: Not images.
 */
export class ImageProcessingError extends Error {
  code: ErrorCodes;

  constructor(code: ErrorCodes, message: string) {
    super(message);

    this.code = code;
  }
}

/**
 * Get coordinates from an image's EXIF info.
 *
 * @param file File to be processed
 * @returns Coordinate
 * @throws ImageProcessingError | Error
 */
export const GetCoordinateFromImage = async (file: File): Promise<Position> =>
  new Promise((resolve, reject) => {
    file2Buffer(file)
      .then((buffer) => {
        if (buffer instanceof ArrayBuffer) {
          const exifData = ExifReader(getExifDataFromJPEG(buffer));

          if (!exifData.gps) {
            throw new ImageProcessingError(ErrorCodes.MISSING_GPS, '画像にGPS情報が含まれいません');
          }

          const lat = exifData.gps.GPSLatitude as Array<number>;
          const lng = exifData.gps.GPSLongitude as Array<number>;
          const alt = exifData.gps.GPSAltitude as number;

          resolve({
            height: alt,
            latitude: DMS2Decimal(lat[0], lat[1], lat[2], exifData.gps.GPSLatitudeRef as string),
            longitude: DMS2Decimal(lng[0], lng[1], lng[2], exifData.gps.GPSLongitudeRef as string),
          });
        }

        throw new ImageProcessingError(ErrorCodes.UNRECOVERABLE, 'ファイル読み込みに失敗しました');
      })
      .catch((err: Error) => {
        reject(err);
      });
  });

/**
 * Image post-processing runs directly here on JS, not back-end.
 * Strips EXIF and resize to 2048x2048 (whichever side is smallest).
 * @returns void
 */
export const ScaleAndStripImage = async (file: File): Promise<File> => {
  // This scaling operation would have strip the EXIF, no need to explicitly do it.
  const blob = await readAndCompressImage(file, {
    quality: 0.75,
    maxWidth: 2048,
    maxHeight: 1500,
  });

  const buff = await new Response(blob).arrayBuffer();

  return new File([buff], file.name, { lastModified: file.lastModified });
};

/**
 * Reads a file from the user's local PC.
 * Note that this will only work with files chosen through file input.
 *
 * @param file File to be read
 * @returns ArrayBuffer
 */
const file2Buffer = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    const readFile = () => {
      const buffer = reader.result;
      resolve(buffer);
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.addEventListener('load', readFile);
    reader.readAsArrayBuffer(file);
  });

/**
 * Slices the image's buffer to only get EXIF-related data.
 *
 * @param arrayBuffer Entire image's array buffer as read from ArrayBuffer
 * @returns EXIF data in Buffer
 */
const getExifDataFromJPEG = (arrayBuffer: ArrayBuffer): Buffer => {
  const buf = Buffer.from(new Uint8Array(arrayBuffer));

  // Make sure it's jpeg
  if (buf[0] !== 0xff || buf[1] !== 0xd8) {
    throw new ImageProcessingError(ErrorCodes.UNRECOVERABLE, 'jpegファイル形式ではありません');
  }

  // Make sure it has EXIF data
  if (buf[2] !== 0xff || (buf[3] !== 0xe1 && buf[3] !== 0xe0)) {
    throw new ImageProcessingError(ErrorCodes.MISSING_GPS, '画像にEXIFデータが含まれていません');
  }

  let offset = 2;

  // If it has JFIF (0xFFE0), then we need to skip it
  if (buf[3] === 0xe0) {
    offset = buf.readUInt16BE(4) + 4;

    // Make sure it is followed by EXIF data
    if (buf[offset] !== 0xff || buf[offset + 1] !== 0xe1) {
      throw new ImageProcessingError(ErrorCodes.MISSING_GPS, '画像にEXIFデータが含まれていません');
    }
  }

  const size = buf.readUInt16BE(offset + 2); // to skip '0xffe1'

  return buf.slice(offset + 4, size - 2);
};

/**
 * Strips an image off its EXIF data.
 *
 * @param arrayBuffer Entire image's array buffer
 * @returns Image in Buffer. If it fails, will return the original data in Buffer.
 */
const stripExifData = (arrayBuffer: ArrayBuffer): Buffer => {
  const buf = Buffer.from(new Uint8Array(arrayBuffer));

  // Make sure it's jpeg
  if (buf[0] !== 0xff || buf[1] !== 0xd8) {
    return buf;
  }

  // Make sure it has EXIF data
  if (buf[2] !== 0xff || (buf[3] !== 0xe1 && buf[3] !== 0xe0)) {
    return buf;
  }

  let offset = 2;

  // If it has JFIF (0xFFE0), then we need to skip it
  if (buf[3] === 0xe0) {
    offset = buf.readUInt16BE(4) + 4;

    // Make sure it is followed by EXIF data
    if (buf[offset] !== 0xff || buf[offset + 1] !== 0xe1) {
      return buf;
    }

    // Skip app marker 0 '0xffe0'
    offset += 2;
  }

  const size = buf.readUInt16BE(offset); // to skip '0xffe1'

  // The first 2 is the jpeg marker. The following 2 + size
  // are the EXIF marker, size of EXIF and EXIF data itself.
  return Buffer.concat([
    buf.slice(0, 2), // Get jpeg format '0xffd8'
    buf.slice(2 + offset + size), // Skip '0xffd8', any offsets (0xffe0 if exists), and EXIF (0xffe1)
  ]);
};

/**
 * Converts coordinate in degrees, minute and seconds to decimal degrees.
 *
 * @param degrees
 * @param minutes
 * @param seconds
 * @param direction
 * @returns
 */
const DMS2Decimal = (degrees = 0, minutes = 0, seconds = 0, direction = 'N'): number => {
  const directions = ['N', 'S', 'E', 'W'];
  if (!directions.includes(direction.toUpperCase())) return 0;
  if (!Number(minutes) || minutes < 0 || minutes > 59) return 0;
  if (!Number(seconds) || seconds < 0 || seconds > 59) return 0;
  if (!Number(degrees) || degrees < 0 || degrees > 180) return 0;

  let decimal = degrees + minutes / 60 + seconds / 3600;
  if (direction.toUpperCase() === 'S' || direction.toUpperCase() === 'W') decimal *= -1;
  return decimal;
};
