export const NAV_BAR_HEIGHT = 60;

// default breakpoints
//   sm:  30em
//   md:  48em
//   lg:  62em
//   xl:  80em
//   2xl: 96em
export const CONTAINER_MAX_WIDTH = { lg: '58em', xl: '74em', '2xl': '89em' };

// InfoPanel styles
export const INFO_PANEL_WIDTH = 300;
export const INFO_PANEL_PADDING = 2;
export const ACTION_PANEL_WIDTH = INFO_PANEL_WIDTH - 30;

export const LAYER_ITEM_FOCUSED_BACKGROUND_COLOR = 'whiteAlpha.50';

export const LAYER_ITEM_STYLES = {
  borderColor: 'transparent',
  borderWidth: '1px',
  paddingLeft: INFO_PANEL_PADDING,
  paddingRight: INFO_PANEL_PADDING - 1.5,
  _hover: {
    borderColor: 'whiteAlpha.50',
  },
};

export const LAYER_ITEM_ACTIONS_PANEL_STYLES = {
  backgroundColor: 'gray.800',
  border: '1px',
  borderColor: 'secondary.400',
  paddingTop: INFO_PANEL_PADDING,
  paddingBottom: INFO_PANEL_PADDING,
};

export const LAYER_ACTION_PANEL_STYLES = {
  backgroundColor: 'gray.800',
  border: '1px',
  borderColor: 'secondary.400',
  padding: INFO_PANEL_PADDING,
};
