// react modules
import { StackDivider, VStack } from '@chakra-ui/react';
// third party modules
import { Cartesian2, Cartesian3, Color, defined, ScreenSpaceEventHandler, ScreenSpaceEventType, Viewer } from 'cesium';
import Entity from 'cesium/Source/DataSources/Entity';
import { debounce, startsWith } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
// assets, config
import { VIEWER_TOOLBAR_SHORTCUT, VIEWER_TOOLS } from '../../../config/constants';
import { ViewLayerPoints } from '../../../config/interfaces/views';
// services
import { DrawCommentPin, DrawImagePin, DrawPoint, DrawPolygon, DrawPolyline } from '../../../services/Cesium/Viewer';
import AreaTool from './AreaTool';
import CommentTool from './CommentTool/CommentTool';
import Compass from './Compass';
// components
import DistanceTool from './DistanceTool';
import MoveTool from './MoveTool';

const FOCUSED_LAYER_ID = 'FOCUSED_LAYER_ID';
const FOCUSED_COMMENT_LAYER_ID = 'FOCUSED_COMMENT_LAYER_ID';
const COMMENT_ICON_ID = 'COMMENT_ICON_ID';
export const IMAGE_ICON_ID = 'IMAGE_ICON_ID';
const HOVERED_POINT_ID = 'HOVERED_POINT_ID';
const HOVERED_LINE_ID = 'HOVERED_LINE_ID';
const FOCUSED_POLYGON_ID = 'FOCUSED_POLYGON_ID';
const SURFACE_POINT_COLOR = Color.GREEN;
const COMMENT_ICON_NAME = 'コメント';
const IMAGE_ICON_NAME = 'Image';

export const Toolbar: React.FC<{
  areaLayersPoints: ViewLayerPoints[];
  cesiumScreenSpaceEventHandler: ScreenSpaceEventHandler | undefined;
  cesiumViewerElement: Viewer;
  deletedAreaLayerIndex: number;
  deletedDistanceLayerIndex: number;
  distanceLayersPoints: ViewLayerPoints[];
  focusedAreaLayerIndex: number;
  focusedDistanceLayerIndex: number;
  isCommentPopupOpen: boolean;
  isToolDisabled: boolean;
  commentLayersPoints: ViewLayerPoints[];
  imageLayersPoints: ViewLayerPoints[];
  focusedCommentLayerIndex: number;
  isMovingPointCloudObject: boolean;
  setSelectedCommentLayerIndex: (index: number) => void;
  setFocusedCommentLayerIndex: (index: number) => void;
  resetDeletedAreaLayerIndex: () => void;
  resetDeletedDistanceLayerIndex: () => void;
  setAreaLayersPoints: (layerPoints: ViewLayerPoints[]) => void;
  setDistanceLayersPoints: (layerPoints: ViewLayerPoints[]) => void;
  toggleCommentPopup: (position: Cartesian3 | undefined) => void;
  setCameraHeading: (heading: number) => void;
  forSharedView?: boolean;
}> = ({
  areaLayersPoints,
  cesiumScreenSpaceEventHandler,
  cesiumViewerElement,
  deletedAreaLayerIndex,
  deletedDistanceLayerIndex,
  distanceLayersPoints,
  focusedAreaLayerIndex,
  focusedDistanceLayerIndex,
  isCommentPopupOpen,
  isToolDisabled,
  commentLayersPoints,
  imageLayersPoints,
  focusedCommentLayerIndex,
  isMovingPointCloudObject,
  setSelectedCommentLayerIndex,
  setFocusedCommentLayerIndex,
  resetDeletedAreaLayerIndex,
  resetDeletedDistanceLayerIndex,
  setAreaLayersPoints,
  setDistanceLayersPoints,
  toggleCommentPopup,
  setCameraHeading,
  forSharedView,
}) => {
  const [selectedTool, setSelectedTool] = useState<string>(VIEWER_TOOLS.MOVE);
  // because cesium input action callback cannot access react state properly,
  // use these states to trigger the click event after hovering a point
  const [hoveredPoint, setHoveredPoint] = useState<{ point: Cartesian3; fromSurface: boolean }>();
  const [hoveredPointConfirmed, setHoveredPointConfirmed] = useState(false);
  // each point layer will have a corresponding collection of point entites
  const [distancePointsEntities, setDistancePointsEntities] = useState<Entity[][]>([]);
  const [areaPointsEntities, setAreaPointsEntities] = useState<Entity[][]>([]);
  const [commentPointEntities, setCommentPointEntities] = useState<Entity[]>([]);
  const [imagePointEntities, setImagePointEntities] = useState<Entity[]>([]);
  // each point layer will have a corresponding collection of line entites
  const [distanceLinesEntities, setDistanceLinesEntities] = useState<Entity[]>([]);
  const [areaLinesEntities, setAreaLinesEntities] = useState<Entity[]>([]);
  // each point layer will have a corresponding polygon entity
  const [areaPolygonEntities, setAreaPolygonEntities] = useState<Entity[][]>([]);
  // for hovering layer effect
  const [localFocusedDistanceLayerIndex, setLocalFocusedDistanceLayerIndex] = useState(-1);
  const [localFocusedAreaLayerIndex, setLocalFocusedAreaLayerIndex] = useState(-1);
  const [localFocusedCommentLayerIndex, setLocalFocusedCommentLayerIndex] = useState(-1);

  // switch to point cloud object clickable setting
  const onClickIntersectEvent = useCallback(
    (isEnabled: boolean) => {
      if (!cesiumScreenSpaceEventHandler) return;

      cesiumScreenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK);

      if (isEnabled) {
        cesiumScreenSpaceEventHandler.setInputAction(() => {
          setHoveredPointConfirmed(true);
        }, ScreenSpaceEventType.LEFT_CLICK);
      } else {
        cesiumScreenSpaceEventHandler.setInputAction((event: { position: Cartesian2 }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const pickedObject: { id: Entity } = cesiumViewerElement.scene.pick(event.position, 1, 1);
          if (defined(pickedObject) && pickedObject?.id?.id === FOCUSED_COMMENT_LAYER_ID) {
            const [, index] = (pickedObject?.id?.name || ' ').split(' ');
            const layerIndex = parseInt(index, 10) - 1;
            setSelectedCommentLayerIndex(layerIndex);
          } else {
            setSelectedCommentLayerIndex(-1);
          }
        }, ScreenSpaceEventType.LEFT_CLICK);
      }
    },
    [cesiumScreenSpaceEventHandler, cesiumViewerElement.scene, setSelectedCommentLayerIndex]
  );

  // switch to point cloud object hoverable setting
  const onHoverIntersectEvent = useCallback(
    (isEnabled: boolean) => {
      if (!cesiumScreenSpaceEventHandler) return;

      cesiumScreenSpaceEventHandler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);

      if (isEnabled && cesiumViewerElement) {
        cesiumScreenSpaceEventHandler.setInputAction(
          // use debounce to prevent event triggering too many times
          debounce((event: { endPosition: Cartesian2 }) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const pickedObject = cesiumViewerElement.scene.pick(event.endPosition, 1, 1);
            if (cesiumViewerElement.scene.pickPositionSupported && defined(pickedObject)) {
              const position = cesiumViewerElement.scene.pickPosition(event.endPosition);
              if (position) {
                setHoveredPoint({ point: position, fromSurface: false });
              }
            } else {
              const ray = cesiumViewerElement.camera.getPickRay(event.endPosition);
              const position = cesiumViewerElement.scene.globe.pick(ray, cesiumViewerElement.scene);
              if (position) {
                setHoveredPoint({ point: position, fromSurface: true });
              }
            }
          }, 100),
          ScreenSpaceEventType.MOUSE_MOVE
        );
      } else {
        cesiumScreenSpaceEventHandler.setInputAction(
          // use debounce to prevent event triggering too many times
          debounce((event: { endPosition: Cartesian2 }) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const pickedObject: { id: Entity } = cesiumViewerElement.scene.pick(event.endPosition, 1, 1);
            if (defined(pickedObject) && pickedObject?.id?.id.includes(COMMENT_ICON_ID)) {
              const [, index] = (pickedObject?.id?.name || ' ').split(' ');
              const layerIndex = parseInt(index, 10) - 1;
              setFocusedCommentLayerIndex(layerIndex);
            } else if (defined(pickedObject) && pickedObject?.id?.id === FOCUSED_COMMENT_LAYER_ID) {
              // do nothing
            } else {
              setFocusedCommentLayerIndex(-1);
            }
          }, 100),
          ScreenSpaceEventType.MOUSE_MOVE
        );
      }
    },
    [cesiumScreenSpaceEventHandler, cesiumViewerElement, setFocusedCommentLayerIndex]
  );

  const isPolygonLayerClosed = (layerPoints: Cartesian3[]) => {
    if (layerPoints.length < 4) {
      return false;
    }

    const firstPoint = layerPoints[0];
    const lastPoint = layerPoints[layerPoints.length - 1];

    return Cartesian3.equals(firstPoint, lastPoint);
  };

  const cleanUpTool = useCallback(
    (tool: string) => {
      if (tool === VIEWER_TOOLS.DISTANCE && distanceLayersPoints.length) {
        // remove the last layer, because it is a continuous point
        const layerPoints = [...distanceLayersPoints];
        layerPoints.pop();
        setDistanceLayersPoints(layerPoints);

        // remove the point entity also
        const pointsEntities = [...distancePointsEntities];
        const entity = pointsEntities.pop();
        if (entity?.[0]) {
          cesiumViewerElement.entities.remove(entity[0]);
        }
        setDistancePointsEntities(pointsEntities);
      } else if (tool === VIEWER_TOOLS.AREA && areaLayersPoints.length) {
        const layerPoints = [...areaLayersPoints];
        const lastLayer = layerPoints[layerPoints.length - 1];

        // if the last layer has less than 3 points, remove this layer
        if (lastLayer?.points.length < 3) {
          layerPoints.pop();
          setAreaLayersPoints(layerPoints);

          // remove the point entity also
          const pointsEntities = [...areaPointsEntities];
          const entities = pointsEntities.pop();
          if (entities) {
            entities.forEach((entity) => cesiumViewerElement.entities.remove(entity));
          }
          setAreaPointsEntities(pointsEntities);

          // remove the line entity also
          const lineEntities = [...areaLinesEntities];
          const lineEntity = lineEntities.pop();
          if (lineEntity) {
            cesiumViewerElement.entities.remove(lineEntity);
          }
          setAreaLinesEntities(lineEntities);
          // connect the last point with the first one if not yet
        } else if (!isPolygonLayerClosed(lastLayer?.points)) {
          // duplicate the first point
          layerPoints[layerPoints.length - 1].points.push(lastLayer.points[0].clone());
          setAreaLayersPoints(layerPoints);

          const pointsEntities = [...areaPointsEntities];
          const lineEntities = [...areaLinesEntities];
          const polygonEntities = [...areaPolygonEntities];
          // draw the connection line and store in state, which can be used for removing later
          // each layer contains only one polyline
          // delete the polyline for this layer (if any), which connecting the previous points
          if (lineEntities.length === layerPoints.length) {
            const lineEntity = lineEntities.pop();
            if (lineEntity) {
              cesiumViewerElement.entities.remove(lineEntity);
            }
          }
          if (polygonEntities.length === layerPoints.length) {
            const polygonEntity = polygonEntities.pop();
            if (polygonEntity) {
              polygonEntity.forEach((entity) => cesiumViewerElement.entities.remove(entity));
            }
          }
          // and draw the new one which connect all of previous points with the new picked point
          const lineEntity = DrawPolyline(lastLayer?.points, cesiumViewerElement);
          if (lineEntity) {
            lineEntities.push(lineEntity);
          }
          // draw the polygon area if there are more than 2 points
          if (lastLayer?.points.length > 2) {
            const polygonEntity = DrawPolygon(lastLayer?.points, cesiumViewerElement);
            if (polygonEntity) {
              polygonEntities.push(polygonEntity);
            }
          }
          // draw the confirmed point and store in state, which can be used for removing later
          const pointEntity = DrawPoint(lastLayer.points[0], cesiumViewerElement);
          if (pointEntity) {
            // because the last layer has more than one point, push the new entity into it
            pointsEntities[pointsEntities.length - 1].push(pointEntity);
          }
          // save back the states
          setAreaPointsEntities(pointsEntities);
          setAreaLinesEntities(lineEntities);
          setAreaPolygonEntities(polygonEntities);
        }
      }
    },
    [
      areaLayersPoints,
      areaLinesEntities,
      areaPointsEntities,
      areaPolygonEntities,
      cesiumViewerElement,
      distanceLayersPoints,
      distancePointsEntities,
      setAreaLayersPoints,
      setDistanceLayersPoints,
    ]
  );

  const changeTool = useCallback(
    (tool: string, isActionHandled?: boolean) => {
      if (tool !== VIEWER_TOOLS.COMMENT) {
        toggleCommentPopup(undefined);
      }

      if (tool === VIEWER_TOOLS.DISTANCE || tool === VIEWER_TOOLS.AREA || tool === VIEWER_TOOLS.COMMENT) {
        onHoverIntersectEvent(true);
        onClickIntersectEvent(true);
      } else {
        onHoverIntersectEvent(false);
        onClickIntersectEvent(false);
        setHoveredPoint(undefined);

        if (!isActionHandled) {
          cleanUpTool(selectedTool);
        }
      }

      setSelectedTool(tool);
    },
    [cleanUpTool, onClickIntersectEvent, onHoverIntersectEvent, toggleCommentPopup, selectedTool]
  );

  useEffect(() => {
    // reset the current focused layer's style, before highlight the new one
    cesiumViewerElement.entities.removeById(FOCUSED_LAYER_ID);
    if (localFocusedDistanceLayerIndex >= 0 && localFocusedDistanceLayerIndex < distanceLinesEntities.length) {
      // show the current focused layer if allowed
      if (!distanceLayersPoints[localFocusedDistanceLayerIndex].invisible) {
        distanceLinesEntities[localFocusedDistanceLayerIndex].show = true;
      }
    }

    if (focusedDistanceLayerIndex < 0 || focusedDistanceLayerIndex >= distanceLinesEntities.length) {
      return;
    }
    // hide the line entity and draw another highlighted line over that position
    distanceLinesEntities[focusedDistanceLayerIndex].show = false;
    DrawPolyline(
      distanceLayersPoints[focusedDistanceLayerIndex]?.points,
      cesiumViewerElement,
      true,
      true,
      FOCUSED_LAYER_ID
    );

    // save back the state
    setLocalFocusedDistanceLayerIndex(focusedDistanceLayerIndex);

    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedDistanceLayerIndex]);

  useEffect(() => {
    // reset the current focused layer's style, before highlight the new one
    cesiumViewerElement.entities.removeById(FOCUSED_LAYER_ID);
    cesiumViewerElement.entities.removeById(FOCUSED_POLYGON_ID);
    if (localFocusedAreaLayerIndex >= 0 && localFocusedAreaLayerIndex < areaLinesEntities.length) {
      // show the current focused layer if allowed
      if (!areaLayersPoints[localFocusedAreaLayerIndex].invisible) {
        areaLinesEntities[localFocusedAreaLayerIndex].show = true;
        areaPolygonEntities[localFocusedAreaLayerIndex][0].show = true;
      }
    }

    if (focusedAreaLayerIndex < 0 || focusedAreaLayerIndex >= areaLinesEntities.length) {
      return;
    }
    // hide the line entity and draw another highlighted line over that position
    areaLinesEntities[focusedAreaLayerIndex].show = false;
    DrawPolyline(areaLayersPoints[focusedAreaLayerIndex]?.points, cesiumViewerElement, false, true, FOCUSED_LAYER_ID);

    // hide the label enity and draw another highlighted label over that position
    areaPolygonEntities[focusedAreaLayerIndex][0].show = false;
    DrawPolygon(areaLayersPoints[focusedAreaLayerIndex]?.points, cesiumViewerElement, true, true, FOCUSED_POLYGON_ID);

    // save back the state
    setLocalFocusedAreaLayerIndex(focusedAreaLayerIndex);

    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedAreaLayerIndex]);

  useEffect(() => {
    // reset the current focused layer's style, before highlight the new one
    cesiumViewerElement.entities.removeById(FOCUSED_COMMENT_LAYER_ID);
    if (localFocusedCommentLayerIndex >= 0 && localFocusedCommentLayerIndex < commentPointEntities.length) {
      // show the current focused layer if allowed
      if (!commentLayersPoints[localFocusedCommentLayerIndex].invisible) {
        commentPointEntities[localFocusedCommentLayerIndex].show = true;
      }
    }

    if (focusedCommentLayerIndex < 0 || focusedCommentLayerIndex >= commentPointEntities.length) {
      return;
    }
    // hide the line entity and draw another highlighted line over that position
    commentPointEntities[focusedCommentLayerIndex].show = false;
    DrawCommentPin(
      commentLayersPoints[focusedCommentLayerIndex]?.points[0],
      cesiumViewerElement,
      true,
      FOCUSED_COMMENT_LAYER_ID,
      `${COMMENT_ICON_NAME} ${focusedCommentLayerIndex + 1}`
    );

    // save back the state
    setLocalFocusedCommentLayerIndex(focusedCommentLayerIndex);

    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedCommentLayerIndex]);

  const confirmHoveredPoint = useCallback(
    (ended?: boolean) => {
      if (!hoveredPoint) {
        return;
      }

      // replace hovered point with a picked point
      cesiumViewerElement.entities.removeById(HOVERED_POINT_ID);
      // replace hovered line with a picked line
      cesiumViewerElement.entities.removeById(HOVERED_LINE_ID);

      if (selectedTool === VIEWER_TOOLS.DISTANCE) {
        const layerPoints = [...distanceLayersPoints];
        const pointsEntities = [...distancePointsEntities];

        if (layerPoints.length) {
          const lastLayer = layerPoints[layerPoints.length - 1];
          const lineEntities = [...distanceLinesEntities];

          // last layer has one point means that user is continuously picking points
          if (lastLayer?.points.length === 1) {
            // confirm the hovered point and put it into the last layer
            lastLayer?.points.push(hoveredPoint.point);
            // draw the connection line and store in state, which can be used for removing later
            // each layer contains only one line
            const lineEntity = DrawPolyline(lastLayer?.points, cesiumViewerElement, true);
            if (lineEntity) {
              lineEntities.push(lineEntity);
            }
            // draw the confirmed point and store in state, which can be used for removing later
            // this layer contains two points
            const pointEntity = DrawPoint(hoveredPoint.point, cesiumViewerElement);
            if (pointEntity) {
              pointsEntities[pointsEntities.length - 1].push(pointEntity);
            }

            // save back the states
            setDistanceLinesEntities(lineEntities);
            // set state for layer points will be handled at the end
            // point entities has been modified directly already
            layerPoints[layerPoints.length - 1] = lastLayer;

            if (!ended) {
              // because user is continuously picking points, duplicate the confirmed point into a new layer
              layerPoints.push({ points: [hoveredPoint.point] });
              // draw the duplicated point and store in state, which can be used for removing later
              // this layer contains one point
              const duplicatedPointEntity = DrawPoint(hoveredPoint.point, cesiumViewerElement);
              if (duplicatedPointEntity) {
                pointsEntities.push([duplicatedPointEntity]);
              }
            }
          } else if (lastLayer?.points.length >= 2 && !ended) {
            // last layer has 2 points means that user is picking a new layer,
            // so we will push this confirmed point into a new layer
            // no need to draw any line
            layerPoints.push({ points: [hoveredPoint.point] });
            // draw the confirmed point and store in state, which can be used for removing later
            // this layer contains one point
            const pointEntity = DrawPoint(hoveredPoint.point, cesiumViewerElement);
            if (pointEntity) {
              pointsEntities.push([pointEntity]);
            }
          }
        } else if (!ended) {
          // no need to draw any line if there is only one point in the layer
          layerPoints.push({ points: [hoveredPoint.point] });
          // draw the confirmed point and store in state, which can be used for removing later
          // this layer contains one point
          const pointEntity = DrawPoint(hoveredPoint.point, cesiumViewerElement);
          if (pointEntity) {
            pointsEntities.push([pointEntity]);
          }
        }
        // save back the states
        setDistancePointsEntities(pointsEntities);
        setDistanceLayersPoints(layerPoints);
      } else if (selectedTool === VIEWER_TOOLS.AREA) {
        const layerPoints = [...areaLayersPoints];
        const pointsEntities = [...areaPointsEntities];

        if (layerPoints.length) {
          const lastLayer = layerPoints[layerPoints.length - 1];
          const lineEntities = [...areaLinesEntities];
          const polygonEntities = [...areaPolygonEntities];

          // if the last layer has not completed the points picking,
          // continue to push the picked point into the last layer
          if (!isPolygonLayerClosed(lastLayer.points)) {
            // confirm the hovered point and put it into the last layer
            lastLayer?.points.push(hoveredPoint.point);
            // if user try to press Confirm shortcut, close the polygon if not yet
            const needClose = ended && !Cartesian3.equals(hoveredPoint.point, lastLayer?.points[0]);
            if (needClose) {
              lastLayer?.points.push(lastLayer?.points[0].clone());
            }
            // draw the connection line and store in state, which can be used for removing later
            // each layer contains only one polyline
            // delete the polyline for this layer (if any), which connecting the previous points
            if (lineEntities.length === layerPoints.length) {
              const lineEntity = lineEntities.pop();
              if (lineEntity) {
                cesiumViewerElement.entities.remove(lineEntity);
              }
            }
            if (polygonEntities.length === layerPoints.length) {
              const polygonEntity = polygonEntities.pop();
              if (polygonEntity) {
                polygonEntity.forEach((entity) => cesiumViewerElement.entities.remove(entity));
              }
            }

            // and draw the new one which connect all of previous points with the new picked point
            const lineEntity = DrawPolyline(lastLayer?.points, cesiumViewerElement);
            if (lineEntity) {
              lineEntities.push(lineEntity);
            }
            // draw the polygon area if there are more than 2 points
            if (lastLayer?.points.length > 2) {
              const polygonEntity = DrawPolygon(lastLayer?.points, cesiumViewerElement);
              // eslint-disable-next-line no-underscore-dangle
              if (polygonEntity) {
                polygonEntities.push(polygonEntity);
              }
            }
            // draw the confirmed point and store in state, which can be used for removing later
            const pointEntity = DrawPoint(hoveredPoint.point, cesiumViewerElement);
            if (pointEntity) {
              // because the last layer has more than one point, push the new entity into it
              pointsEntities[pointsEntities.length - 1].push(pointEntity);
            }

            // save back the states
            setAreaLinesEntities(lineEntities);
            setAreaPolygonEntities(polygonEntities);
            // set state for layer points will be handled at the end
            // point entities has been modified directly already
            layerPoints[layerPoints.length - 1] = lastLayer;
          } else if (!ended) {
            // else, create a new layer
            layerPoints.push({ points: [hoveredPoint.point] });
            // draw the confirmed point and store in state, which can be used for removing later
            // this layer contains one point
            const pointEntity = DrawPoint(hoveredPoint.point, cesiumViewerElement);
            if (pointEntity) {
              pointsEntities.push([pointEntity]);
            }
          }
        } else if (!ended) {
          // no need to draw any line if there is only one point in the layer
          layerPoints.push({ points: [hoveredPoint.point] });
          // draw the confirmed point and store in state, which can be used for removing later
          // this layer contains one point
          const pointEntity = DrawPoint(hoveredPoint.point, cesiumViewerElement);
          if (pointEntity) {
            pointsEntities.push([pointEntity]);
          }
        }
        // save back the states
        setAreaPointsEntities(pointsEntities);
        setAreaLayersPoints(layerPoints);
      } else if (selectedTool === VIEWER_TOOLS.COMMENT) {
        toggleCommentPopup(hoveredPoint.point);
      }
      // reset the state
      setHoveredPoint(undefined);
    },
    [
      areaLayersPoints,
      areaLinesEntities,
      areaPointsEntities,
      areaPolygonEntities,
      cesiumViewerElement,
      distanceLayersPoints,
      distanceLinesEntities,
      distancePointsEntities,
      hoveredPoint,
      selectedTool,
      setAreaLayersPoints,
      setDistanceLayersPoints,
      toggleCommentPopup,
    ]
  );

  useEffect(() => {
    // reset the state
    setHoveredPointConfirmed(false);
    if (!hoveredPoint || !hoveredPointConfirmed) {
      return;
    }
    confirmHoveredPoint();
    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cesiumViewerElement, hoveredPointConfirmed]);

  useEffect(() => {
    cesiumViewerElement.entities.removeById(HOVERED_POINT_ID);
    cesiumViewerElement.entities.removeById(HOVERED_LINE_ID);

    if (hoveredPoint) {
      DrawPoint(
        hoveredPoint.point,
        cesiumViewerElement,
        hoveredPoint.fromSurface ? SURFACE_POINT_COLOR : undefined,
        true,
        HOVERED_POINT_ID
      );

      const layersPoints = selectedTool === VIEWER_TOOLS.DISTANCE ? distanceLayersPoints : areaLayersPoints;
      // if last layer has one or more picked points,
      // draw a hovered line from the last point to this hovered point
      if (layersPoints.length) {
        const lastLayer = layersPoints[layersPoints.length - 1];
        if (
          // if distance tool is active, the last layer must have only one point
          // (distance layer points allow only 2 points per layer)
          (selectedTool === VIEWER_TOOLS.DISTANCE && lastLayer?.points.length === 1) ||
          // if area tool is active, the last layer must not be closed (completed)
          (selectedTool === VIEWER_TOOLS.AREA && !isPolygonLayerClosed(lastLayer?.points))
        ) {
          DrawPolyline(
            [lastLayer?.points[lastLayer?.points.length - 1], hoveredPoint.point],
            cesiumViewerElement,
            // selectedTool === VIEWER_TOOLS.DISTANCE,
            true,
            true,
            HOVERED_LINE_ID
          );
        }
      }
    }
    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredPoint]);

  useEffect(() => {
    distanceLayersPoints.forEach((layersPoints, index) => {
      if (index < distancePointsEntities.length && index < distanceLinesEntities.length) {
        distancePointsEntities[index].forEach((pointEntity) => {
          const entity = pointEntity;
          entity.show = !layersPoints.invisible;
        });
        // only show back if this layer is not focused
        if (!layersPoints.invisible && localFocusedDistanceLayerIndex !== index) {
          distanceLinesEntities[index].show = true;
        }
      }
    });
    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distanceLayersPoints]);

  useEffect(() => {
    areaLayersPoints.forEach((layersPoints, index) => {
      if (index < areaPointsEntities.length && index < areaLinesEntities.length && index < areaPolygonEntities.length) {
        areaPointsEntities[index].forEach((pointEntity) => {
          const entity = pointEntity;
          entity.show = !layersPoints.invisible;
        });
        areaPolygonEntities[index].forEach((polygonEntity) => {
          const entity = polygonEntity;
          entity.show = !layersPoints.invisible;
        });
        // only show back if this layer is not focused
        const willShow = !layersPoints.invisible && localFocusedAreaLayerIndex !== index;
        areaLinesEntities[index].show = willShow;
        areaPolygonEntities[index][0].show = willShow;
      }
    });
    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaLayersPoints]);

  useEffect(() => {
    if (commentPointEntities?.length) {
      commentPointEntities.forEach((commentPoint) => cesiumViewerElement.entities.remove(commentPoint));
    }
    const newEntities = commentLayersPoints.map((layer, index) =>
      DrawCommentPin(
        layer.points[0],
        cesiumViewerElement,
        false,
        `${COMMENT_ICON_ID}.${index}`,
        `${COMMENT_ICON_NAME} ${index + 1}`,
        !layer.invisible
      )
    );
    setCommentPointEntities(newEntities);
    cesiumViewerElement.entities.removeById(FOCUSED_LAYER_ID);
    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentLayersPoints]);

  useEffect(() => {
    if (!cesiumViewerElement) return;

    // Remove existing entities before re-adding them later
    cesiumViewerElement.entities.values.filter(row => startsWith(row.id, IMAGE_ICON_ID))
      .reverse()
      .forEach(entity => cesiumViewerElement.entities.removeById(entity.id));

    const newEntities = imageLayersPoints.map((layer, index) => 
      DrawImagePin(
        layer.points[0],
        cesiumViewerElement,
        false,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${IMAGE_ICON_ID}.${layer.id}`,
        layer.name,
        !layer.invisible,
        layer.clamp_to_ground
      )
    );

    setImagePointEntities(newEntities);
    cesiumViewerElement.entities.removeById(FOCUSED_LAYER_ID);
    // no need to watch other states
  }, [imageLayersPoints, cesiumViewerElement]);

  useEffect(() => {
    if (deletedDistanceLayerIndex < 0) {
      return;
    }

    // delete all points and corresponding entities
    if (deletedDistanceLayerIndex < distanceLayersPoints.length) {
      const newLayersPoints = [...distanceLayersPoints];
      newLayersPoints.splice(deletedDistanceLayerIndex, 1);
      setDistanceLayersPoints(newLayersPoints);
    }

    if (deletedDistanceLayerIndex < distanceLinesEntities.length) {
      const newLinesEntities = [...distanceLinesEntities];
      const deletedLineEntities = newLinesEntities.splice(deletedDistanceLayerIndex, 1);
      deletedLineEntities.forEach((entity) => cesiumViewerElement.entities.remove(entity));
      setDistanceLinesEntities(newLinesEntities);
    }

    if (deletedDistanceLayerIndex < distancePointsEntities.length) {
      const newPointsEntities = [...distancePointsEntities];
      const deletedPointEntities = newPointsEntities.splice(deletedDistanceLayerIndex, 1);
      deletedPointEntities.forEach((entities) => {
        entities.forEach((entity) => cesiumViewerElement.entities.remove(entity));
      });
      setDistancePointsEntities(newPointsEntities);
    }

    resetDeletedDistanceLayerIndex();

    // because deleted layer also is being focused, remove the focus entities
    cesiumViewerElement.entities.removeById(FOCUSED_LAYER_ID);

    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedDistanceLayerIndex]);

  useEffect(() => {
    if (deletedAreaLayerIndex < 0) {
      return;
    }

    // delete all points and corresponding entities
    if (deletedAreaLayerIndex < areaLayersPoints.length) {
      const newLayersPoints = [...areaLayersPoints];
      newLayersPoints.splice(deletedAreaLayerIndex, 1);
      setAreaLayersPoints(newLayersPoints);
    }

    if (deletedAreaLayerIndex < areaLinesEntities.length) {
      const newLinesEntities = [...areaLinesEntities];
      const deletedLineEntities = newLinesEntities.splice(deletedAreaLayerIndex, 1);
      deletedLineEntities.forEach((entity) => cesiumViewerElement.entities.remove(entity));
      setAreaLinesEntities(newLinesEntities);
    }

    if (deletedAreaLayerIndex < areaPointsEntities.length) {
      const newPointsEntities = [...areaPointsEntities];
      const deletedPointEntities = newPointsEntities.splice(deletedAreaLayerIndex, 1);
      deletedPointEntities.forEach((entities) => {
        entities.forEach((entity) => cesiumViewerElement.entities.remove(entity));
      });
      setAreaPointsEntities(newPointsEntities);
    }

    if (deletedAreaLayerIndex < areaPolygonEntities.length) {
      const newPolygonEntities = [...areaPolygonEntities];
      const deletedPolygonEntities = newPolygonEntities.splice(deletedAreaLayerIndex, 1);
      deletedPolygonEntities.forEach((polygonEntity) =>
        polygonEntity.forEach((entity) => cesiumViewerElement.entities.remove(entity))
      );
      setAreaPolygonEntities(newPolygonEntities);
    }

    resetDeletedAreaLayerIndex();

    // because deleted layer also is being focused, remove the focus entities
    cesiumViewerElement.entities.removeById(FOCUSED_LAYER_ID);
    cesiumViewerElement.entities.removeById(FOCUSED_POLYGON_ID);

    // no need to watch other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedAreaLayerIndex]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // do nothing if user is typing on comment input
      if (selectedTool === VIEWER_TOOLS.COMMENT || isToolDisabled || isCommentPopupOpen) {
        return;
      }

      if (VIEWER_TOOLBAR_SHORTCUT.CONFIRM.includes(e.key)) {
        confirmHoveredPoint(true);
        // only need to cleanup if the last layer has only one point but no hovered point
        // this happens when user has just finished picking and press Enter
        changeTool(VIEWER_TOOLS.MOVE, selectedTool !== VIEWER_TOOLS.AREA && !!hoveredPoint);
      } else if (VIEWER_TOOLBAR_SHORTCUT.CANCEL.includes(e.key) || VIEWER_TOOLBAR_SHORTCUT.MOVE_TOOL.includes(e.key)) {
        changeTool(VIEWER_TOOLS.MOVE);
      } else if (VIEWER_TOOLBAR_SHORTCUT.DISTANCE_TOOL.includes(e.key)) {
        changeTool(VIEWER_TOOLS.DISTANCE);
      } else if (VIEWER_TOOLBAR_SHORTCUT.AREA_TOOL.includes(e.key)) {
        changeTool(VIEWER_TOOLS.AREA);
      } else if (VIEWER_TOOLBAR_SHORTCUT.COMMENT_TOOL.includes(e.key)) {
        changeTool(VIEWER_TOOLS.COMMENT);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [changeTool, confirmHoveredPoint, hoveredPoint, selectedTool, isToolDisabled, isCommentPopupOpen]);

  // When tools are disabled, change it back to move
  useEffect(() => {
    if (!isToolDisabled) return;

    changeTool(VIEWER_TOOLS.MOVE);
  }, [isToolDisabled, changeTool]);

  // init click event when loading
  useEffect(() => {
    if (isMovingPointCloudObject || selectedTool !== VIEWER_TOOLS.MOVE) {
      return;
    }
    if (cesiumScreenSpaceEventHandler && onClickIntersectEvent && onHoverIntersectEvent) {
      onClickIntersectEvent(false);
      onHoverIntersectEvent(false);
    }
  }, [
    onClickIntersectEvent,
    onHoverIntersectEvent,
    cesiumScreenSpaceEventHandler,
    isMovingPointCloudObject,
    selectedTool,
  ]);

  if (isMovingPointCloudObject) {
    return null;
  }
  return (
    <VStack
      backgroundColor="gray.800"
      borderRadius="md"
      divider={<StackDivider borderColor="whiteAlpha.200" />}
      left={1}
      overflow="hidden"
      position="absolute"
      spacing={0}
      top={forSharedView ? 1 : 9}
      w={8}
    >
      <MoveTool isActive={selectedTool === VIEWER_TOOLS.MOVE} onClick={() => changeTool(VIEWER_TOOLS.MOVE)} />
      <DistanceTool
        isActive={selectedTool === VIEWER_TOOLS.DISTANCE}
        onClick={() => changeTool(VIEWER_TOOLS.DISTANCE)}
      />
      <AreaTool isActive={selectedTool === VIEWER_TOOLS.AREA} onClick={() => changeTool(VIEWER_TOOLS.AREA)} />
      <CommentTool
        isActive={selectedTool === VIEWER_TOOLS.COMMENT}
        onClick={() => changeTool(VIEWER_TOOLS.COMMENT)}
        numberOfComments={commentLayersPoints.length || 0}
      />{' '}
      <Compass onClick={() => setCameraHeading(0)} />
    </VStack>
  );
};
