import { Box, Button, Divider, Flex, Heading, Skeleton, Spacer, Text, VStack } from '@chakra-ui/react';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ViewCommentReply } from '../../../../config/interfaces/views';
import { GetAssetReplies, GetSharedAssetReplies } from '../../../../services/View/AssetReply';
import { Comment } from './Comment';
import { Form } from './Form';

export const Comments: React.FC<{
  authToken: string | null;
  asset_id: number;
  view_id: string;
  forSharedView: boolean | undefined;
  handleModalClose: () => void;
}> = ({ authToken, asset_id, view_id, forSharedView, handleModalClose }) => {
  const [isFormShown, setIsFormShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState<ViewCommentReply[]>([]);
  const bottomRef = useRef<HTMLDivElement>(null);

  const updateComments = (values: Array<ViewCommentReply>): void => {
    setComments(values);
  };

  const fetchComments = useCallback(async () => {
    setIsLoading(true);
    const result = await (forSharedView ? GetSharedAssetReplies : GetAssetReplies)(authToken || "", view_id, asset_id);

    setComments(result);
    setIsLoading(false);
  }, [view_id, asset_id, forSharedView, setComments, authToken]);

  useEffect(() => {
    if (!comments || !comments.length || !bottomRef) return;

    setTimeout(() => bottomRef.current?.scrollIntoView());
  }, [comments, bottomRef, isFormShown]);

  // Comment was deleted, remove it from comment's array.
  const handleCommentDeleted = (comment: ViewCommentReply) => {
    setComments(cloneDeep(comments).filter((row) => row.reply_id !== comment.reply_id));
  };

  // Comment was edited, update it in the array
  const handleCommentEdited = (comment: ViewCommentReply) => {
    setComments(
      comments.map((row) => {
        const updated = cloneDeep(row);
        if (row.reply_id === comment.reply_id) return comment;
        return updated;
      })
    );
  };

  useEffect(() => {
    if (!view_id || !asset_id) return;

    try {
      void fetchComments();
    } catch (err) {
      console.error(err);
    }
  }, [view_id, asset_id, fetchComments]);

  return (
    <VStack alignItems="left" maxHeight="100%" width="100%">

      <Heading size="sm">
        コメント
      </Heading>

      <Divider />

      <Box overflow="auto" flex="1 1 auto" minHeight="0">
        {!isLoading &&
          comments.map((comment) => (
            <Comment
              key={comment.reply_id}
              authToken={authToken}
              view_id={view_id}
              asset_id={asset_id}
              comment={comment}
              forSharedView={forSharedView}
              onDeleted={handleCommentDeleted}
              onEdited={handleCommentEdited}
            />
          ))}
        {isLoading && (
          <>
            <VStack alignItems="left" mb={5}>
              <Skeleton height="1rem" width="100px" />
              <Skeleton height="1rem" />
            </VStack>
            <Spacer />
            <VStack alignItems="left">
              <Skeleton height="1rem" width="100px" />
              <Skeleton height="1rem" />
            </VStack>
          </>
        )}
        <Box ref={bottomRef} />
      </Box>

      <Box>
        {(comments.length || isLoading) && <Divider mb={3} />}

        {isFormShown && (
          <Form 
            authToken={authToken}
            asset_id={asset_id} 
            view_id={view_id} 
            forSharedView={forSharedView}
            setIsFormShown={setIsFormShown} 
            updateComments={updateComments}
          />
        )}
        <Flex>
          {!isFormShown && (
            <>
              <Button colorScheme="primary" size="sm" onClick={() => setIsFormShown(true)}>
                返信
              </Button>
              <Spacer />
              <Button variant="outline" size="sm" onClick={() => handleModalClose()}>
                閉じる
              </Button>
            </>
          )}
        </Flex>
      </Box>
    </VStack>
  );
};
