// react modules
import { Box, Button, Collapse, useDisclosure, Text } from '@chakra-ui/react';

// third party modules
// components
// services
// assets, config
import {
  BigChevronDownIcon as ChevronDownIcon,
  BigChevronUpIcon as ChevronUpIcon,
} from '../../../../assets/icons/Index';

export const CollapsePanel: React.FC<{ title: string }> = ({ children, title }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  return (
    <Box w="100%">
      <Button
        onClick={onToggle}
        w="100%"
        variant="layer"
        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        paddingInlineStart={3}
        paddingInlineEnd={4}
        display="flex"
        justifyContent="space-between"
      >
        <Text>{title}</Text>
      </Button>
      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </Box>
  );
};
