// react modules
import { useImperativeHandle, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';

// third party modules

// assets, config

// services
import { DeleteView } from '../../services/View/View';
import { ProcessErrorHandler } from '../../services/ErrorHandler';

const DeleteViewModal: React.ForwardRefRenderFunction<
  {
    openModal: (viewId: string, viewName: string) => void;
  },
  {
    deleteCompleted: (viewId: string) => void;
  }
> = ({ deleteCompleted }, ref) => {
  // common hooks
  const { getAccessTokenSilently } = useAuth0();

  // manage modal state
  const { isOpen, onOpen, onClose } = useDisclosure();

  // delete target view
  const [deleteViewId, setDeleteViewId] = useState('');
  const [deleteViewName, setDeleteViewName] = useState('');

  // manage delete processing
  const [isDeleting, setIsDeleting] = useState(false);

  // call function from parent component setting
  useImperativeHandle(ref, () => ({
    openModal(viewId: string, viewName: string) {
      setDeleteViewId(viewId);
      setDeleteViewName(viewName);
      onOpen();
    },
  }));

  const deleteView = async () => {
    setIsDeleting(true);

    try {
      const tokenForDeleteView = await getAccessTokenSilently();
      await DeleteView(tokenForDeleteView, deleteViewId);

      deleteCompleted(deleteViewId);
      onClose();
    } catch (err) {
      ProcessErrorHandler(err, 'deleteView');
    }

    setIsDeleting(false);
  };

  return (
    <Modal closeOnOverlayClick={!isDeleting} isOpen={isOpen} onClose={onClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent color="gray.600" mx={2}>
        <ModalHeader>Viewの削除</ModalHeader>
        <ModalCloseButton disabled={isDeleting} />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>View名</FormLabel>
            <Input value={deleteViewName} color="black" bg="gray.300" readOnly disabled />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} disabled={isDeleting} mr={3}>
            キャンセル
          </Button>
          <Button colorScheme="red" isLoading={isDeleting} onClick={deleteView}>
            viewを削除
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default forwardRef(DeleteViewModal);
