// react modules
import { Box, Center, Divider, Flex, VStack } from '@chakra-ui/react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
// services
// assets, config
import { SharedLinkIcon, SharedLinkPasswordIcon, UnsharedLinkIcon } from '../../../assets/icons/Index';
import { Asset, View, ViewLayerPoints, ViewSettings } from '../../../config/interfaces/views';
import { INFO_PANEL_WIDTH } from '../../../config/styles';
// third party modules
// components
import { AreaPanel } from './areaPanel/Panel';
import { CommentPanel } from './commentPanel/Panel';
import { DataPanel, DataPanelForwardRef, EditAssetStatus } from './dataPanel/Panel';
import { DistancePanel } from './distancePanel/Panel';
import { GISPanel } from './GISPanel/Panel';
import { GlobeTranslucencyPanel } from './terrainPanel/Panel';
import { ActionButton } from './_components/ActionButton';
import { CollapsePanel } from './_components/CollapsePanel';

const InfoPanelsFunction: React.ForwardRefRenderFunction<
  DataPanelForwardRef,
  {
    view: View;
    isEditable: boolean;
    distanceLayersPoints: ViewLayerPoints[];
    areaLayersPoints: ViewLayerPoints[];
    commentLayersPoints: ViewLayerPoints[];
    focusedDistanceLayerIndex: number;
    focusedAreaLayerIndex: number;
    focusedCommentLayerIndex: number;
    selectedCommentLayerIndex: number;
    isCommentLayerLoading: boolean;
    updateDistanceLayerVisibility: (index: number, visibility: boolean) => void;
    deleteDistanceLayer: (index: number) => void;
    updateAreaLayerVisibility: (index: number, visibility: boolean) => void;
    deleteAreaLayer: (index: number) => void;
    updateCommentLayerVisibility: (index: number, visibility: boolean) => void;
    openDeleteCommentConfirmModalHandler: (index: number) => void;
    setFocusedDistanceLayerIndex: (index: number) => void;
    setFocusedAreaLayerIndex: (index: number) => void;
    setFocusedCommentLayerIndex: (index: number) => void;
    setSelectedCommentLayerIndex: (index: number) => void;
    openDeleteAssetConfirmModalHandler: (asset: Asset) => void;
    downloadAssetHandler: (asset: Asset) => void;
    editedAssetHandler: (asset: Asset) => void;
    moveCameraHandler: (asset: Asset, vectorType: string) => void;
    saveAssetHandler: (asset: Asset) => void;
    saveAssetVisibleHandler: (asset: Asset) => void;
    saveViewSettingsHandler: (newViewSettings: ViewSettings) => void;
    editGlobeTranslucencyHandler: (alpha: number) => void;
    switchCesiumClickEventHandler: (isEnabled: boolean) => void;
    openAddAssetModalHandler: () => void;
    openEditShareLinkModalHandler: () => void;
    openRevertAssetConfirmModalHandler: () => void;
    changePinPosition: (asset: Asset) => void;
  }
> = (
  {
    view,
    isEditable,
    distanceLayersPoints,
    areaLayersPoints,
    commentLayersPoints,
    focusedDistanceLayerIndex,
    focusedAreaLayerIndex,
    focusedCommentLayerIndex,
    selectedCommentLayerIndex,
    isCommentLayerLoading,
    updateDistanceLayerVisibility,
    deleteDistanceLayer,
    updateAreaLayerVisibility,
    deleteAreaLayer,
    updateCommentLayerVisibility,
    openDeleteCommentConfirmModalHandler,
    setFocusedDistanceLayerIndex,
    setFocusedAreaLayerIndex,
    setFocusedCommentLayerIndex,
    setSelectedCommentLayerIndex,
    openDeleteAssetConfirmModalHandler,
    downloadAssetHandler,
    editedAssetHandler,
    moveCameraHandler,
    saveAssetHandler,
    saveAssetVisibleHandler,
    saveViewSettingsHandler,
    editGlobeTranslucencyHandler,
    switchCesiumClickEventHandler,
    openAddAssetModalHandler,
    openEditShareLinkModalHandler,
    openRevertAssetConfirmModalHandler,
    changePinPosition,
  },
  ref
) => {
  // child data panel for call close action panel
  const dataPanelRef = useRef<DataPanelForwardRef>(null);

  useImperativeHandle(ref, () => ({
    closeActionPanel() {
      dataPanelRef.current?.closeActionPanel();
    },
    getEditAssetStatus() {
      if (!dataPanelRef.current) return null;
      return dataPanelRef.current.getEditAssetStatus();
    },
    updateEditAssetStatus(status: EditAssetStatus) {
      if (!dataPanelRef.current) return;
      dataPanelRef.current.updateEditAssetStatus(status);
    },
  }));

  const getShareLinkIcon = (): React.ReactNode => {
    if (!view.view_settings.is_share) {
      return <UnsharedLinkIcon size={16} style={{ marginRight: '1px' }} />;
    }
    if (view.view_settings.password) {
      return <SharedLinkPasswordIcon />;
    }
    return <SharedLinkIcon size={16} style={{ marginRight: '1px' }} />;
  };

  return (
    <VStack
      color="secondary.400"
      fontSize="xs"
      position="absolute"
      right={0}
      spacing={1.5}
      top={10}
      paddingTop={2}
      paddingRight={2}
      w={INFO_PANEL_WIDTH}
      height="calc(100% - 100px)"
      overflowY="auto"
    >
      <Box textAlign="right" w="100%">
        <ActionButton ReactIcon={getShareLinkIcon()} onClickHandler={openEditShareLinkModalHandler}>
          共有リンク
        </ActionButton>
      </Box>

      <Flex justify="flex-end" ml="auto!important" w="100%">
        <Box backgroundColor="gray.800" borderRadius="md" w="100%">
          <CollapsePanel title="地表透過度">
            <GlobeTranslucencyPanel editGlobeTranslucencyHandler={editGlobeTranslucencyHandler} />
          </CollapsePanel>
          <CollapsePanel title="ファイル">
            <DataPanel
              ref={dataPanelRef}
              view={view}
              isEditable={isEditable}
              // asset handler
              editedAssetHandler={editedAssetHandler}
              saveAssetHandler={saveAssetHandler}
              saveAssetVisibleHandler={saveAssetVisibleHandler}
              downloadAssetHandler={downloadAssetHandler}
              openAddAssetModalHandler={openAddAssetModalHandler}
              openDeleteAssetConfirmModalHandler={openDeleteAssetConfirmModalHandler}
              openRevertAssetConfirmModalHandler={openRevertAssetConfirmModalHandler}
              // cesium viewer handler
              moveCameraHandler={moveCameraHandler}
              switchCesiumClickEventHandler={switchCesiumClickEventHandler}
              changePinPosition={changePinPosition}
            />
          </CollapsePanel>
          <Center h={2} w="100%">
            <Divider variant="layer" />
          </Center>
          <CollapsePanel title="建物モデル">
            <GISPanel view={view} saveViewSettingsHandler={saveViewSettingsHandler} />
          </CollapsePanel>
          {!!distanceLayersPoints.length && (
            <>
              <Center h={2} w="100%">
                <Divider variant="layer" />
              </Center>
              <CollapsePanel title="距離">
                <DistancePanel
                  distanceLayersPoints={distanceLayersPoints}
                  updateVisibility={updateDistanceLayerVisibility}
                  deleteLayer={deleteDistanceLayer}
                  focusedLayerIndex={focusedDistanceLayerIndex}
                  setFocusedLayerIndex={setFocusedDistanceLayerIndex}
                />
              </CollapsePanel>
            </>
          )}
          {!!areaLayersPoints.length && (
            <>
              <Center h={2} w="100%">
                <Divider variant="layer" />
              </Center>
              <CollapsePanel title="面積">
                <AreaPanel
                  areaLayersPoints={areaLayersPoints}
                  updateVisibility={updateAreaLayerVisibility}
                  deleteLayer={deleteAreaLayer}
                  focusedLayerIndex={focusedAreaLayerIndex}
                  setFocusedLayerIndex={setFocusedAreaLayerIndex}
                />
              </CollapsePanel>
            </>
          )}
          {!!commentLayersPoints.length && (
            <>
              <Center h={2} w="100%">
                <Divider variant="layer" />
              </Center>
              <CollapsePanel title="コメント">
                <CommentPanel
                  commentLayersPoints={commentLayersPoints}
                  updateVisibility={updateCommentLayerVisibility}
                  deleteLayer={openDeleteCommentConfirmModalHandler}
                  focusedLayerIndex={focusedCommentLayerIndex}
                  setFocusedLayerIndex={setFocusedCommentLayerIndex}
                  selectedLayerIndex={selectedCommentLayerIndex}
                  setSelectedLayerIndex={setSelectedCommentLayerIndex}
                  loading={isCommentLayerLoading}
                />
              </CollapsePanel>
            </>
          )}
        </Box>
      </Flex>
    </VStack>
  );
};

export const InfoPanels = forwardRef(InfoPanelsFunction);
