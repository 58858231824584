// third party modules
import proj4 from 'proj4';

// assets, config
import { Cartesian } from '../config/interfaces/cesium';
import { Position } from '../config/interfaces/views';
import { WGS84_EPSG, JP_EPSG_LIST } from '../config/epsg';

// services
import { calcRound } from './Util';

// define wgs84 projection
proj4.defs(WGS84_EPSG.alias, WGS84_EPSG.projection);

// define jp projections
JP_EPSG_LIST.forEach((epsg) => {
  proj4.defs(epsg.alias, epsg.projection);
});

/**
 * 緯度経度から平面直角座標系に変更
 * @param cartesianNo
 * @param position longitude, latitude, height
 * @returns x, y, h Cartesian
 */
export const toCartesian = (cartesianNo: string, position: Position): Cartesian => {
  const [_y, _x] = proj4('wgs84', `jp_${cartesianNo}`, [position.longitude, position.latitude]);

  return {
    x: calcRound(_x, 2),
    y: calcRound(_y, 2),
    h: calcRound(position.height, 2),
  };
};

/**
 * 平面直角座標系から緯度経度に変更
 * @param cartesianNo
 * @param cartesian x, y, h
 * @returns position longitude, latitude, height
 */
export const toDegrees = (cartesianNo: string, cartesian: Cartesian): Position => {
  const [_longitude, _latitude] = proj4(`jp_${cartesianNo}`, 'wgs84').forward([cartesian.y, cartesian.x]);
  return {
    longitude: calcRound(_longitude, 7),
    latitude: calcRound(_latitude, 7),
    height: calcRound(cartesian.h, 2),
  };
};

/**
 * 日本平面直角座標系の番号配列を返す
 * @returns number[]
 */
export const returnCartesianNo = (): number[] => {
  const cartesianNoArray: number[] = [...Array<number>(JP_EPSG_LIST.length)].map((_, i) => i + 1);
  return cartesianNoArray;
};
