import { useCallback, useEffect, useRef, useState } from 'react';
import { Boring } from '../config/interfaces/views';
import { GetBorings, DeleteBoring, GetSharedBorings } from '../services/View/View';

const useBoring = (view_id: string | undefined, authToken: string | null, forSharedView?: boolean) => {
  const [borings, setBorings] = useState<Boring[]>([]);
  const AuthGetBorings = forSharedView ? GetSharedBorings : GetBorings;

  // get borings from db
  const fetchBorings = useCallback(async () => {
    console.log('fetchBorings');
    if (authToken !== null && view_id) {
      const allBorings = await AuthGetBorings(authToken, view_id);
      setBorings(allBorings);
    }
  }, [AuthGetBorings, authToken, view_id]);

  useEffect(() => {
    void fetchBorings();
  }, [view_id, fetchBorings]);

  // constrol modal ref
  const deleteBoringConfirmModalRef = useRef<{ openModal: (deleteBoringIndex: number) => void }>();

  const updateBoringVisibility = (indexInProperty: number, visibility: boolean) => {
    const newBorings = [...borings];
    const arrayIndex = newBorings.findIndex(({ index }) => index === indexInProperty);
    newBorings[arrayIndex].show = visibility;
    setBorings(newBorings);
    console.log('update boring visibility', borings);
  };

  const deleteBoring = async (indexInProperty: number) => {
    if (
      authToken === null ||
      !view_id ||
      indexInProperty < 0 ||
      !borings.some(({ index }) => index === indexInProperty)
    ) {
      return;
    }
    const newBorings = [...borings];
    const arrayIndex = borings.findIndex(({ index }) => index === indexInProperty);
    newBorings.splice(arrayIndex, 1);
    setBorings(newBorings);
    await DeleteBoring(authToken, view_id, indexInProperty);
  };

  const addBorings = (boringArray: Boring[]) => {
    console.log('addborings in useBoring', boringArray);
    setBorings(boringArray);
  };

  const getBorings = () => borings;

  const openDeleteBoringConfirmModal = (index: number): void => deleteBoringConfirmModalRef.current?.openModal(index);

  return {
    deleteBoringConfirmModalRef,
    borings,
    updateBoringVisibility,
    deleteBoring,
    addBorings,
    getBorings,
    setBorings,
    fetchBorings,
    openDeleteBoringConfirmModal,
  };
};

export default useBoring;
