// react modules
import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  VStack,
  Text,
} from '@chakra-ui/react';

// third party modules

// assets, config
import { CloseIcon } from '../../../../../assets/icons/Index';
import { Orientation, Position } from '../../../../../config/interfaces/views';
import { ACTION_PANEL_WIDTH, LAYER_ACTION_PANEL_STYLES } from '../../../../../config/styles';
import {
  CARTESIAN_X_INPUT_STEP,
  CARTESIAN_Y_INPUT_STEP,
  HEIGHT_INPUT_STEP,
  LATITUDE_INPUT_MAX,
  LATITUDE_INPUT_MIN,
  LATITUDE_INPUT_STEP,
  LONGITUDE_INPUT_MAX,
  LONGITUDE_INPUT_MIN,
  LONGITUDE_INPUT_STEP,
  PITCH_INPUT_MAX,
  PITCH_INPUT_MIN,
  PITCH_INPUT_STEP,
  ROLL_INPUT_MAX,
  ROLL_INPUT_MIN,
  ROLL_INPUT_STEP,
  YAW_INPUT_MAX,
  YAW_INPUT_MIN,
  YAW_INPUT_STEP,
} from '../../../../../config/constants';

// services
import { _ } from '../../../../../services/Util';
import { toCartesian, returnCartesianNo, toDegrees } from '../../../../../services/Proj4';
import { returnFloatWithinRange } from '../../../../../services/Validation';

// 日本平面直角座標系番号配列を取得
const cartesianNos = returnCartesianNo();

export const AssetPositionOrientationPanel: React.FC<{
  assetPosition: Position;
  assetOrientation: Orientation;
  isEditable: boolean;
  closeHandler: () => void;
  editedPositionOrientationHandler: (assetPosition: Position, assetOrientation: Orientation) => void;
  savePositionOrientationHandler: () => void;
}> = ({
  assetPosition,
  assetOrientation,
  isEditable,
  closeHandler,
  editedPositionOrientationHandler,
  savePositionOrientationHandler,
}) => {
  const { longitude, latitude, height } = assetPosition;
  const { pitch, yaw, roll } = assetOrientation;

  // 平面直角座標系番号管理変数
  const [_cartesianNo, _setCartesianNo] = useState('9');

  // 平面直角座標変更ファンクション
  const changeCartesian = (value: number, cartesianName: string) => {
    try {
      // create new cartesian
      const newCartesian = toCartesian(_cartesianNo, assetPosition);
      newCartesian[cartesianName] = value;

      // create new position from new cartesian
      const newPosition = toDegrees(_cartesianNo, newCartesian);
      editedPositionOrientationHandler(newPosition, assetOrientation);
    } catch (err) {
      console.error(err);
      alert('座標変換処理でエラーが発生しました。\n時間を置いてから再度お試しください。');
    }
  };

  return (
    <Box w={ACTION_PANEL_WIDTH} pos="absolute" right={0} zIndex={999} textAlign="center" {...LAYER_ACTION_PANEL_STYLES}>
      {/* 描画位置・向き設定 */}

      <Box textAlign="right">
        <Icon
          as={CloseIcon}
          cursor="pointer"
          onClick={() => {
            if (isEditable) closeHandler();
          }}
        />
      </Box>
      <VStack spacing={4} alignItems="left">
        <Box>
          <Heading as="h4" size="xs">
            描画位置・向き設定
          </Heading>
        </Box>
        <VStack overflowY="auto" maxHeight="300px">
          <Box>
            <Text fontSize="xs" align="left">
              地点クリックでも指定できます。
            </Text>
          </Box>

          <Box>
            <Tabs size="sm" isFitted>
              <TabList>
                <Tab fontSize="xs" pr={2} pl={2}>
                  緯度経度
                </Tab>
                <Tab fontSize="xs" pr={2} pl={2}>
                  平面直角座標
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel id="long-lat-panel" pr={0} pl={0}>
                  <VStack>
                    <FormControl id="longitude">
                      <FormLabel fontSize="xs">経度 (-180° ~ 180°)</FormLabel>
                      <Input
                        type="number"
                        textAlign="right"
                        size="sm"
                        value={longitude}
                        step={LONGITUDE_INPUT_STEP}
                        onChange={(e) => {
                          const newPosition = _.cloneDeep(assetPosition);
                          if (e.target.value) {
                            const parsedValue = parseFloat(e.target.value);
                            if (parsedValue < LONGITUDE_INPUT_MIN) {
                              newPosition.longitude = LONGITUDE_INPUT_MIN;
                            } else if (parsedValue > LONGITUDE_INPUT_MAX) {
                              newPosition.longitude = LONGITUDE_INPUT_MAX;
                            } else {
                              newPosition.longitude = parsedValue;
                            }
                          } else {
                            newPosition.longitude = 0;
                          }

                          editedPositionOrientationHandler(newPosition, assetOrientation);
                        }}
                      />
                    </FormControl>

                    <FormControl id="latitude">
                      <FormLabel fontSize="xs">緯度 (-90° ~ 90°)</FormLabel>
                      <Input
                        type="number"
                        textAlign="right"
                        size="sm"
                        value={latitude}
                        step={LATITUDE_INPUT_STEP}
                        onChange={(e) => {
                          const newPosition = _.cloneDeep(assetPosition);

                          if (e.target.value) {
                            const parsedValue = parseFloat(e.target.value);
                            if (parsedValue < LATITUDE_INPUT_MIN) {
                              newPosition.latitude = LATITUDE_INPUT_MIN;
                            } else if (parsedValue > LATITUDE_INPUT_MAX) {
                              newPosition.latitude = LATITUDE_INPUT_MAX;
                            } else {
                              newPosition.latitude = parsedValue;
                            }
                          } else {
                            newPosition.latitude = 0;
                          }
                          editedPositionOrientationHandler(newPosition, assetOrientation);
                        }}
                      />
                    </FormControl>

                    <FormControl id="height">
                      <FormLabel fontSize="xs">高さ (m)</FormLabel>
                      <Input
                        type="number"
                        textAlign="right"
                        size="sm"
                        value={height}
                        step={HEIGHT_INPUT_STEP}
                        onChange={(e) => {
                          const newPosition = _.cloneDeep(assetPosition);
                          if (e.target.value) {
                            newPosition.height = parseFloat(e.target.value);
                          } else {
                            newPosition.height = 0;
                          }
                          editedPositionOrientationHandler(newPosition, assetOrientation);
                        }}
                      />
                    </FormControl>
                  </VStack>
                </TabPanel>

                <TabPanel id="cartesian-panel" pr={0} pl={0}>
                  <VStack>
                    <FormControl id="cartesian-x">
                      <FormLabel fontSize="xs">系番号</FormLabel>
                      <Select defaultValue={9} size="sm" onChange={(no) => _setCartesianNo(no.target.value)}>
                        {cartesianNos.map((val: number) => (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl id="cartesian-x">
                      <FormLabel fontSize="xs">X座標 (ｍ)</FormLabel>
                      <Input
                        type="number"
                        textAlign="right"
                        size="sm"
                        value={toCartesian(_cartesianNo, assetPosition).x}
                        step={CARTESIAN_X_INPUT_STEP}
                        onChange={(e) => {
                          if (e.target.value) {
                            changeCartesian(parseFloat(e.target.value), 'x');
                          } else {
                            changeCartesian(0, 'x');
                          }
                        }}
                      />
                    </FormControl>

                    <FormControl id="cartesian-y">
                      <FormLabel fontSize="xs">Y座標 (ｍ)</FormLabel>

                      <Input
                        type="number"
                        textAlign="right"
                        size="sm"
                        value={toCartesian(_cartesianNo, assetPosition).y}
                        step={CARTESIAN_Y_INPUT_STEP}
                        onChange={(e) => {
                          if (e.target.value) {
                            changeCartesian(parseFloat(e.target.value), 'y');
                          } else {
                            changeCartesian(0, 'y');
                          }
                        }}
                      />
                    </FormControl>

                    <FormControl id="cartesian-h">
                      <FormLabel fontSize="xs">高さ (m)</FormLabel>
                      <Input
                        type="number"
                        textAlign="right"
                        size="sm"
                        value={toCartesian(_cartesianNo, assetPosition).h}
                        step={HEIGHT_INPUT_STEP}
                        onChange={(e) => {
                          if (e.target.value) {
                            changeCartesian(parseFloat(e.target.value), 'h');
                          } else {
                            changeCartesian(0, 'h');
                          }
                        }}
                      />
                    </FormControl>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>

          <FormControl id="roll">
            <FormLabel fontSize="xs">x軸回転</FormLabel>
            <HStack>
              <Slider
                mr={2}
                aria-label="roll-slider"
                defaultValue={roll}
                value={roll}
                focusThumbOnChange={false}
                min={ROLL_INPUT_MIN}
                max={ROLL_INPUT_MAX}
                step={ROLL_INPUT_STEP}
                onChange={(val) => {
                  const newOrientation = _.cloneDeep(assetOrientation);
                  newOrientation.roll = val;
                  editedPositionOrientationHandler(assetPosition, newOrientation);
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb borderColor="gray.500" />
              </Slider>

              <Input
                w="88px"
                type="number"
                textAlign="right"
                size="sm"
                value={roll}
                step={ROLL_INPUT_STEP}
                onChange={(e) => {
                  const newOrientation = _.cloneDeep(assetOrientation);

                  const floatValue = returnFloatWithinRange(e.target.value, ROLL_INPUT_MIN, ROLL_INPUT_MAX);
                  if (_.isNull(floatValue)) {
                    newOrientation.roll = 0;
                  } else {
                    newOrientation.roll = floatValue;
                  }

                  editedPositionOrientationHandler(assetPosition, newOrientation);
                }}
              />
            </HStack>
          </FormControl>

          <FormControl id="pitch">
            <FormLabel fontSize="xs">y軸回転</FormLabel>
            <HStack>
              <Slider
                mr={2}
                aria-label="pitch-slider"
                defaultValue={pitch}
                value={pitch}
                focusThumbOnChange={false}
                min={PITCH_INPUT_MIN}
                max={PITCH_INPUT_MAX}
                step={PITCH_INPUT_STEP}
                onChange={(val) => {
                  const newOrientation = _.cloneDeep(assetOrientation);
                  newOrientation.pitch = val;
                  editedPositionOrientationHandler(assetPosition, newOrientation);
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb borderColor="gray.500" />
              </Slider>

              <Input
                w="88px"
                type="number"
                textAlign="right"
                size="sm"
                value={pitch}
                step={PITCH_INPUT_STEP}
                onChange={(e) => {
                  const newOrientation = _.cloneDeep(assetOrientation);

                  const floatValue = returnFloatWithinRange(e.target.value, PITCH_INPUT_MIN, PITCH_INPUT_MAX);
                  if (_.isNull(floatValue)) {
                    newOrientation.pitch = 0;
                  } else {
                    newOrientation.pitch = floatValue;
                  }

                  editedPositionOrientationHandler(assetPosition, newOrientation);
                }}
              />
            </HStack>
          </FormControl>

          <FormControl id="yaw">
            <FormLabel fontSize="xs">z軸回転</FormLabel>
            <HStack>
              <Slider
                mr={2}
                aria-label="yaw-slider"
                defaultValue={yaw}
                value={yaw}
                focusThumbOnChange={false}
                min={YAW_INPUT_MIN}
                max={YAW_INPUT_MAX}
                step={YAW_INPUT_STEP}
                onChange={(val) => {
                  const newOrientation = _.cloneDeep(assetOrientation);
                  newOrientation.yaw = val;
                  editedPositionOrientationHandler(assetPosition, newOrientation);
                }}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb borderColor="gray.500" />
              </Slider>

              <Input
                w="88px"
                type="number"
                textAlign="right"
                size="sm"
                value={yaw}
                step={YAW_INPUT_STEP}
                onChange={(e) => {
                  const newOrientation = _.cloneDeep(assetOrientation);

                  const floatValue = returnFloatWithinRange(e.target.value, YAW_INPUT_MIN, YAW_INPUT_MAX);
                  if (_.isNull(floatValue)) {
                    newOrientation.yaw = 0;
                  } else {
                    newOrientation.yaw = floatValue;
                  }

                  editedPositionOrientationHandler(assetPosition, newOrientation);
                }}
              />
            </HStack>
          </FormControl>
        </VStack>
        <Box>
          <Button
            w="100%"
            color="white"
            rounded="md"
            _hover={{ bg: useColorModeValue('blue.500', 'gray.500') }}
            bg={useColorModeValue('blue.500', 'gray.500')}
            onClick={() => savePositionOrientationHandler()}
            isLoading={!isEditable}
          >
            保存
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};
