import { Box, Flex, HStack, IconButton, Spacer, Text, Tooltip } from '@chakra-ui/react';
import { HideIcon, MoreHorizontalIcon, ShowIcon } from '../../../../assets/icons/Index';
import { Asset } from '../../../../config/interfaces/views';
import { LAYER_ITEM_STYLES } from '../../../../config/styles';
import { _ } from '../../../../services/Util';
import { ActionListPanel } from './actionPanels/ListPanel';

export const DataPanelItem: React.FC<{
  asset: Asset;
  editAssetStatus: { assetId: number | null; activeActionPanelName: string };
  downloadAssetHandler: () => void;
  editedAssetHandler: (asset: Asset) => void;
  moveCameraHandler: (vectorType: string) => void;
  changeEditAssetStatusHandler: (nextAssetId: number | null, actionPanelName: string) => void;
}> = ({
  asset,
  editAssetStatus,
  editedAssetHandler,
  downloadAssetHandler,
  moveCameraHandler,
  changeEditAssetStatusHandler,
}) => {
  const { asset_settings, asset_type } = asset;
  const { asset_name, style, disallows_share_download } = asset_settings;
  const { visible } = asset_settings.style;

  const SHOW_ASSET_NAME_LENGTH = 20;
  return (
    <Box w="100%" pos="relative">
      <Flex w="100%" alignItems="center" {...LAYER_ITEM_STYLES}>
        <Tooltip label={asset_name.length > SHOW_ASSET_NAME_LENGTH ? asset_name : ''}>
          <Text pl={2}>{`${
            asset_name.length > SHOW_ASSET_NAME_LENGTH
              ? `${asset_name.substring(0, SHOW_ASSET_NAME_LENGTH - 1)}...`
              : asset_name
          }`}</Text>
        </Tooltip>
        <Spacer />
        <HStack spacing={0}>
          {/* 24px */}
          {/* visible button */}
          <IconButton
            aria-label={visible ? 'show' : 'hide'}
            fontSize="sm"
            icon={visible ? <ShowIcon /> : <HideIcon />}
            onClick={() => {
              const newAsset = _.clone(asset);
              newAsset.asset_settings.style.visible = !visible;
              editedAssetHandler(newAsset);
            }}
            size="xs"
            variant="toolbarIcon"
          />

          {/* more actions button */}
          <IconButton
            aria-label="more"
            fontSize="sm"
            icon={<MoreHorizontalIcon />}
            onClick={() => {
              if (editAssetStatus.assetId === asset.asset_id) {
                changeEditAssetStatusHandler(null, '');
              } else {
                changeEditAssetStatusHandler(asset.asset_id, 'actionList');
              }
            }}
            size="xs"
            variant="toolbarIcon"
          />
        </HStack>
      </Flex>
      {/* more actions panel */}
      {editAssetStatus.assetId === asset.asset_id && editAssetStatus.activeActionPanelName === 'actionList' && (
        <ActionListPanel
          assetType={asset_type!}
          downloadAssetHandler={downloadAssetHandler}
          moveCameraHandler={moveCameraHandler}
          disallows_share_download={disallows_share_download}
        />
      )}
    </Box>
  );
};
