/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// react modules
import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  forwardRef,
  HStack,
  IconButton,
  Input,
  Link,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Cartesian3, Cartographic, sampleTerrain, ScreenSpaceEventHandler, Viewer } from 'cesium';
import { cloneDeep } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FolderIcon } from '../../../../assets/icons/Index';
import { UPLOAD_LIMIT_FILE_SIZE_BYTE, UPLOAD_LIMIT_FILE_SIZE_GIGABYTE } from '../../../../config/constants';
import { JP_EPSG_LIST, SG_EPSG_LIST } from '../../../../config/epsg';
import { CapacityStatus } from '../../../../config/interfaces/util';
import { Asset, Position, View } from '../../../../config/interfaces/views';
import { GetSignedUrlForUploadAsset } from '../../../../services/AWS/S3';
import { FileUpload } from '../../../../services/FileUpload';
import {
  ErrorCodes,
  GetCoordinateFromImage,
  ImageProcessingError,
  ScaleAndStripImage,
} from '../../../../services/Image';
import { checkSize } from '../../../../services/Validation';
import { FileRow, FileRowData, State as FileRowState, State } from './_components/FileRow';
import { SelectPositionDialog, SelectPositionDialogForwardRef } from './_components/SelectPositionDialog';

const isFulfilled = <T,>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> => p.status === 'fulfilled';

// EPGS list for add-asset modal
const EPSG_LIST = [...JP_EPSG_LIST, ...SG_EPSG_LIST];

// upload available extensions
const extensions = '.las, .e57, .ply, .pts, .xyz, .xyzrgb, .fbx, .obj, .gltf, .tif, .tiff, .kml, .geojson, .zip, .jpg';

// ファイル追加モーダルコンポーネント
const AddAssetModalFunction: React.ForwardRefRenderFunction<
  // 親コンポーネントでの呼び出し用ファンクション群定義
  {
    openModal: () => void;
  },
  // 親コンポーネントから伝搬される変数・ファンクション群定義
  {
    capacityStatus: CapacityStatus | undefined;
    view: View | undefined;
    setIsToolDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    cesiumViewer: Viewer | undefined;
    cesiumScreenSpaceEventHandler: ScreenSpaceEventHandler | undefined;
    reloadView: () => Promise<void>;
    tilingAsset: Asset | null;
    isEditable: boolean;
  }
> = (
  {
    capacityStatus,
    view,
    setIsToolDisabled,
    cesiumViewer,
    cesiumScreenSpaceEventHandler,
    reloadView,
    tilingAsset,
    isEditable,
  },
  componentRef
) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm();

  const [crs, setCrs] = useState('');
  const selectPositionDialogRef = useRef<SelectPositionDialogForwardRef>(null);
  const [selectingLocationFile, setSelectingLocationFile] = useState<FileRowData | null>(null);
  const [files, setFiles] = useState<Array<FileRowData>>([]);
  const [message, setMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const { capacity, usage, capacityInGigabyte } = capacityStatus || {
    capacity: 0,
    capacityInGigabyte: '0',
    usage: 0,
    usageInGigabyte: '0',
    progressValue: 0,
  };

  // file upload control form
  const inputRef = useRef<HTMLInputElement>();
  const { ref: fileRef, ...field } = register('fileUpload');

  const updateFileData = useCallback(
    (updated: FileRowData) => {
      setFiles((rows) =>
        rows.map((file) => {
          if (updated.fileInfo?.originFilename === file.fileInfo?.originFilename) {
            return { ...file, ...updated };
          }

          return cloneDeep(file);
        })
      );
    },
    [setFiles]
  );

  // add asset process
  const onSubmit = async () => {
    if (isUploading) return;

    // Make sure we have the user
    if (!user || !user.sub || !view) {
      alert('認証情報に不備があります。\nログインし直して再度お試しください。');
      return;
    }

    // Make sure user has actually chosen some files
    if (files.length === 0) {
      setError('fileUpload', {
        type: 'invalid',
        message: 'データを選択してください',
      });
      return;
    }

    // Filter files and only process ones pending for download
    let filesToProcess = files.filter((row) => [State.PENDING, State.ERROR].indexOf(row.state) >= 0);

    // If there are no files to process, no need to proceed any further
    if (filesToProcess.length === 0) {
      setError('fileUpload', {
        type: 'invalid',
        message: 'データを選択してください',
      });
      return;
    }

    // Another round of file validation. Files that requires CRS selection are validated here.
    filesToProcess = filesToProcess.map((row) => {
      const updated = cloneDeep(row);

      // validate file info
      if (!updated.fileInfo) {
        updated.error = '入力に不備があります。\n画面を更新して再度お試しください。';
        updateFileData(updated);
      } else if (updated.requireCrs && !crs) {
        // validate CRS has been selected
        updated.error = '座標情報を選択してください。';
        updateFileData(updated);
      }

      return updated;
    });

    // If there are still files with ERROR status, don't start upload.
    if (
      filesToProcess.reduce<number>(
        (previous, current) => (current.error !== '' || current.missingLocation ? previous + 1 : previous),
        0
      ) > 0
    )
      return;

    // Disable form to prevent multiple trigger 
    setIsUploading(true);

    // Finally, once all errors are cleared, begin upload process
    const uploadPromises = filesToProcess.map(async (row: FileRowData, index): Promise<[FileUpload, FileRowData]> => {
      // Catch and update errors immediately. If we don't do it here,
      // errors will have to wait until all uploads are done before
      // it could be shown to the user.
      try {
        let runningFile = cloneDeep(row);

        // mark as uploading and remove any existing errors
        runningFile.state = FileRowState.UPLOADING;
        runningFile.error = '';
        updateFileData(runningFile);

        // If it's an image, scale and strip EXIF data out of it first
        if (runningFile.fileInfo?.contentType === 'image/jpeg') {
          runningFile.file = await ScaleAndStripImage(runningFile.file);
        }

        // Upload the file
        const fileUpload = new FileUpload(runningFile.file, runningFile.fileInfo, crs);
        const uploadUrl = await GetSignedUrlForUploadAsset(
          await getAccessTokenSilently(),
          runningFile.fileInfo!.filename,
          runningFile.fileInfo!.contentType
        );

        await fileUpload.upload(uploadUrl, (progress) => {
          updateFileData((runningFile = { ...runningFile, progress }));
        });

        return Promise.resolve([fileUpload, runningFile]);
      } catch (err) {
        updateFileData({
          ...row,
          state: FileRowState.ERROR,
          error: err instanceof Error ? err.message : (err as string),
        });
        return Promise.reject();
      }
    });

    try {
      // Only get fulfilled results. Rejected operations are already handled earlier.
      const results = await Promise.allSettled(uploadPromises);
      const fulfilled = results.filter(isFulfilled).map((p) => p.value);

      // For every fulfilled uploads, run post-process over them.
      // This have to be separated and run sequentially due to the API's inability to handle concurrent writes.
      await fulfilled.reduce(
        (p, [fileUpload, file]) =>
          p.then(async () => {
            const runningFile = cloneDeep(file);
            runningFile.progress = 0;

            // Change the progress back to indefinite by setting it back to 0
            updateFileData(runningFile);

            return fileUpload
              .postProcess(await getAccessTokenSilently(), view, user, runningFile.position!)
              .then(() => updateFileData({ ...runningFile, state: FileRowState.SUCCESS, error: '' }))
              .catch((err) =>
                updateFileData({
                  ...runningFile,
                  state: FileRowState.ERROR,
                  error: err instanceof Error ? err.message : (err as string),
                })
              );
          }),
        Promise.resolve()
      );

      if (fulfilled.length) {
        setMessage('アップロードが完了しました。このウィンドウを閉じるか更にファイルを追加できます。');
        setIsUploading(false);
        await reloadView();
      }
    } catch (err) {
      setIsUploading(false);
      setError(
        'custom',
        { type: 'custom', message: err instanceof Error ? err.message : (err as string) },
        { shouldFocus: false }
      );
    }
  };

  // the event when change file input
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    // Clear message. Generally will be needed if user has finished uploading something,
    // but wants to add some more.
    setMessage('');

    // Convert all into FileRowData first for easy processing
    const fileRows = Array.from(e.target.files).map((targetFile) => new FileRowData(targetFile));
    e.target.value = '';

    try {
      // Filter files so only images are allowed to be multiple-uploaded.
      // Other Cesium-based assets are not allowed.
      // Make sure to only check files that have not been successfully uploaded.
      const found = files
        .concat(fileRows)
        .filter((file) => file.state !== State.SUCCESS)
        .reduce<number>(
          (previous, current) => (current.fileInfo?.contentType !== 'image/jpeg' ? previous + 1 : previous),
          0
        );
      if (found > 1)
        throw new Error(
          'Please select only one 1 asset at a time. Only images are allowed to be uploaded multiple at the same time.'
        );

      // Merge with original set of files
      setFiles(files.concat(await Promise.all(fileRows.map(queueFile))));
    } catch (err) {
      if (err instanceof Error) {
        setError('fileUpload', {
          type: 'invalid',
          message: err.message,
        });
      }
    }
  };

  // Queue file to be uploaded
  const queueFile = async (row: FileRowData) => {
    // const fileRow = new FileRowData(targetFile);
    const fileRow = cloneDeep(row);

    // Anything other than image can only be handled one at a time.
    // If another is selected, reject it.
    if ((tilingAsset || !isEditable) && fileRow.fileInfo?.contentType !== 'image/jpeg') {
      return Promise.reject(
        new Error(
          tilingAsset
            ? '現在、ファイルを追加中です。完了後に再度ご利用ください。'
            : '現在、ファイルを更新中です。完了後に再度ご利用ください。'
        )
      );
    }

    // Reject files if they are already added
    const exists = files.find(
      (data: FileRowData) => fileRow.fileInfo?.originFilename === data.fileInfo?.originFilename
    );
    if (exists) {
      return Promise.reject(new Error(`ファイル '${fileRow.fileInfo!.originFilename}' は既に追加されています。`));
    }

    // Image validation are done immediately
    if (fileRow.fileInfo?.contentType === 'image/jpeg') {
      try {
        fileRow.position = await GetCoordinateFromImage(fileRow.file);
        fileRow.position.clamp_to_ground = true; // Clamped to ground by default
      
        if (cesiumViewer) {
          await sampleTerrain(cesiumViewer.terrainProvider, 13, [Cartographic.fromDegrees(fileRow.position.longitude, fileRow.position.latitude, 0)]).then(
            (res) => {
              if (fileRow.position) fileRow.position.height = (res[0]?.height || 0);
            }
          );
        }
      } catch (err) {
        if (err instanceof ImageProcessingError) {
          if (err.code === ErrorCodes.MISSING_GPS) {
            fileRow.missingLocation = true;
          } else if (err.code === ErrorCodes.UNRECOVERABLE) {
            // there's nothing user can do if this happens, outright reject it.
            return Promise.reject(new Error('画像ファイルの読み込みに失敗しました。別の画像をお試しください。'));
          }
        } else if (err instanceof Error) {
          fileRow.error = err.message;
        }
      }
    }

    // check capacity
    if (fileRow.file.size + usage > capacity) {
      return Promise.reject(new Error(`アップロードファイルの合計が${capacityInGigabyte}を超えています。`));
    }

    clearErrors('fileUpload');

    // check file size
    if (!checkSize(fileRow.file.size, UPLOAD_LIMIT_FILE_SIZE_BYTE)) {
      return Promise.reject(new Error(`${UPLOAD_LIMIT_FILE_SIZE_GIGABYTE}GB以下のファイルを選択してください。`));
    }

    return fileRow;
  };

  // Remove an added file
  const handleRemoveFile = (file: FileRowData): void => {
    setFiles([...files.filter((existingFile: FileRowData) => existingFile !== file)]);
  };

  // Clear or add errors once CRS is selected
  const handleCrsSelected = (e: ChangeEvent<HTMLSelectElement>) => {
    setCrs(e.target.value);
    setFiles(
      files.map((file) => {
        const updated = cloneDeep(file);
        if (updated.requireCrs) {
          updated.error = e.target.value ? '' : '座標情報を選択してください。';
        }

        return updated;
      })
    );
  };

  // Custom event handler for modal closing
  const onModalClose = useCallback(() => {
    onClose();
    setFiles(files.filter((file) => file.state !== State.SUCCESS));
    reset();
  }, [files, setFiles, reset, onClose]);

  useEffect(() => {
    if (!selectingLocationFile) return;

    onClose();
    selectPositionDialogRef.current?.openModal();
  }, [selectingLocationFile, onClose]);

  const setManualPosition = useCallback(
    (newPosition: Position | null) => {
      onOpen();

      // Only update if received a position.
      // User cancellation will give null for newPosition
      if (newPosition) {
        // set location to fileRow
        setFiles(
          files.map((file) => {
            const newFile = cloneDeep(file);

            if (file === selectingLocationFile) {
              newFile.position = newPosition;

              if (newFile.missingLocation) {
                newFile.missingLocation = false;
                newFile.error = '';
              }
            }

            return newFile;
          })
        );
      }

      setSelectingLocationFile(null);
    },
    [files, selectingLocationFile, onOpen]
  );

  // 親コンポーネントから呼び出されるファンクション群の内部処理
  useImperativeHandle(componentRef, () => ({
    openModal() {
      // Clear messages if one was set and modal re-opened
      setMessage('');
      onOpen();
    },
  }));

  // Toggle toolbar hotkeys as the modal is opened/closed
  useEffect(() => {
    if (isOpen) {
      setIsToolDisabled(true);
      return;
    }

    if (!selectingLocationFile) {
      setIsToolDisabled(false);
    }
  }, [isOpen, selectingLocationFile, setIsToolDisabled]);

  return (
    <>
      <SelectPositionDialog
        ref={selectPositionDialogRef}
        setManualPosition={setManualPosition}
        cesiumViewer={cesiumViewer}
      />

      <Modal isOpen={isOpen} onClose={onModalClose} trapFocus={false} size="xl">
        <ModalOverlay />
        <ModalContent minH="334px">
          <ModalHeader>ファイルを追加</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <FormControl id="fileUpload" isInvalid={errors.fileUpload} mt={4}>
                {/* Header and upload button */}
                <FormLabel htmlFor="file">
                  <Flex w="100%" alignItems="center">
                    <Text>追加するファイル</Text>
                    <Spacer />
                    <HStack spacing={0}>
                      {/* 24px */}
                      <Input
                        hidden
                        {...field}
                        ref={(instance: HTMLInputElement) => {
                          fileRef(instance);
                          inputRef.current = instance;
                        }}
                        type="file"
                        multiple
                        accept={extensions}
                        onChange={handleFileChange}
                      />
                      <IconButton
                        aria-label="add-file"
                        fontSize="lg"
                        icon={<FolderIcon />}
                        onClick={() => inputRef.current?.click()}
                        size="s"
                        backgroundColor="transparent"
                        _hover={{ backgroundColor: 'transparent' }}
                      />
                    </HStack>
                  </Flex>
                </FormLabel>

                {/* File list after selection */}
                <List>
                  {files.map((data: FileRowData) => (
                    <FileRow
                      key={data.fileInfo?.originFilename}
                      data={data}
                      onRemoveFile={handleRemoveFile}
                      setSelectingLocationFile={setSelectingLocationFile}
                    />
                  ))}
                </List>

                <FormHelperText>
                  .las / .e57 / .ply / .pts / .xyz / .xyzrgb / .fbx / .obj / .gltf / .tiff / .kml / .geojson /
                  .zip(shapefile) / .jpg
                </FormHelperText>
                {errors.fileUpload && <Alert status="error">{errors.fileUpload.message}</Alert>}
                {message && <Alert status="info">{message}</Alert>}
              </FormControl>
              <Controller
                control={control}
                name="crs"
                defaultValue=""
                render={({ field: { ref, ...restField } }) => (
                  <FormControl id="crs" mt={4}>
                    <FormLabel htmlFor="crs">座標情報</FormLabel>
                    <Select
                      placeholder="las, e57, ply, pts, xyz, xyzrgb, zip(shapefile)の場合選択してください。"
                      {...restField}
                      ref={ref}
                      value={crs}
                      onChange={handleCrsSelected}
                    >
                      {EPSG_LIST.map((epsg) => (
                        <option key={epsg.epsg} value={epsg.epsg}>
                          {epsg.description}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText>元ファイルに座標情報が含まれる場合はそちらが優先されます。</FormHelperText>
                  </FormControl>
                )}
              />
              <Text fontSize="xs" mt={1}>
                点群が公共座標の場合、系の指定で正しい位置に描画されます。
                <br />
                <Link variant="underline" href="https://www.gsi.go.jp/sokuchikijun/jpc.html" isExternal>
                  平面直角座標系について
                </Link>
              </Text>
            </ModalBody>
            <ModalFooter mt={8}>
              <Button me={3} py={2} minW="100px" onClick={onModalClose}>
                閉じる
              </Button>

              <Button colorScheme="primary" minW="100px" type="submit" py={2} disabled={isUploading}>
                追加
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export const AddAssetModal = forwardRef(AddAssetModalFunction);
