import { Flex, Square, Tooltip } from '@chakra-ui/react';

const ToolbarButton: React.FC<{
  isActive: boolean;
  icon: React.ReactElement;
  label: string;
  showBadge?: boolean;
  onClick: () => void;
}> = ({ isActive, icon, label, showBadge, onClick }) => (
  <Tooltip hasArrow placement="right" label={label} p={2} fontSize="xs" openDelay={100} fontWeight="normal">
    <Flex>
      <Square
        backgroundColor={isActive ? 'gray.600' : 'transparent'}
        color="white"
        fontSize="xl"
        onClick={onClick}
        size={8}
        position="relative"
      >
        {icon}
        {showBadge && (
          <Square
            position="absolute"
            backgroundColor="primary.500"
            borderRadius="50%"
            right={1}
            top={1}
            width={2}
            height={2}
          />
        )}
      </Square>
    </Flex>
  </Tooltip>
);

export default ToolbarButton;
