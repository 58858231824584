import { useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  FormControl,
  FormLabel,
  forwardRef,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

// config
import { View, ViewOutline } from '../../config/interfaces/views';

// services
import { ProcessErrorHandler } from '../../services/ErrorHandler';
import { SaveViewSettings } from '../../services/View/View';

const EditViewModalFunc: React.ForwardRefRenderFunction<
  {
    openModal: (viewIndex: number) => void;
  },
  {
    onViewEdited: (viewIndex: number, viewName: string) => void;
    views: View[] | ViewOutline[];
  }
> = ({ onViewEdited, views }, modalRef) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [viewIndex, setViewIndex] = useState<number>(-1);
  const [viewName, setViewName] = useState<string>('');

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = useForm();

  useImperativeHandle(modalRef, () => ({
    openModal(_viewIndex: number) {
      setViewIndex(_viewIndex);
      // eslint-disable-next-line no-underscore-dangle
      setViewName(views[_viewIndex].view_settings.view_name);
      onOpen();
    },
  }));

  // save view name process
  const onSubmit = async () => {
    // data validation
    if (!views || viewIndex < 0 || viewIndex >= views.length) {
      alert('不明なエラーが発生しました。\n画面を更新して再度お試しください。');
    } else {
      const updatedView = { ...views[viewIndex] };
      updatedView.view_settings.view_name = viewName;

      try {
        const tokenForSaveViewSettings = await getAccessTokenSilently();
        await SaveViewSettings(tokenForSaveViewSettings, updatedView.view_id, updatedView.view_settings);

        onViewEdited(viewIndex, viewName);
        onClose();
      } catch (err) {
        ProcessErrorHandler(err, 'editView');
      }
    }
  };

  return (
    <Modal closeOnOverlayClick={!isSubmitting} isOpen={isOpen} onClose={onClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>viewを編集</ModalHeader>
        <ModalCloseButton hidden={isSubmitting} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Controller
              control={control}
              name="viewName"
              defaultValue=""
              render={({ field: { ref, ...restField } }) => (
                <FormControl id="viewName">
                  <FormLabel htmlFor="viewName">view名</FormLabel>
                  <Input
                    {...restField}
                    type="text"
                    placeholder="Tokyo Station"
                    isRequired
                    textAlign="left"
                    name="viewName"
                    ref={ref}
                    value={viewName}
                    onChange={(e) => setViewName(e.target.value)}
                  />
                </FormControl>
              )}
            />
          </ModalBody>
          <ModalFooter mt={8}>
            <Button disabled={isSubmitting} me={3} py={2} minW="100px" onClick={onClose}>
              キャンセル
            </Button>

            <Button
              colorScheme="primary"
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
              loadingText="編集中..."
              minW="100px"
              type="submit"
              py={2}
            >
              確定
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export const EditViewModal = forwardRef(EditViewModalFunc);
