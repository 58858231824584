// react modules
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Center,
  Container,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// third party modules
import dayjs from 'dayjs';

// components
import Navbar from '../../components/Navbar';
import PageHeading from '../../components/PageHeading';
import { CreateViewModal } from './CreateViewModal';
import DeleteViewModal from './DeleteViewModal';
import { EditShareLinkModal } from './EditShareLinkModal';
import { EditViewModal } from './EditViewModal';

// assets, config
import { AddIcon, CopyIcon, EditIcon, HDotsIcon } from '../../assets/icons/Index';
import { environments } from '../../config/environments';
import { CapacityStatus } from '../../config/interfaces/util';
import { Asset, View, ViewOutline, ViewShareLink } from '../../config/interfaces/views';
import { CONTAINER_MAX_WIDTH } from '../../config/styles';

// services
import { ProcessErrorHandler } from '../../services/ErrorHandler';
import { CopyText, showToast } from '../../services/Util';
import { SaveAssetSettings } from '../../services/View/Asset';
import { GetCapacity } from '../../services/View/Capacity';
import { GetViews } from '../../services/View/View';
import { EditShareFileModal } from './EditShareFileModal';

export const Dashboard: React.FC = () => {
  // common hooks
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const toast = useToast();

  // manage views state
  const [_viewOutlines, setViewOutlines] = useState<ViewOutline[]>([]);

  // capacity status
  const [_capacityStatus, setCapacityStatus] = useState<CapacityStatus>({
    capacity: 0,
    capacityInGigabyte: '0',
    usage: 0,
    usageInGigabyte: '0',
    progressValue: 0,
  });

  // manage initial loading
  const [initialCompleted, setInitialCompleted] = useState(false);

  const [selectedViewIndex, setSelectedViewIndex] = useState(-1);
  const [isEditShareFileModalOpen, setIsEditShareFileModalOpen] = useState(false);

  // create modal settings
  const createViewModalRef = useRef<{ openModal: () => void }>();
  const openCreateViewModal = () => createViewModalRef.current?.openModal();

  const onViewAdded = (view: View) => {
    showToast('viewを作成しました', '', 'success', toast);

    // transition view page when completed add view
    navigate(`/view/${view.view_id}`);
  };

  // edit modal settings
  const editViewModalRef = useRef<{ openModal: (viewIndex: number) => void }>();
  const openEditModal = (viewIndex: number) => editViewModalRef.current?.openModal(viewIndex);

  const onViewEdited = (viewIndex: number, viewName: string) => {
    showToast('viewを編集しました', '', 'success', toast);

    if (viewIndex > -1) {
      // update back the status on table view
      const updatedViewOutlines = [..._viewOutlines];
      updatedViewOutlines[viewIndex] = {
        ...updatedViewOutlines[viewIndex],
        view_settings: {
          ...updatedViewOutlines[viewIndex].view_settings,
          view_name: viewName,
        },
      };
      setViewOutlines(updatedViewOutlines);
    }
  };

  // edit share link modal
  const editShareLinkModalRef = useRef<{ openModal: (view: View | ViewOutline) => void }>();
  const openEditShareLinkModal = (viewOutline: ViewOutline) => editShareLinkModalRef.current?.openModal(viewOutline);

  const onShareLinkUpdated = async (viewShareLink: ViewShareLink) => {
    // update back the status on table view
    const updatedViewOutlines = [..._viewOutlines];
    const updatedViewIndex = updatedViewOutlines.findIndex(
      (viewOutline) => viewOutline.view_id === viewShareLink.view_id
    );

    if (updatedViewIndex > -1) {
      updatedViewOutlines[updatedViewIndex] = {
        ...updatedViewOutlines[updatedViewIndex],
        view_settings: {
          ...updatedViewOutlines[updatedViewIndex].view_settings,
          is_share: viewShareLink.is_share,
          password: viewShareLink.password ? '*' : '',
        },
      };
      setViewOutlines(updatedViewOutlines);
    }
    // only copy share link if enabled
    if (viewShareLink.is_share) await copyShareLink(viewShareLink.view_id);
  };

  // delete modal settings
  const deleteModalRef = useRef<{ openModal: (viewId: string, viewName: string) => void }>();
  const openDeleteModal = (viewId: string, viewName: string) => {
    deleteModalRef.current?.openModal(viewId, viewName);
  };

  // copy share link
  const copyShareLink = async (view_id: string) => {
    const copyUrl = `${environments.REACT_APP_BASE_URL}/share/${view_id}`;
    await CopyText(copyUrl, '共有リンクをコピーしました。', toast);
  };

  const handleEditShareFileModalButtonClick = (index: number) => {
    setSelectedViewIndex(index);
    setIsEditShareFileModalOpen(true);
  };

  const handleCloseEditShareFileModal = () => {
    setSelectedViewIndex(-1);
    setIsEditShareFileModalOpen(false);
  };

  const handleToggleShareDownload = async (asset: Asset) => {
    const newAsset = { ...asset };
    newAsset.asset_settings.disallows_share_download = !asset.asset_settings.disallows_share_download;
    const authToken = await getAccessTokenSilently();
    await SaveAssetSettings(authToken, _viewOutlines[selectedViewIndex].view_id, asset.asset_id, asset.asset_settings);
    const updatedViewOutlines = [..._viewOutlines];
    const updatedAssetIndex = updatedViewOutlines[selectedViewIndex].assets!.findIndex(
      (_asset) => _asset.asset_id === asset.asset_id
    );
    updatedViewOutlines[selectedViewIndex].assets![updatedAssetIndex] = newAsset;
    setViewOutlines(updatedViewOutlines);
  };

  // get views when initialize page
  useEffect(() => {
    void (async () => {
      if (!initialCompleted) {
        try {
          const tokenForInitializePage = await getAccessTokenSilently();
          const viewOutlines = await GetViews(tokenForInitializePage);

          setViewOutlines(viewOutlines);

          // Display the views right away without waiting for capacity calling
          setInitialCompleted(true);

          const capacityStatus = await GetCapacity(tokenForInitializePage);

          setCapacityStatus(capacityStatus);
          console.log('completed initialize dashboard');
        } catch (err) {
          setInitialCompleted(true);
          ProcessErrorHandler(err, 'dashboard');
        }
      }
    })();
  }, [user, initialCompleted, getAccessTokenSilently]);

  // remove view at views state
  const deleteView = (viewId: string) => {
    const deepCopyViewOutlines = JSON.parse(JSON.stringify(_viewOutlines)) as ViewOutline[];

    const newViewOutline = deepCopyViewOutlines.filter((viewOutline: ViewOutline) => viewId !== viewOutline.view_id);

    setViewOutlines(newViewOutline);
  };

  // utils
  const getShareLinkStatus = (viewOutline: ViewOutline): string => {
    if (!viewOutline.view_settings.is_share) {
      return '無効';
    }

    if (viewOutline.view_settings.password) {
      return '有効';
    }

    return '有効（パスワードなし）';
  };

  return (
    <>
      <Navbar />

      {initialCompleted ? (
        <Container maxW={CONTAINER_MAX_WIDTH}>
          <PageHeading>Views</PageHeading>
          <Box w={{ sm: '100%', md: '50%' }}>
            <Progress value={_capacityStatus.progressValue} size="xs" colorScheme="secondary" />
            <Text fontSize="sm" fontWeight="semibold" mt={1.5}>
              {/* Capacity limitation never been 0 */}
              {_capacityStatus.capacity <= 0
                ? 'loading...'
                : `${_capacityStatus.usageInGigabyte} / ${_capacityStatus.capacityInGigabyte}`}
            </Text>
          </Box>
          <Button
            id="create-view-button"
            variant="outlinePrimary"
            size="md"
            mt={10}
            mb={7}
            onClick={openCreateViewModal}
            rightIcon={<AddIcon />}
          >
            新規作成
          </Button>
          <TableContainer>
            <Table w="100%" variant="simple" size="sm">
              <Thead fontSize="sm">
                <Tr>
                  <Th w="49%">Name</Th>
                  <Th w="30%" isNumeric>
                    Share Link
                  </Th>
                  <Th w="20%" isNumeric>
                    Updated
                  </Th>
                  <Th w="1%" />
                </Tr>
              </Thead>
              <Tbody>
                {_viewOutlines.map((viewOutline, viewIndex) => (
                  <Tr key={viewOutline.view_id}>
                    <Td>
                      <Link as={RouterLink} variant="underline" to={`/view/${viewOutline.view_id}`}>
                        {viewOutline.view_settings.view_name}
                      </Link>
                    </Td>
                    <Td>
                      <Box display="flex" alignItems="center" justifyContent="end">
                        {getShareLinkStatus(viewOutline)}
                        <IconButton
                          size="sm"
                          variant="ghost"
                          aria-label="edit-share-link"
                          icon={<EditIcon />}
                          onClick={() => openEditShareLinkModal(viewOutline)}
                          ml="2"
                        />
                        <IconButton
                          size="sm"
                          variant="ghost"
                          aria-label="copy-share-link"
                          icon={<CopyIcon />}
                          onClick={() => copyShareLink(viewOutline.view_id)}
                          disabled={!viewOutline.view_settings.is_share}
                        />
                      </Box>
                    </Td>
                    <Td isNumeric>
                      <Text>{dayjs.unix(viewOutline.updated_at).format('YYYY/MM/DD')}</Text>
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          variant="ghost"
                          as={IconButton}
                          aria-label="Actions"
                          fontSize="lg"
                          icon={<HDotsIcon />}
                        />
                        <MenuList>
                          <MenuItem onClick={() => openEditModal(viewIndex)}>編集</MenuItem>
                          <MenuItem onClick={() => handleEditShareFileModalButtonClick(viewIndex)}>
                            共有ダウンロード設定
                          </MenuItem>
                          <MenuItem
                            onClick={() => openDeleteModal(viewOutline.view_id, viewOutline.view_settings.view_name)}
                          >
                            削除
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <CreateViewModal ref={createViewModalRef} onViewAdded={(view) => onViewAdded(view)} />
          <EditViewModal ref={editViewModalRef} onViewEdited={onViewEdited} views={_viewOutlines} />
          <DeleteViewModal ref={deleteModalRef} deleteCompleted={(viewId: string) => deleteView(viewId)} />
          <EditShareLinkModal ref={editShareLinkModalRef} saveShareLinkHandler={onShareLinkUpdated} />
          {selectedViewIndex > -1 && (
            <EditShareFileModal
              isOpen={isEditShareFileModalOpen}
              onClose={handleCloseEditShareFileModal}
              view={_viewOutlines[selectedViewIndex]}
              onToggleShareDownload={handleToggleShareDownload}
            />
          )}
        </Container>
      ) : (
        <Center p={10}>loading...</Center>
      )}
    </>
  );
};
