import { useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  FormControl,
  FormLabel,
  forwardRef,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

// third party modules

// assets, config

// services
import { ProcessErrorHandler } from '../../services/ErrorHandler';
import { CreateView } from '../../services/View/View';
import { View } from '../../config/interfaces/views';

const CreateViewModalFunc: React.ForwardRefRenderFunction<
  {
    openModal: () => void;
  },
  {
    onViewAdded: (view: View) => void;
  }
> = ({ onViewAdded }, modalRef) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = useForm();

  useImperativeHandle(modalRef, () => ({
    openModal() {
      onOpen();
    },
  }));

  // add view process
  const onSubmit = async (formData: { viewName: string }) => {
    try {
      const tokenForCreateView = await getAccessTokenSilently();
      const view = await CreateView(tokenForCreateView, formData.viewName);

      onViewAdded(view);
      onClose();
    } catch (err) {
      ProcessErrorHandler(err, 'createView');
    }
  };

  return (
    <Modal closeOnOverlayClick={!isSubmitting} isOpen={isOpen} onClose={onClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>viewを作成</ModalHeader>
        <ModalCloseButton hidden={isSubmitting} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Controller
              control={control}
              name="viewName"
              defaultValue=""
              render={({ field: { ref, ...restField } }) => (
                <FormControl id="viewName">
                  <FormLabel htmlFor="viewName">view名</FormLabel>
                  <Input
                    {...restField}
                    type="text"
                    placeholder="Tokyo Station"
                    isRequired
                    textAlign="left"
                    name="viewName"
                    ref={ref}
                  />
                </FormControl>
              )}
            />
          </ModalBody>
          <ModalFooter mt={8}>
            <Button disabled={isSubmitting} me={3} py={2} minW="100px" onClick={onClose}>
              キャンセル
            </Button>

            <Button
              colorScheme="primary"
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
              loadingText="作成中"
              minW="100px"
              type="submit"
              py={2}
            >
              作成
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export const CreateViewModal = forwardRef(CreateViewModalFunc);
