// react modules
import { Box } from '@chakra-ui/react';

export const ActionListPanelItem: React.FC<{ children: React.ReactNode; onClickHandler: () => void }> = ({
  children,
  onClickHandler,
}) => (
  <Box
    w="100%"
    cursor="pointer"
    padding={1}
    paddingLeft={4}
    textAlign="left"
    border="1px"
    borderColor="transparent"
    _hover={{ border: '1px', borderColor: 'whiteAlpha.50' }}
    onClick={onClickHandler}
  >
    {children}
  </Box>
);
